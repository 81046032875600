import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentFormComponent } from 'src/app/shared/routes-component/document-form/document-form.component';
import { DetailRequestComponent } from './components/detail-request/detail-request.component';
import { DocumentRequestComponent } from './components/document-request/document-request.component';
import { UserRequestComponent } from './components/user-request/user-request.component';

const routes: Routes = [
  {
    path: 'document',
    redirectTo: 'document/1'
  },
  {
    path: 'document/edit/:id',
    component: DocumentFormComponent,
    data: {
      isRequest: true
    }
  },
  {
    path: 'document/:tab',
    component: DocumentRequestComponent,
  },
  {
    path: 'document/detail/:type/:id',
    component: DetailRequestComponent,
  },
  {
    path: 'user/:tab',
    component: UserRequestComponent
  },
  {
    path: 'user',
    redirectTo: 'user/1'
  },
  {
    path: 'user/detail/:type/:id',
    component: DetailRequestComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'document/1'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestsRoutingModule { }
