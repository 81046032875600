import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, OnDestroy } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { OfficeModel } from 'src/app/core/models/office.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil, timer } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';

/** File node data with possible child nodes. */

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  item: OfficeModel;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-select-office-tree',
  templateUrl: './select-office-tree.component.html',
  styleUrls: ['./select-office-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectOfficeTreeComponent
    }
  ],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: 0, opacity: 0, minHeight: 0 }),
        animate('150ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('150ms', style({ height: 0, opacity: 0, minHeight: 0 }))
      ])
    ]),
  ]
})
export class SelectOfficeTreeComponent implements OnDestroy, ControlValueAccessor {
  @Input() 
  set offices(value: OfficeModel[]) {
    this.dataSource.data = value;
    for (let id of this.selection.selected) {
      if (!this.isAvailable(id, this.dataSource.data)) {
        this.selection.deselect(id);
      }
    }
    this.cdr.detectChanges();
  }

  selection: SelectionModel<any> = new SelectionModel(true, []);

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<OfficeModel, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<OfficeModel, FlatTreeNode>;

  onTouched: Function = () => {};
  touched: boolean = false;

  destroyed: Subject<void> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.cdr.detach();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  // toggleSelection(node: FlatTreeNode): void {
  //   this.selection.toggle(node.item._id);
  //   this.checkAllParentsSelection(node);
  // }

  writeValue(obj: string[]): void {
    this.selection.select(...obj);
  }

  registerOnChange(fn: any): void {
    this.selection.changed
      .pipe(takeUntil(this.destroyed))
      .subscribe((selected) => {
        fn(selected.source.selected);
        this.cdr.detectChanges();
      });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  descendantsAllSelected(node: FlatTreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.selection.isSelected(child.item._id);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatTreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.selection.isSelected(child.item._id));
    return result && !this.descendantsAllSelected(node);
  }

  toggleSelection(node: FlatTreeNode): void {
    this.selection.toggle(node.item._id);
    this.checkAllParentsSelection(node);
  }

  parentToggleSelection(node: FlatTreeNode): void {
    this.selection.toggle(node.item._id);
    const descendants = this.treeControl.getDescendants(node).map(des => des.item._id);
    this.selection.isSelected(node.item._id)
      ? this.selection.select(...descendants)
      : this.selection.deselect(...descendants);
    // Force update for the parent
    descendants.forEach(child => this.selection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: FlatTreeNode): void {
    let parent: FlatTreeNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: FlatTreeNode): void {
    const nodeSelected = this.selection.isSelected(node.item._id);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.selection.isSelected(child.item._id);
      });
    if (nodeSelected && !descAllSelected) {
      this.selection.deselect(node.item._id);
    } else if (!nodeSelected && descAllSelected) {
      this.selection.select(node.item._id);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatTreeNode): FlatTreeNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Transform the data to something the tree can read. */
  transformer(node: OfficeModel, level: number): FlatTreeNode {
    return {
      item: node,
      level,
      expandable: node.childs.length > 0
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode): number {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: OfficeModel): OfficeModel[] | null | undefined {
    return node.childs;
  }

  isAvailable(id: string, source: OfficeModel[]): boolean {
    let check: boolean = false;
    for (let office of source) {
      if (office._id == id) {
        check = true;
      } else {
        if (office.childs && office.childs.length > 0) {
          if (this.isAvailable(id, office.childs)) {
            check = true;
          }
        }
      }
    }
    return check;
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
