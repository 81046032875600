<h2 class="medium">{{ data.title }}</h2>
<h4 class="m-t-10" *ngIf="data.filename">{{'file_name' | translate}} : {{data.filename}} </h4>
<mat-dialog-content>
    <section class="user-role-section">
        <div *ngFor="let role of roles" class="checkbox-contaier">
            <mat-checkbox [checked]="isExistsRole(role._id)" (change)="userRoleCheckBoxChange(role, $event)"><b class="uppercase">{{ role.name }}</b></mat-checkbox>
        </div>
    </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="existsRolesFromUser">{{'cancel' | translate}}</button>
  <button class="m-l-10" mat-flat-button color="accent" [mat-dialog-close]="responseValue">{{'save' | translate}}</button>
</mat-dialog-actions>