import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { OfficeModel } from 'src/app/core/models/office.model';
import { UserModel } from 'src/app/core/models/user.model';
import { NewAPIService } from 'src/app/core/services/new-api.service';

@Component({
  selector: 'app-user-list-select',
  templateUrl: './user-list-select.component.html',
  styleUrls: ['./user-list-select.component.scss']
})
export class UserListSelectComponent implements OnInit, OnDestroy {
  isTryAgain: boolean = false;
  isLoading: boolean = false;
  isEmpty: boolean = false;
  users: UserModel[] = new Array();
  filterForm: FormGroup = new FormGroup({
    searchQuery: new FormControl(''),
    office: new FormControl('')
  })
  manageOffice: OfficeModel[] = new Array();
  lang: string = "";
  displayedColumns: string[] = ["select", "code", "username", "office"];
  subscription: Subscription = new Subscription();
  formChange: Subscription = new Subscription();
  selectedUsers: UserModel[] = new Array();
  selectedUsersID: string[] = new Array();
  @ViewChild("paginator") paginator: MatPaginator;
  totalUser: number = 0;
  limit: number = 10;
  pageIndex: number = 1;
  constructor(
    private API: NewAPIService,
    // private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      users: UserModel[],
    }
  ) { }

  ngOnInit(): void {
    this.selectedUsers = this.data.users.slice();
    this.selectedUsersID = this.selectedUsers.map(user => user.personal_code);
    this.getManageOffice();
    this.formChange = this.filterForm.valueChanges.subscribe((val) => {
      if (this.paginator) this.paginator.pageIndex = 0;
      this.pageIndex = 1;
      this.getQueryUser();
    });
    this.init();
  }
  init(): void {
    this.isLoading = true;
    this.subscription = this.API.getUsers().subscribe(
      response => {
        if (response.status == SERVER_STATUS.SUCCESS) {
          this.users = response.users.map(user => {
            user.selected = this.selectedUsersID.includes(user.personal_code);
            return user;
          });
          this.totalUser = response.total;
          this.isEmpty = this.users.length == 0;
        }
        // this.selectedUsers = this.users.filter(user => user.selected);
      },
      error => {
        this.isTryAgain = true;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  getManageOffice(): void {
    this.subscription.add(
      this.API.getOfficesTree().subscribe(response => {
        if (response.status == SERVER_STATUS.SUCCESS)
          this.manageOffice = response.offices;
      })
    );
  }
  onPageChange(e) {
    this.limit = e.pageSize;
    this.pageIndex = e.pageIndex + 1;
    this.getQueryUser();
  }
  onSelectionChange(e: MatCheckboxChange): void {
    const value = e.source.value;
    const targetUser = this.users.find(user => user.personal_code == value);
    targetUser.selected = e.checked;
    if (e.checked) {
      this.selectedUsers.push(targetUser);
    } else {
      this.selectedUsers.splice(this.selectedUsers.findIndex(user => user.personal_code == value), 1);
    }
    this.selectedUsersID = this.selectedUsers.filter(user => user.selected).map(user => user.personal_code);
  }
  getQueryUser(): void {
    this.subscription.unsubscribe();
    this.isLoading = true;
    this.subscription = this.API.searchUsers({ ...this.filterForm.value, limit: this.limit, page: this.pageIndex }).subscribe(
      response => {
        if (response.status == SERVER_STATUS.SUCCESS) {
          this.users = response.users;
          this.totalUser = response.total;
          this.isEmpty = this.totalUser == 0;
        }
      },
      err => {
        throw new Error(JSON.stringify(err))
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.formChange.unsubscribe();
  }
}
