import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const NUMBERS =  [
  String.fromCharCode(6112),
  String.fromCharCode(6113),
  String.fromCharCode(6114),
  String.fromCharCode(6115),
  String.fromCharCode(6116),
  String.fromCharCode(6117),
  String.fromCharCode(6118),
  String.fromCharCode(6119), 
  String.fromCharCode(6120),
  String.fromCharCode(6121)
];

@Pipe({
  name: 'localeNumber',
  pure: false
})
export class LocaleNumberPipe implements PipeTransform {
  value: string = '';
  prevValue: string | number = '';
  prevLang: string;
  constructor(
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) { }

  transform(value: string | number, ...args: unknown[]): string | number {
    if (value == null || value == undefined) {
      return value
    }
    if (this.translate.defaultLang == this.prevLang && value == this.prevValue) {
      return this.value;
    }
    this.prevValue = value;
    this.prevLang = this.translate.defaultLang;
    this.updateValue(value);
    return this.value;
  }

  updateValue(value: string |  number): void {
    switch (this.translate.defaultLang) {
      case 'en':
        this.value = this.transformToEn(value);
        break;
      case 'km':
        this.value = this.transformToKm(value);
    }
    this.cdRef.markForCheck();
  }

  transformToKm(value: string | number): string {
    const valueString = String(value);
    return valueString.replace(/[0-9]/g, function(match) {
      return NUMBERS[match];
    });
  }

  transformToEn(value: string | number): string {
    const valueString = String(value);
    return valueString.replace(/\u17E0|\u17E1|\u17E2|\u17E3|\u17E4|\u17E5|\u17E6|\u17E7|\u17E8|\u17E9/g, function(match) {
      return NUMBERS.indexOf(match).toString();
    });
  }

}
