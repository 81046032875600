import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

type Value = string | Date;

@Pipe({
  name: 'localeDate',
  pure: false
})
export class LocaleDatePipe implements PipeTransform {
  value: string = '';
  prevLang: string;
  constructor(
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  transform(value: Value, template: string = 'DD/MMM/yyyy'): string {
    if (this.translate.defaultLang == this.prevLang) {
      return this.value;
    }
    this.prevLang = this.translate.defaultLang;
    this.updateValue(value, template);
    return this.value;
  }

  updateValue(value: Value, template: string): void {
    this.value = moment(value).locale(this.prevLang).format(template);
    this.cdRef.markForCheck();
  }

}
