import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserModel } from '../../../core/models/user.model';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { NewAPIService } from 'src/app/core/services/new-api.service';
import { fadeOpacity } from 'src/app/core/animations/common-animations';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
  animations: [
    fadeOpacity
  ]
})
export class ChangePasswordDialogComponent implements OnInit {

  oldPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  userID: string = "";
  hide_new = true;
  hide_old = true;
  hide_confirm = true;
  checkBothPassword: boolean = false;
  checkConfirmPassword: boolean = false;
  checkWrongPassword: boolean = false;

  // ===== Form Validation ======================================================
  oldPasswordFormControl: FormControl = new FormControl('', [
    Validators.required
  ]);

  newPasswordFormControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  ]);

  confirmPasswordFormControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  ]);

  changePasswordFormGroup: FormGroup = new FormGroup({
    old_password: this.oldPasswordFormControl,
    new_password: this.newPasswordFormControl,
    confirm_password: this.confirmPasswordFormControl
  });

  getOldPasswordErrorMessage() {
    return this.oldPasswordFormControl.hasError('required') ? this.translate.instant("global.form.can_not_save") + this.translate.instant("top_menu.change_pwd_dialog.placeholder_old_pwd") + this.translate.instant("global.form.be_empty") : '';
  }

  getNewPasswordErrorMessage() {
    return this.newPasswordFormControl.hasError('required') ? this.translate.instant("global.form.can_not_save") + this.translate.instant("top_menu.change_pwd_dialog.placeholder_new_pwd") + this.translate.instant("global.form.be_empty") :
      this.newPasswordFormControl.hasError('pattern') ? this.translate.instant("user.create_edit_dialog.form.error.pwd_strong") : 
      this.newPasswordFormControl.hasError("equalOld") ? this.translate.instant("user.create_edit_dialog.form.error.equal") : '';
  }

  getConfirmPasswordErrorMessage() {
    return this.confirmPasswordFormControl.hasError('required') ? this.translate.instant("global.form.can_not_save") + this.translate.instant("user.create_edit_dialog.form.re_pwd") + this.translate.instant("global.form.be_empty") :
      this.confirmPasswordFormControl.hasError('pattern') ? this.translate.instant("user.create_edit_dialog.form.error.pwd_strong") : 
      this.confirmPasswordFormControl.hasError("notMatch") ? this.translate.instant("user.create_edit_dialog.form.error.password_unique") : "";
  }
  // ===========================================================================

  isEqual(inp1: HTMLInputElement | AbstractControl, inp2: HTMLInputElement | AbstractControl) {
    return inp1.value == inp2.value;
  }
  // private onChange(): void {
  //   if (this.oldPasswordFormControl.value && this.newPasswordFormControl.value && this.oldPasswordFormControl.value == this.newPasswordFormControl.value) {
  //     this.oldPasswordFormControl.setErrors({
  //       equalNew: true
  //     });
  //     this.oldPasswordFormControl.setErrors({
  //       equalOld: true
  //     });
  //   } else {
  //     this.oldPasswordFormControl.setErrors({
  //       equalNew: false
  //     });
  //     this.oldPasswordFormControl.setErrors({
  //       equalOld: false
  //     });
  //   }
  //   if (this.confirmPasswordFormControl.value && this.newPasswordFormControl.value && this.confirmPasswordFormControl.value != this.newPasswordFormControl.value) {
  //     this.confirmPasswordFormControl.setErrors({
  //       notMatch: true
  //     })
  //   } else {
  //     this.confirmPasswordFormControl.setErrors({
  //       notMatch: false
  //     })
  //   }
  // }

  constructor(
    private newApi: NewAPIService,
    private translate: TranslateService,
    // private api: ApiService,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: UserModel,
      is_approval: boolean
    },
  ) {

    if (this.data.is_approval) {
      this.oldPasswordFormControl.clearValidators();
      this.oldPasswordFormControl.updateValueAndValidity();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.oldPasswordFormControl.valueChanges.subscribe(this.onChange.bind(this));
    // this.newPasswordFormControl.valueChanges.subscribe(this.onChange.bind(this));
    // this.confirmPasswordFormControl.valueChanges.subscribe(this.onChange.bind(this));
    // this.changePasswordFormGroup.valueChanges.subscribe(value => {
    //   if (value.old_password && value.old_password == value.new_password) {
    //     this.oldPasswordFormControl.setErrors({
    //       equalNew: true
    //     });
    //     this.oldPasswordFormControl.setErrors({
    //       equalOld: true
    //     });
    //   }
    // })
  }

  handleChangePassword() {
    this.checkWrongPassword = false;
    if (this.oldPassword === this.newPassword) {
      this.checkBothPassword = true;
    } else if (this.confirmPassword !== this.newPassword) {
      this.checkConfirmPassword = true;
      this.checkBothPassword = false;
      this.confirmPassword = '';
    } else {
      this.checkBothPassword = false;

      if (!this.data.is_approval) {
        this.newApi.changeOwnPassword(this.newPassword, this.oldPassword).subscribe((response: any) => {
          if(response.status !== 1) {
            this.checkWrongPassword = true;
            this.checkConfirmPassword = false;
            this.oldPassword = '';
          } else {
            // this.snackBar.open('changed successfully', null, {duration: 2000, horizontalPosition: 'center'});
            this.dialogRef.close();
          }
        });

      } else {
        this.dialogRef.close({
          reset_password: this.newPassword,
          confirm_reset_password: this.confirmPassword
        })
      }
    }
  }
}
