<h2 mat-dialog-title>{{'top_menu.change_pwd_dialog.title' | translate}}</h2>
<form class="example-form" name="changePasswordFormGroup" [formGroup]="changePasswordFormGroup">
    <div mat-dialog-content>
        <p>
            <mat-form-field *ngIf="!data.is_approval" class="example-full-width" appearance="outline">
                <mat-label>{{ 'top_menu.change_pwd_dialog.placeholder_old_pwd' | translate }}</mat-label>
                <input matInput [placeholder]="'top_menu.change_pwd_dialog.placeholder_old_pwd' | translate" [type]="hide_old ? 'password' : 'text'" [formControl]="oldPasswordFormControl" [(ngModel)]="oldPassword">
                <mat-icon matSuffix (click)="hide_old = !hide_old">{{hide_old ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error class="l2" *ngIf="oldPasswordFormControl.invalid">{{getOldPasswordErrorMessage()}}</mat-error>
                <mat-error class="l2" *ngIf="oldPasswordFormControl.hasError('equalNew')">{{"user.create_edit_dialog.form.error.equal" | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{ 'top_menu.change_pwd_dialog.placeholder_new_pwd' | translate }}</mat-label>
                <input matInput [placeholder]="'top_menu.change_pwd_dialog.placeholder_new_pwd' | translate" [type]="hide_new ? 'password' : 'text'" [formControl]="newPasswordFormControl" [(ngModel)]="newPassword">
                <mat-icon matSuffix (click)="hide_new = !hide_new">{{hide_new ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error class="l2" *ngIf="newPasswordFormControl.invalid">{{getNewPasswordErrorMessage()}}</mat-error>
                <!-- <mat-error *ngIf="newPasswordFormControl.hasError('pattern')">{{ 'error' }}</mat-error> -->
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>{{ 'top_menu.change_pwd_dialog.placeholder_confirm' | translate }}</mat-label>
                <input matInput [placeholder]="'top_menu.change_pwd_dialog.placeholder_confirm' | translate" [type]="hide_confirm ? 'password' : 'text'" [formControl]="confirmPasswordFormControl" [(ngModel)]="confirmPassword">
                <mat-icon matSuffix (click)="hide_confirm = !hide_confirm">{{hide_confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error class="l2" *ngIf="confirmPasswordFormControl.invalid">{{getConfirmPasswordErrorMessage()}}</mat-error>
                <!-- <mat-error *ngIf="confirmPasswordFormControl.hasError('pattern')">{{ 'error' }}</mat-error> -->
            </mat-form-field>
        </p>
        <p @fade class="warn" *ngIf="checkBothPassword">{{'top_menu.change_pwd_dialog.message.check_both_pwd' | translate}}</p>
        <p @fade class="warn" *ngIf="checkConfirmPassword">{{'top_menu.change_pwd_dialog.message.check_confirm_pwd' | translate}}</p>
        <p @fade class="warn" *ngIf="checkWrongPassword">{{'top_menu.change_pwd_dialog.message.check_wrong_pwd' | translate}}</p>
        <div mat-dialog-actions>
            <button mat-raised-button (click)="onNoClick()">{{'top_menu.change_pwd_dialog.button.cancel' | translate}}</button>
            <button mat-raised-button (click)="handleChangePassword()" class="btn-right" cdkFocusInitial [disabled]="changePasswordFormGroup.invalid" [ngClass]="{'btn-disabled': changePasswordFormGroup.invalid}">{{'top_menu.change_pwd_dialog.button.confirm' | translate}}</button>
        </div>
    </div>
</form>
