import { NewAPIService } from './../../../../core/services/new-api.service';
import { Component, OnInit, Injectable, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { OrganizationModel } from 'src/app/core/models/organization.model';
import { ReportModel } from 'src/app/core/models/report.model';
import { DatePipe } from '@angular/common';
import { OfficeModel } from 'src/app/core/models/office.model';
import { NativeDateAdapter, MatDateFormats, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { fadeOpacity } from 'src/app/core/animations/common-animations';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { MatPaginator } from '@angular/material/paginator';
import { EXCEL_EXTENSION, EXCEL_TYPE, NewExcelService } from 'src/app/core/services/new-excel.service';
import { Alignment, Font, PaperSize } from 'exceljs';
import { PrintService } from 'src/app/core/services/print.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocaleNumberPipe } from 'src/app/core/pipe/locale-number.pipe';
import { DocumentTypeModel } from 'src/app/core/models/document-type.model';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
  animations: [
    fadeOpacity
  ]
})

export class ReportComponent implements OnInit, OnDestroy {
  isEditing: false;
  displayedColumns = ['no', 'title', 'ref', 'date', 'state', 'office', 'user', 'created_date', 'order'];
  organizationList: OrganizationModel[] = [];
  organizationName: string = '';
  formGroup: FormGroup;
  reports: ReportModel[] = [];
  export_reports: any;
  date = new Date();
  isEmpty: boolean = false;
  isLoading: boolean = false;
  private subscription: Subscription = new Subscription();
  total: number = 0;
  currentPage: number = 1;
  limit: number = 10;
  @ViewChild('table', { static: true, read: ElementRef }) table: ElementRef;
  @ViewChild("paginator") paginator: MatPaginator;
  private getOfficeSubscription: Subscription = new Subscription();
  private getTypeSubscription: Subscription = new Subscription();

  newCondition: any = {
    start_date: '',
    end_date: '',
    doc_type: '',
    from_to: '',
  };
  lang: string;

  destroyed: Subject<void> = new Subject();

  offices: Array<OfficeModel> = [];
  types: Array<DocumentTypeModel> = [];

  constructor(
    private formBuilder: FormBuilder,
    public datePipe: DatePipe,
    private newApi: NewAPIService,
    // private api: ApiService,
    private title: Title,
    private newExcel: NewExcelService,
    private translateService: TranslateService,
    private localeNumber: LocaleNumberPipe,
    private prinnter: PrintService
  ) {
    this.formGroup = this.formBuilder.group({
      start_date: [new Date(this.date.getFullYear(), this.date.getMonth(), 1), Validators.required],
      end_date: [new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0), Validators.required],
      office: [''],
      type: [''],
    });
    this.lang = this.translateService.defaultLang;
    this.translateService.onLangChange
    .pipe(takeUntil(this.destroyed))
    .subscribe((e: any) => {
      this.lang = e.lang;
    });
  }

  ngOnInit() {
    this.title.setTitle("Reports");
    this.getOfficeTree();
    this.getDocTypeTree();
    this.init();
    this.formGroup.valueChanges.subscribe(value => {
      this.limit = 10;
      this.currentPage = 1;
      if (this.paginator) this.paginator.pageIndex = 0;
      this.init();
    });
    // this.patchValueToForm();
  }

  patchValueToForm() {
    this.formGroup.patchValue({
      start_date: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
      end_date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0),
    });
  }

  getOfficeTree() {
    this.getOfficeSubscription.unsubscribe();
    this.isLoading = true;
    this.getOfficeSubscription = this.newApi.getOfficesTree().subscribe(response => {
      this.offices = [];
      this.isLoading = false;
      if (response.status == SERVER_STATUS.SUCCESS) {
        response.offices.forEach(office => {
          this.offices.push(...office.childs);
        });
      }
    });
  }

  getDocTypeTree() {
    this.getTypeSubscription.unsubscribe();
    this.isLoading = true;
    this.getTypeSubscription = this.newApi.getLetterTypeTree().subscribe(response => {
      this.types = [];
      this.isLoading = false;
      if (response.status == SERVER_STATUS.SUCCESS) {
        this.types = response.letter_types;
      }
    })
  }

  init(): void {
    this.subscription.unsubscribe();
    const values = this.formGroup.value;
    this.isLoading = true;
    this.export_reports = [];
    this.subscription = this.newApi.getReports({
      page: this.currentPage,
      count: this.limit,
      start_date: this.transformDateApi(values.start_date),
      end_date: this.transformDateApi(values.end_date),
      office_id: values.office._id,
      type_id: values.type._id
    })
    .subscribe(response => {
      this.isLoading = false;
      if (response.status == SERVER_STATUS.SUCCESS) {
        this.export_reports = response.docs;
        this.total = response.total;
      }
      this.isEmpty = this.export_reports.length == 0;
    })
  }

  private transformDateApi(date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  // refreshListReports() {
  //   this.newCondition = {};
  //   const values = this.formGroup.value;

  //   this.newCondition = {
  //     start_date: this.datePipe.transform(values.start_date, 'yyyy-MM-dd'),
  //     end_date: this.datePipe.transform(values.end_date, 'yyyy-MM-dd'),
  //     office_id: values.office || null,
  //   }

  //   this.organizationList.forEach((org: any) => {
  //     if (org._id == values.from_to) {
  //       this.organizationName = org.name;
  //     }
  //   });

  //   this.isLoading = true;
  //   this.export_reports = [];
  //   this.newApi.getReports(this.newCondition).subscribe((response: any) => {
  //     this.isLoading = false;
  //     if (response.status) {
  //       this.export_reports = response.docs;

  //       if (this.export_reports.length == 0) this.isEmpty = true;
  //       else this.isEmpty = false;
  //     }
  //   });
  // }
  
  private transformDateDisplay(date): string {
    return this.datePipe.transform(date, "dd/MM/yyyy")
  }

  exportReports() {
    const val = this.formGroup.value;
    let start_date = this.transformDateDisplay(val.start_date);
    let end_date = this.transformDateDisplay(val.end_date);
    this.prinnter.printReport(
      this.table.nativeElement.outerHTML,
      '',
      [
        "របាយការណ៍",
        `ចាប់ពីថ្ងៃទី ${start_date} ដល់ ‌‌${end_date}`
      ],
      [
        val.office && `ការិយាល័យ៖ ${ val.office.name }`,
      ]
    )
    return;
    // const newWin = window.open("", "PRINT", "height=700,width=1000");
    // const html = `
    // <style>
    //     @font-face {
    //       font-family: 'khmerMEF1';
    //       src: url('/assets/fonts/KHMERMEF1.ttf');
    //     }

    //     @font-face {
    //       font-family: 'khmerMEF2';
    //       src: url('/assets/fonts/KHMERMEF2.ttf');
    //     }

    //     html, body {
    //       width: 94%;
    //       padding: 3%;
    //       font-family: 'khmerMEF1';
    //       font-size: 14px;
    //       line-height: 18px;
    //     } 

    //     table {
    //       width: 100%;
    //       border-collapse: collapse;
    //     }
    //     .stt {
    //       font-family: 'khmerMEF1';
    //       font-weight: bold;
    //     }
    //     table, th, td {
    //       border: 1px solid #000000;
    //     }

    //     th, td {
    //       padding: 5px !important;
    //       font-size: 14px;
    //     }

    //     .center {
    //       text-align: center;
    //     }

    //     .space {
    //       height: 40px;
    //     }

    //     .top-header {
    //       font-family: 'khmerMEF2';
    //       font-size: 15px;
    //     }

    //     .logo-style {
    //       width: 88px;
    //     }
    //     .box {
    //       width: 20%;
    //       position: absolute;
    //       top: 60px;
    //     }
    //     .rp {
    //       margin-top: 20px;
    //     }
    //     .cm {
    //       white-space: nowrap;
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //     }
    //     .office {
    //       margin-top: 85px;
    //       margin-left: 8px;
    //     }
    //     .hide {
    //       visibility: hidden;
    //     }
    // </style>

    // <p class="center top-header">ព្រះរាជាណាចក្រកម្ពុជា</p>        
    // <p class="center top-header">ជាតិ សាសនា ព្រះមហាក្សត្រ</p>  
    // <div class="box cm">
    //   <img class="logo-style" src="/assets/logo/mef-logo@0,5x.png"/>
    //   <p class="top-header">ក្រសួងសេដ្ឋកិច្ច និងហិរញ្ញវត្ថុ</p>
    // </div>
    // ${`<p class="office${val.office ? '' :  ' hide'}">ការិយាល័យ៖ ` + (val.office ? val.office.name : "") + `</p>`}
    // <p class="center stt rp">របាយការណ៍</p>
    // <p class="center stt">ចាប់ពីថ្ងៃទី `+ start_date + ` ដល់ ‌‌‌‌` + end_date + `</p>
    // <div class="space"></div>
    // <script>
    //   window.addEventListener('DOMContentLoaded', (event) => {
    //     setTimeout(() => {
    //       window.focus();
    //       window.print();
    //       window.close();
    //     }, 500);
    //   });
    // </script>
    // `;
    // newWin.document.write(html);
    // newWin.document.write(this.table.nativeElement.outerHTML);
    // newWin.document.close();
  }

  clickCollapse(office: OfficeModel) {
    if (!office.collapse) {
      this.traverseCollapse(office);
    }
  }

  traverseCollapse(office: OfficeModel) {
    office.collapse = false;
    if (office.childs.length) {
      office.childs.forEach(of => this.traverseCollapse(of));
    }
  }
  
  exportExcel(): void {
    const filter = this.formGroup.value;
    this.newApi.getReports({
      start_date: this.transformDateApi(filter.start_date),
      end_date: this.transformDateApi(filter.end_date),
      office_id: filter.office._id,
      type_id: filter.type._id
    }).subscribe(response => {
      if (response.status !== SERVER_STATUS.SUCCESS) return;
      this.newExcel.getTemplate("របាយការណ៍")
      .then(wb => {
        const values = this.formGroup.value;
        const ws = wb.getWorksheet(1);
        const A5 = ws.getCell("A5");
        A5.value = values.office ? values.office.name : "";
        A5.font = { name: "Khmer MEF2", size: 8 };
        ws.getRow(8).height = 20;
        const headerRow = ws.getRow(9);
        headerRow.values = ["ល.រ", "ចំណងជើង", "លេខលិខិត", "កាលបរិច្ឆេទ​", "លក្ខណៈ", "អង្គភាព", "អ្នកបង្កើត", "ថ្ងៃបង្កើត", "ផ្សេងៗ"];
        headerRow.font = { size: 8, name: "Khmer MEF1", bold: true };
        headerRow.height = 20;
        const dateRow = ws.getRow(7);
        dateRow.values = [this.localeNumber.transformToKm(this.transformDateDisplay(this.formGroup.value.start_date)) + " - " + this.localeNumber.transformToKm(this.transformDateDisplay(this.formGroup.value.end_date))];
        dateRow.font = headerRow.font;
        dateRow.height = 20;
        const start: number = 10;
        const font: Partial<Font> = { name: "Khmer MEF1", size: 8 };
        response.docs.forEach((data, index) => {
          const row = ws.getRow(start+index);
          row.height = 0;
          row.font = font;
          row.values = [
            this.localeNumber.transformToKm(index + 1),
            data.title,
            (this.localeNumber.transformToKm(data.reference_id) || "គ្មាន"),
            moment(data.date).locale("km").format("DD/MMM/yyyy"),
            data.states.map(state => state.name_kh).join(', '),
            data.office.name,
            data.created_user.last_name + " " + data.created_user.first_name,
            moment(data.createdAt).locale('km').format('DD/MMM/yyyy'),
            " "
          ];
        });
        // const A = ws.getColumn("A");
        // A.width = 5;
        ws.getColumn("A").alignment = { horizontal: "center", vertical: 'middle' };
        const COLC = ws.getColumn("C")
        COLC.width = 9;
        COLC.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
        const COLE = ws.getColumn("E");
        COLE.width = 8;
        COLE.alignment = Object.assign({ wrapText: true }, COLC.alignment);
        const B = ws.getColumn("B");
        const bAlignment: Partial<Alignment> = { wrapText: true, vertical: 'middle' };
        B.eachCell(cell => {
          let rowIndex = parseInt(cell.row, 10);
          if (rowIndex < start) return;
          cell.alignment = Object.assign({ indent: 1 }, bAlignment);
          ws.getRow(rowIndex).height = 0;
        });
        B.width = 20;
        const D = ws.getColumn("D");
        D.width = 8;
        D.eachCell(cell => {
          let rowIndex = parseInt(cell.row, 10);
          if (rowIndex < start) return;
          cell.alignment = { wrapText: true, vertical: 'middle' };
          ws.getRow(rowIndex).height = 0;
        });
        const F = ws.getColumn("F");
        F.width = 12;
        F.alignment = { wrapText: true, vertical: 'middle' };
        const G = ws.getColumn("G");
        G.width = 8;
        G.alignment = { wrapText: true, vertical: 'middle' };
        const H = ws.getColumn("H");
        H.width = 8;
        H.alignment = { wrapText: true, vertical: 'middle' };
        const I = ws.getColumn("I");
        I.width = 7;
        A5.alignment = { horizontal: "left", indent: 0 };
        ws.getCell("A4").alignment = A5.alignment;
        [1, 2, 3, 6, 7].forEach(val => {
          ws.unMergeCells(val, 1, val, headerRow.values.length as number - 1);
          ws.mergeCells(val, 1, val, headerRow.values.length as number - 1);
        });
        ws.eachRow({ includeEmpty: true }, (row, n) => {
          if (n > 8)
            row.eachCell({ includeEmpty : true }, cell => {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" }
              };
              // cell.alignment = Object.assign({ indent: 1 }, row.alignment);
            });
        });
        const ROW1 = ws.getRow(1);
        ROW1.alignment = { horizontal: 'center', vertical: 'middle' };
        ws.getRow(2).alignment = ROW1.alignment;
        ws.getRow(3).alignment = ROW1.alignment;
        ws.getRow(7).alignment = ROW1.alignment;
        ws.getRow(6).font = { ...font, bold: true };
        ws.pageSetup.orientation = "portrait";
        ws.pageSetup.paperSize = PaperSize.A4;
        wb.xlsx.writeBuffer()
        .then(buffer => {
          let a = document.createElement("a");
          a.download = "របាយការណ៌ - " + Date.now() + EXCEL_EXTENSION;
          a.href = URL.createObjectURL(new Blob([buffer], { type: EXCEL_TYPE }));
          a.click();
          ws.destroy();
        })
        .catch(console.log);
      })
      .catch(console.log);
    });
  }

  onPageChange(e): void {
    this.currentPage = e.pageIndex + 1;
    this.limit = e.pageSize;
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.subscription.unsubscribe();
    this.getOfficeSubscription.unsubscribe();
    this.getTypeSubscription.unsubscribe();
  }

  get itemPerPage(): number[] {
    return Array.from(new Set([5, 10, 25, 100, this.total]));
  }
}


