import { Injectable } from '@angular/core';
import { LocalStorageEnum } from '../models/enum/local-storage.enum';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  get(key: LocalStorageEnum): string {
    return this.__get(key);
  }

  set(key: LocalStorageEnum, value: string): boolean {
    return this.__set(key, value);
  }

  setJSON(key: LocalStorageEnum, value: any): boolean {
    const encode = JSON.stringify(value);
    return this.__set(key, encode);
  }

  getJSON<T>(key: LocalStorageEnum): T {
    const decode = JSON.parse(this.get(key));
    return decode as T;
  }
  
  delete(key: LocalStorageEnum): boolean {
    return this.__delete(key);
  }

  private __get(key: string): string {
    return localStorage.getItem(key);
  }

  private __set(key: string, value): boolean {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      return false
    }
  }
  private __delete(key: string): boolean {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}
