import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  cssReplacer: string = "<!-- [ CSS ] -->";
  domReplacer: string = "<!-- [ DOC ] -->";
  reportStyle = `
    @font-face {
      font-family: 'khmerMEF1';
      src: url('/assets/fonts/KHMERMEF1.ttf');
    }
    @font-face {
      font-family: 'khmerMEF2';
      src: url('/assets/fonts/KHMERMEF2.ttf');
    }
    * { box-sizing: border-box }
    body {
      margin: 0;
      font-family: 'khmerMEF1';
      margin: 0 0.5in;
    }
    #header {
      position: relative;
      height: 200px;
    }
    .legends, #header {
      text-align: center;
    }
    .legends h4 {
      margin: 1rem 0;
    }
    #header h2, .logo-container h3 {
      font-size: 18px;
      font-weight: 300
    }
    .logo-container {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 0;
      transform: translateY(25%)
    }
    #logo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #main {
      margin-top: 32px;
    }
    .logo-container h3 {
      margin: 0.1rem 0;
    }
    h1, h2, h3 {
      font-family: 'khmerMEF2';
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    table, th, td {
      border: 1px solid #000000;
    }
    th, td {
      padding: 0 8px;
    }`;
  reportHeader = `<header id="header">
  <h2>ព្រះរាជាណាចក្រកម្ពុជា</h2>
  <h2>ជាតិ សាសនា ព្រះមហាក្សត្រ</h2>
  <div class="logo-container">
    <div id="logo">
      <img width="90" src="/assets/logo/mef-logo@0,5x.png"/>
      <h3>ក្រសួងសេដ្ឋកិច្ច និងហិរញ្ញវត្ថុ</h3>
    </div>
    <!-- AFTER LOGO -->
  </div>
</header>`
  doc: string = `<!Doctype html>
  <html>
    <head>
      <style>
        /* -- DEFAULT CSS -- */
        ${ this.cssReplacer }
        @page {
            size: auto;
            margin: 0mm;
        }
      </style>
    </head>
    <body>
      <!-- HEADER -->
      <main id="main">
        <div class="legends">
          <!-- TABLE LEGENDS -->
        </div>
        ${ this.domReplacer }
      </main>
    </body>
  </html>`;

  constructor() {
  }

  /**
   * 
   * @param doc DOC that print
   * @param css style in header
   */
  print(srcDoc: string): void {
    const iframe = document.createElement("iframe");
    iframe.onload = this.setPrint.bind(this);
    iframe.style.position = "fixed";
    iframe.style.top = "0";
    iframe.style.bottom = "0";
    iframe.style.left = "0";
    iframe.style.right = "0";
    iframe.style.visibility = "hidden";
    iframe.srcdoc = srcDoc;
    document.body.appendChild(iframe);
  }

  setPrint(e: Event): void {
    let self = e.target as HTMLIFrameElement;
    (self.contentWindow as any).__container__ = self;
    self.contentWindow.onbeforeunload = this.closePrint;
    self.contentWindow.onafterprint = this.closePrint;
    self.contentWindow.focus();
    self.contentWindow.print();
  }

  closePrint(e: Event): void {
    document.body.removeChild((e.target as any).__container__ as HTMLIFrameElement);
  }

  private getReportTemplate(legends: string[]): string {
    const template = this.doc
    .replace("/* -- DEFAULT CSS -- */", this.reportStyle)
    .replace("<!-- HEADER -->", this.reportHeader)
    .replace("<!-- TABLE LEGENDS -->", legends.map(legend => legend && `<h4>${ legend }</h4>`).join(""))
    return template;
  }

  printReport(doc: string, css: string, legends: string[], afterLogo?: string[]): void {
    let template = this.getReportTemplate(legends)
    .replace(this.cssReplacer, css)
    .replace(this.domReplacer, doc);
    if (afterLogo) {
      template = template.replace("<!-- AFTER LOGO -->", afterLogo.map(text => `<h3>${ text }</h3>`).join(""))
    }
    this.print(template);
  }

  printQr(doc: string): void {
    const template = this.doc.replace(this.cssReplacer, '#main { text-align: center }')
    .replace("/* -- DEFAULT CSS -- */", "")
    .replace(this.domReplacer, doc);
    this.print(template);
  }

  // setup(width: number, height: number): void {
  //   this.iframe.style.display = 'none';
  //   this.iframe.width = width + 'px';
  //   this.iframe.height = height + 'px';
  //   document.body.appendChild(this.iframe);
  // }

  // write(content: string): void {
  //   this.iframe.contentWindow.document.write(content)
  // }

  // close(): void {
  //   this.iframe.contentWindow.document.close();
  // }

  // loadFont(font: Blob, name: string): void {
  //   const reader = new FileReader();
  //   const onload = (e) => {
  //   console.log(e.target.result)
  //     this.write(`<style>
  //       @font-face {
  //         font-family: "${name}";
  //         src: url("${e.target.result}") format('ttf')
  //       }
  //     </style>`);
  //     reader.removeEventListener('load', onload);
  //   }
  //   reader.addEventListener('load', onload);
  //   reader.readAsDataURL(font);
  // }

  // getFont(url: string, familyName: string) {
  //   return this.http.get(url, { responseType: 'blob' })
  //   .pipe(
  //     map(
  //       response => ({ data: response, status: 1, name: familyName })
  //     )
  //     // (response) => {
  //     //   console.log(response)
  //     //   this.loadFont(response as unknown as Blob, familyName);
  //     //   return response;
  //     // }
  //   )
  // }

  // print(html): void {
  //   combineLatest([this.getFont("/assets/fonts/KHMERMEF1.ttf", "khmerMEF1"), this.getFont("/assets/fonts/KHMERMEF2.ttf", "khmerMEF2")])
  //   .subscribe(response => {
  //     response.forEach(response => {
  //       if (response.status == SERVER_STATUS.SUCCESS) {
  //         this.loadFont(response.data, response.name);
  //       }
  //     });
  //     setTimeout(() => {
  //       this.write(html);
  //       this.close();
  //       this.iframe.contentWindow.window.focus();
  //       this.iframe.contentWindow.window.print();
  //     }, 1000);
  //   })
  // }

  // destroy(): void {
  //   this.iframe.remove();
  // }
}
