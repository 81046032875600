import { DOCUMENT } from "@angular/common"

export enum MODELS {
    DOCUMENT = 'document',
    TEMPORARY = 'temporary',
    USER = "user"
}

export enum REFERENCE_MODEL {
    DOCUMENT = 'document',
    USER = 'user',
}
