import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { OverlayModule } from "@angular/cdk/overlay";
import { TranslateModule } from "@ngx-translate/core";
import { TrackingItemComponent } from "../components/tracking-item/tracking-item.component";
import { SubmitReasonDialogComponent } from "../dialogs/submit-reason/submit-reason-dialogcomponent";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipeModule } from "src/app/core/pipe/pipe.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatTooltipModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatChipsModule,
        MatFormFieldModule,
        MatMenuModule,
        MatRippleModule,
        MatSnackBarModule,
        OverlayModule,
        TranslateModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        PipeModule
    ],
    exports: [TrackingItemComponent],
    declarations: [TrackingItemComponent, SubmitReasonDialogComponent]
})
export class TrackingItemModule {}
