
import { ImgCropperConfig, ImgCropperEvent, LyImageCropper } from '@alyle/ui/image-cropper';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { styles } from './cropper.style';
import { LyTheme2 } from '@alyle/ui';
declare var $: any;

@Component({
  selector: 'app-crop-dialog',
  templateUrl: './crop-dialog.component.html',
  styleUrls: ['./crop-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropDialogComponent implements OnInit {

  fileList: FileModel<File>[] = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file: any;
  selectedImage: any;
  rgbBackgroundColor: Array<number> = [];
  currentMsg: string = '';

  isImageCropped: boolean = false;
  isSelectedImage: boolean = false;

  classes = this.theme.addStyleSheet(styles);
  croppedImg: string;
  minimumRGBColorCode: number = 100;
  maxPhotoSize: number = 2; // size: 2MB

  @ViewChild(LyImageCropper) imgCropper: LyImageCropper;
  scale: number;
  myConfig: ImgCropperConfig = {
    // width: 151.16,
    // height: 190,
    width: 150,
    height: 150,
    round: true,
    fill: '#ff2997',
    type: 'image/png',
    // output: {
    //   // width: 397.36,
    //   // height: 500
    //   width: 40,
    //   height: 40
    // }
  };

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<CropDialogComponent>,
    // private apiService: ApiService,
    private theme: LyTheme2,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
  }

  /** on event */
  onCrop(e: ImgCropperEvent) {
    this.croppedImg = e.dataURL;
    
    // this.isSelectedImage = true;
    // this.selectedImage = e.dataURL;

    this.file = new File([this.dataURItoBlob(this.croppedImg)], Date.now() + '.png', { type: 'image/png' });
    this.dialogRef.close({ file: this.file });
  }

  dataURItoBlob(dataURI) {

    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  validatePhoto(src: string, succeedCallBack) {
    var that = this
    var img = new Image();

    img.onload = function () {
      console.log("img onload");
      console.log($(img));
      console.log($(img).faceDetection);

      // try {
      //   nonExistentFunction();
      // } catch (error) {
      //   console.error(error);
      // }

      $(img).faceDetection({
        complete: function (faces) {
          if (faces.length < 1) {
            this.currentMsg = "0001";
            // that.dialog.open(FaceDetectionMessageComponent, {
            //   panelClass: 'width-message-dialog',
            //   data: {
            //     message_code: "0001",
            //     isConfirm: false,
            //   },
            //   disableClose: false, role: 'dialog'
            // });

          } else if (faces.length == 1) {
            var face = faces[0];
            if ((face.width / img.width) > 0.6) { //if too close
              this.currentMsg = "0002";
              // that.dialog.open(FaceDetectionMessageComponent, {
              //   panelClass: 'width-message-dialog',
              //   data: {
              //     message_code: "0002",
              //     isConfirm: false,
              //   },
              //   disableClose: false, role: 'dialog'
              // });

            } else if ((face.width / img.width) < 0.25) { //if too far
              this.currentMsg = "0003";
              // that.dialog.open(FaceDetectionMessageComponent, {
              //   panelClass: 'width-message-dialog',
              //   data: {
              //     message_code: "0003",
              //     isConfirm: false,
              //   },
              //   disableClose: false, role: 'dialog'
              // });

            } else if (Math.abs(img.width - face.width - face.x - face.x) / img.width > 0.15) { //if not center
              this.currentMsg = "0004";
              // that.dialog.open(FaceDetectionMessageComponent, {
              //   panelClass: 'width-message-dialog',
              //   data: {
              //     message_code: "0004",
              //     isConfirm: false,
              //   },
              //   disableClose: false, role: 'dialog'
              // });

            } else {
              succeedCallBack(src);
            }
          } else if (faces.length > 1) {
            this.currentMsg = "0005";
            // that.dialog.open(FaceDetectionMessageComponent, {
            //   panelClass: 'width-message-dialog',
            //   data: {
            //     message_code: "0005",
            //     isConfirm: false,
            //   },
            //   disableClose: false, role: 'dialog'
            // });

          }
        },
        error: function (code, message) {
        }
      });
    }
    img.src = src;
  }

  onVerifyBackground(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event: any) => { // called once readAsDataURL is completed
        this.isSelectedImage = true;
        this.selectedImage = event.target.result;
      }
    }
  }

  onLoadImage(element_id: string) {
    this.isImageCropped = true;
    var img = document.getElementById(element_id);

    var arrAvgColors = img.onload = function (): any {
      var realSize = realImgDimension(img);
      const image = document.getElementById(element_id) as HTMLImageElement;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const width = realSize.naturalWidth;
      const height = realSize.naturalHeight;

      canvas.width = width;
      canvas.height = height;

      context.drawImage(image, 0, 0, width, height);

      const data = context.getImageData(0, 0, 5, height).data;
      console.log("data:");
      console.log(context.getImageData(0, 0, 5, height));
      console.log(data);

      var arrAvgColors = [
        ...getAverageSolidColor(0, 0, 10, height / 2), // left side
        ...getAverageSolidColor(0, 0, width, 10), // top
        ...getAverageSolidColor(width - 10, 0, 10, height / 2) // right side
      ];

      return arrAvgColors;

      function realImgDimension(img) {
        var i = new Image();
        i.src = img.src;
        return {
          naturalWidth: i.width,
          naturalHeight: i.height
        };
      }

      function getAverageSolidColor(X, Y, width, height) {
        let R = 0;
        let G = 0;
        let B = 0;
        let A = 0;
        let wR = 0;
        let wG = 0;
        let wB = 0;
        let wTotal = 0;

        const data = context.getImageData(X, Y, width, height).data;

        const components = data.length;

        for (let i = 0; i < components; i += 4) {
          // A single pixel (R, G, B, A) will take 4 positions in the array:
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          const a = data[i + 3];

          // Update components for solid color and alpha averages:
          R += r;
          G += g;
          B += b;
          A += a;

          // Update components for alpha-weighted average:
          const w = a / 255;
          wR += r * w;
          wG += g * w;
          wB += b * w;
          wTotal += w;
        }

        const pixelsPerChannel = components / 4;

        // The | operator is used here to perform an integer division:

        R = R / pixelsPerChannel | 0;
        G = G / pixelsPerChannel | 0;
        B = B / pixelsPerChannel | 0;
        wR = wR / wTotal | 0;
        wG = wG / wTotal | 0;
        wB = wB / wTotal | 0;

        // The alpha channel need to be in the [0, 1] range:

        A = A / pixelsPerChannel / 255;

        return [R, G, B];
      }
    }();

    // var backgroundColorClass = BackgroundColorTheif.prototype;
    // this.rgbBackgroundColor = backgroundColorClass.getBackGroundColor(img, 5);
    // console.log(this.rgbBackgroundColor);

    console.log(arrAvgColors);

    // this.validatePhoto(this.croppedImg, (src) => {
    //   this.file = new File([this.dataURItoBlob(src)], Date.now() + '.png', { type: 'image/png' });
    //   this.dialogRef.close({ file: this.file });
    // });

    // if (this.isValidBackgroundColor(arrAvgColors)) {
    //   this.validatePhoto(this.croppedImg, (src) => {
    //     this.file = new File([this.dataURItoBlob(src)], Date.now() + '.png', { type: 'image/png' });
    //     console.log(this.file.size / (1024 * 1024) + "MB");
    //     if (this.file.size / (1024 * 1024) < this.maxPhotoSize) this.dialogRef.close({ file: this.file });
    //     else {
    //       this.currentMsg = "0006";
    //       this.dialog.open(FaceDetectionMessageComponent, {
    //         panelClass: 'width-message-dialog',
    //         data: {
    //           message_code: "0006",
    //           isConfirm: false,
    //         },
    //         disableClose: false, role: 'dialog'
    //       });
    //     }
    //   });
    // }
    // else {
    //   this.currentMsg = "0007";
    //   this.dialog.open(FaceDetectionMessageComponent, {
    //     panelClass: 'width-message-dialog',
    //     data: {
    //       message_code: "0007",
    //       isConfirm: false,
    //     },
    //     disableClose: false, role: 'dialog'
    //   });
    // }

    // this.isImageCropped = true;
    // document.getElementById("backGroundColor").style.backgroundColor = 'rgb(' + this.rgbBackgroundColor[0] + ',' + this.rgbBackgroundColor[1] + ',' + this.rgbBackgroundColor[2] + ')';
  }

  // get isValidBackgroundColor() {
  //   if (this.rgbBackgroundColor[0] < this.minimumRGBColorCode || this.rgbBackgroundColor[1] < this.minimumRGBColorCode || this.rgbBackgroundColor[2] < this.minimumRGBColorCode) return false;
  //   return true;
  // }

  isValidBackgroundColor(colors: Array<number>) {
    let isWhite: boolean = true;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i] < 230) {
        isWhite = false;
        break;
      }
    }
    return isWhite;
  }
}

export interface FileModel<T = File> {
  file?: T;
  key?: string;
}