<mat-sidenav-container [hasBackdrop]="handsetBreakpoint">
  <mat-sidenav class="mat-elevation-z1" [mode]="handsetBreakpoint ? 'push': 'side'" #start opened>
    <div class="nav-header">
      <div class="logo"></div>
    </div>
    
    <p class="archive-title">នាយកដ្ឋានបណ្ណសារ</p>

    <mat-nav-list>
      <ng-container #menuList *ngFor="let group of nav.displayMenuItems; let i = index">
        <h3 class="nav-group-title" mat-subheader *ngIf="group.groupTitle">{{group.groupTitle | translate }}</h3>
        <a [routerLink]="item.link" class="nav-item" *ngFor="let item of group.items; let j = index;" (click)="onClickMenu()" mat-list-item
          routerLinkActive="selected" [routerLinkActiveOptions]="{ exact: item.isNotExact }">
          <div class="flex-center nav-container">
            <div class="flex-center">
              <mat-icon class="item-icon">{{item.icon}}</mat-icon>
              <span class="m-l-10 item-title">{{ item.title | translate }}</span>
            </div>
            <span class="badge" *ngIf="item.badge">{{ item.badge }}</span>
          </div>
        </a>
        <!-- <button (click)="toggleMenuByIndex(i)" class="nav-group" mat-button mat-subheader *ngIf="group.groupTitle">
          <div class="between-box">
            <h2 class="nav-group-title">{{group.groupTitle | translate | uppercase}}</h2>
            <p><mat-icon class="nav-toggler-icon"> {{group.isExpanded ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon></p>
          </div>
        </button>
        <div @fade class="nav-items" *ngIf="group.isExpanded" [style.overflow]="'hidden'">
          <a [routerLink]="item.link" class="nav-item" *ngFor="let item of group.items; let j = index;" mat-list-item
            routerLinkActive="selected" [routerLinkActiveOptions]="{exact:true}">
            <div class="flex-center nav-container">
              <div class="flex-center">
                <mat-icon class="item-icon">{{item.icon}}</mat-icon>
                <span class="m-l-10 item-title">{{ item.title | translate | uppercase }}</span>
              </div>
              <span class="badge" *ngIf="item.badge">{{ item.badge }}</span>
            </div>
          </a>
        </div>
        <div class="border"></div> -->
      </ng-container>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="toolbar">
      <mat-toolbar-row>
        <button (click)="start.toggle()" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
        <div class="mat-search" *ngIf="isShowSearch">
          <form [formGroup]="formGroup" id="search-form">
            <div class="search-input">
              <input matInput formControlName="search" [placeholder]="'top_menu.search' | translate" type="text" (change)="onEmitSearch()">
              <button mat-icon-button (click)="onEmitSearch()"><mat-icon>search</mat-icon></button>
            </div>
            <button class="advanced-search-btn" mat-button [matMenuTriggerFor]="menu" (click)="openAdvanceSearch()">
              {{ 'top_menu.advanced_search' | translate }}
            </button>
            <mat-menu #menu="matMenu">
              <div class="search-dialog" (click)="preventMatMenu($event)">
                <div class="search-dialog-header">
                  <p>{{ 'top_menu.advanced_search' | translate }}</p>
                  <span class="spacer"></span>
                  <button mat-stroked-button class="btn-clear" (click)="onClearAdvancedSearch()">
                    {{ 'top_menu.clear' | translate }}
                  </button>
                </div>
                <!-- (click)="preventMatMenu($event)" -->
                <!-- Office -->
                <mat-form-field appearance="outline">
                  <mat-label>{{'top_menu.office' | translate}}</mat-label>
                  <mat-select formControlName="office" (selectionChange)="onEmitSearch()"
                    [placeholder]="'top_menu.office' | translate">
                    <mat-option class="option" value="">
                      {{'global.all' | translate}}
                    </mat-option>
                    <ng-template #parentList let-list>
                      <ng-container *ngFor="let office of list.data">
                        <div *ngIf="list.collapse || isEditing" [style.padding-left]=" 18 * list.index + 12 + 'px'"
                          class="tfield color-primary">
                          <button class="collapse-btn"
                            (click)="clickCollapse(office);"
                            [style.visibility]="office.childs.length ? 'show': 'hidden' " mat-icon-button>
                            <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                            <mat-icon *ngIf="office.collapse">arrow_drop_down</mat-icon>
                          </button>
                          <mat-option class="option" style="width: calc(100% - 45px); float: right; height: 40px;"
                            [value]="office._id">{{office.name}}
                          </mat-option>
                        </div>

                        <ng-container *ngIf="office.childs">
                          <ng-container
                            *ngTemplateOutlet="parentList; context:{ $implicit: { data: office.childs, index: list.index + 1, parent: office, collapse: office.collapse }}">
                          </ng-container>
                        </ng-container>

                      </ng-container>
                    </ng-template>

                    <ng-container
                      *ngTemplateOutlet="parentList; context:{ $implicit: {data: offices, index: 0, parent: null, collapse: true}}">
                    </ng-container>

                  </mat-select>
                </mat-form-field>

                <!-- Document Type -->
                <mat-form-field appearance="outline">
                  <mat-label>{{'top_menu.doc_type' | translate}}</mat-label>
                  <mat-select formControlName="doc_type" (selectionChange)="onLetterChange($event);onEmitSearch()"
                    [placeholder]="'top_menu.doc_type' | translate">
                    <mat-option class="option" [value]="''">
                      {{'global.all' | translate}}
                    </mat-option>
                    <ng-template #parentListDocType let-list>
                      <ng-container *ngFor="let doc_type of list.data">
                        <div *ngIf="list.collapse || isEditing" [style.padding-left]=" 18 * list.index + 12 + 'px'"
                          class="tfield color-primary">
                          <button class="collapse-btn"
                            (click)="clickCollapse(doc_type)"
                            [style.visibility]="doc_type.childs.length ? 'show': 'hidden' " mat-icon-button>
                            <mat-icon *ngIf="!doc_type.collapse">arrow_right</mat-icon>
                            <mat-icon *ngIf="doc_type.collapse">arrow_drop_down</mat-icon>
                          </button>
                          <mat-option [attr.data-letter]="list.index" class="option" style="width: calc(100% - 45px); float: right; height: 40px;" [value]="doc_type._id">
                            {{ doc_type.type }}
                          </mat-option>
                        </div>

                        <ng-container *ngIf="doc_type.childs">
                          <ng-container
                            *ngTemplateOutlet="parentListDocType; context:{ $implicit: { data: doc_type.childs, index: list.index + 1, parent: doc_type, collapse: doc_type.collapse }}">
                          </ng-container>
                        </ng-container>

                      </ng-container>
                    </ng-template>

                    <ng-container
                      *ngTemplateOutlet="parentListDocType; context:{ $implicit: {data: doc_types, index: 0, parent: null, collapse: true}}">
                    </ng-container>

                  </mat-select>
                </mat-form-field>

                <!--  CABINETS  -->
                <mat-form-field *ngIf="cabinets.length" color="accent" appearance="outline">
                  <mat-label>{{ "new_doc.cabinet" | translate }}</mat-label>
                  <mat-select formControlName="cabinet" [placeholder]="'new_doc.cabinet' | translate"
                    (selectionChange)="onSelectCabinet($event);onEmitSearch();">
                    <mat-option class="option" [value]="''">
                      {{'global.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let cabinet of cabinets" [value]="cabinet._id">{{ cabinet.code }}</mat-option>
                  </mat-select>
                </mat-form-field>
    
                <!--  BOX  -->
                <mat-form-field appearance="outline" *ngIf="boxes.length && formGroup.get('cabinet').value">
                  <mat-label>{{ "new_doc.box" | translate }}</mat-label>
                  <mat-select formControlName="box" [placeholder]="'new_doc.box' | translate" (selectionChange)="onSelectBox($event);onEmitSearch()">
                    <mat-option class="option" [value]="''">
                      {{'global.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let box of boxes" [value]="box._id">{{ box.code }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <!--  FOLDER  -->
                <mat-form-field appearance="outline" *ngIf="folders.length && formGroup.get('box').value">
                  <mat-label>{{ "new_doc.folder" | translate }}</mat-label>
                  <mat-select formControlName="folder" [placeholder]="'new_doc.folder' | translate" (selectionChange)="onEmitSearch()">
                    <mat-option class="option" [value]="''">
                      {{'global.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let folder of folders" [value]="folder._id">{{ folder.code }}</mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Public or Staff -->
                <!-- <div (click)="preventMatMenu($event)" class="control-box">
                  <mat-radio-group formControlName="accessibilities" (change)="onEmitSearch()" class="access-doc">
                    <mat-radio-button *ngFor="let access of accessibilities" [value]="access?._id">
                      {{ lang != 'en' ? access.name_kh : access.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div> -->

                <mat-form-field appearance="outline">
                  <mat-label>{{'report.start_date' | translate}}</mat-label>
                  <input matInput formControlName="start_date" [matDatepicker]="start_picker" (dateChange)="onEmitSearch()"
                    [placeholder]="'report.start_date' | translate">
                  <mat-datepicker-toggle matSuffix [for]="start_picker"></mat-datepicker-toggle>
                  <mat-datepicker #start_picker></mat-datepicker>
                </mat-form-field>
                
                <mat-form-field appearance="outline">
                  <mat-label>{{'report.end_date' | translate}}</mat-label>
                  <input matInput formControlName="end_date" [matDatepicker]="end_picker" (dateChange)="onEmitSearch()"
                    [placeholder]="'report.end_date' | translate">
                  <mat-datepicker-toggle matSuffix [for]="end_picker"></mat-datepicker-toggle>
                  <mat-datepicker #end_picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{'all_documents.detail_dialog.date' | translate}}</mat-label>
                  <input matInput formControlName="date" [matDatepicker]="date_picker" (dateChange)="onEmitSearch()"
                    [placeholder]="'all_documents.detail_dialog.date' | translate">
                  <mat-datepicker-toggle matSuffix [for]="date_picker"></mat-datepicker-toggle>
                  <mat-datepicker #date_picker></mat-datepicker>
                </mat-form-field>

                <!-- Is expired -->
                <div class="control-box">
                  <mat-checkbox formControlName="is_expired" (change)="onEmitSearch()">{{ "new_doc.expired_doc" | translate }}</mat-checkbox>

                  <mat-checkbox formControlName="state" (change)="onEmitSearch()">{{ "top_menu.soft_only" | translate }}</mat-checkbox>
                </div>
                <div class="footer">
                  <!-- <button mat-stroked-button (click)="">{{'global.export_as_excel' | translate}}</button> -->
                  <button class="m-r-10" (click)="exportAdvanceSearchToExcel()" mat-stroked-button>
                    <mat-icon>calendar_view_month</mat-icon>
                    {{ 'global.export_as_excel' | translate }}
                  </button>
                </div>
              </div>
            </mat-menu>
          </form>
        </div>

        <span class="spacer"></span>

        <button *ngIf="currentUserRole == '1' || currentUserRole == '2' || currentUserRole == '3' || currentUserRole == '5'" 
          class="notification-btn"
          mat-icon-button 
          (click)="notifications = []; getNotifications(1)"
          [matMenuTriggerFor]="notification">
          <mat-icon>notifications_none</mat-icon>
        </button>

        <mat-menu #notification="matMenu" xPosition="before">
          <div class="infinite-scroller" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
            (scrolled)="onScrollDown()" [scrollWindow]="false" [fromRoot]="true">

            <div class="notification-item" *ngFor="let notification of notifications" [routerLink]="['requests', 'document', 'detail', notification.onModel, notification?.request?._id]">
              <p class="message">{{ notification.message }}</p>
              <div class="notification-footer">
                <p>{{ notification?.reference?.office?.name }}</p>
                <span class="spacer"></span>
                <p>{{ notification.date | date:'dd-MM-yyyy h:mm a' }}</p>
              </div>
            </div>
          </div>
        </mat-menu>

        <!-- <button *ngIf="currentUserRole == '1' || currentUserRole == '2' || currentUserRole == '3' || currentUserRole == '4' || currentUserRole == '5'" mat-icon-button routerLink="/documents/new"
          class="create-new-btn">
          <mat-icon>control_point</mat-icon>
        </button> -->

        <div class="user-profile">
          <a routerLinkActive="selected" class="profile-link" mat-stroked-button *ngIf="profileName" routerLink="/users/profile">{{profileName}}</a>
        </div>

        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="languages">
            <mat-icon>language</mat-icon>
            {{'top_menu.languages.title' | translate}}
          </button>
          <button (click)="openDialogChangePassword()" mat-menu-item>
            <mat-icon>vpn_key</mat-icon>
            {{'top_menu.change_password' | translate}}
          </button>
          <button [disabled]="downloadingManual" mat-menu-item (click)="downloadManual()" *ngIf="manualUsers.includes(user.role._id)">
            <mat-icon>download</mat-icon>
            {{ downloadingManual ? ("global.start_downloading" | translate) : ('global.dm' | translate) }}
          </button>
          <button (click)="signOut()" mat-menu-item>
            <mat-icon>exit_to_app</mat-icon>
            {{'top_menu.sign_out' | translate}}
          </button>
        </mat-menu>

        <mat-menu #languages="matMenu">
          <button mat-menu-item (click)="switchLanguage('km')"><img src="/assets/images/khmer.png"
              class="flag-size" /> {{'top_menu.languages.khmer' | translate}}</button>
          <button mat-menu-item (click)="switchLanguage('en')"><img src="/assets/images/english.png"
              class="flag-size" /> {{'top_menu.languages.english' | translate}}</button>
        </mat-menu>

      </mat-toolbar-row>
    </mat-toolbar>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
