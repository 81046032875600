<h2 mat-dialog-title class="dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    {{this.data.file.name}}
</h2>
<mat-dialog-content class="file-content">
    <div *ngIf="isLoading" class="main">
        <mat-progress-bar mode="determinate" color="accent" [value]="progress"></mat-progress-bar>
        <!-- <p>{{"global.loading" | translate}}... : {{ progress }}%</p> -->
    </div>
    <!-- <img *ngIf="isImage; else isNotImage"> -->
    <img *ngIf="isImage && !isLoading" [src]="this.fileURL">
    <iframe (load)="revoke()" *ngIf="!isImage && !isLoading" id="viewer" class="main" width="800" height="400" [src]="fileURL" frameborder="0"></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close>{{ 'global.close' | translate }}</button>
    <!-- <button class="view-full" mat-raised-button (click)="viewFull()">{{"global.view_full" | translate}} <mat-icon>open_in_new</mat-icon></button> -->
</mat-dialog-actions>