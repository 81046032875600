<div
  class="container fixed"
  id="infinite-scroller"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  [fromRoot]="true"
  (scrolled)="onScrollDown()"
>
  <div style="height: 5px;">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
  </div>

  <div class="content">
    <mat-tab-group [selectedIndex]="selectedIndex - 1" animationDuration="10" (selectedTabChange)="onTabChange($event)">
      <mat-tab>
        <ng-template matTabLabel>
          <span style="margin: 5px" [matBadge]="isShowNumber ? nav.pendingUserBadge == 0 ? null : nav.pendingUserBadge : null"
            matBadgeColor="warn" matBadgeOverlap="false">{{ 'notification.c_request' | translate }}</span>
        </ng-template>

        <div class="tool-bar">
          <mat-form-field class="filter-option" appearance="outline">
            <mat-label>{{'notification.filter.title' | translate}}</mat-label>
            <mat-select [value]="statusTarget" (valueChange)="onFilter($event)">
              <mat-option *ngFor="let option of getUndeterminedStatuses() | keyvalue" [value]="option.value">
                {{'requests.status.' + option.key | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <app-tracking-item request="user" *ngFor="let item of undeterminedRequests" [item]="item"></app-tracking-item>

        <div *ngIf="!isLoading && undeterminedRequests?.length == 0" class="empty-box">
          <img src="./../../../../../../assets/images/empty1.svg" alt="">
          <h3>{{'empty_list' | translate}}</h3>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span style="margin: 5px" [matBadge]="isShowNumber ? nav.verifiedUserBadge == 0 ? null : nav.verifiedUserBadge : null"
            matBadgeColor="warn" matBadgeOverlap="false">{{ 'notification.request' | translate }}</span>
        </ng-template>

        <div class="tool-bar">
          <mat-form-field class="filter-option" appearance="outline">
            <mat-label>{{'notification.filter.title' | translate}}</mat-label>
            <mat-select [value]="statusTarget" (valueChange)="onFilter($event)">
              <mat-option *ngFor="let option of getDeterminedStatuses() | keyvalue" [value]="option.value">
                {{'requests.status.' + option.key | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-tracking-item request="user" *ngFor="let item of determinedRequests" [item]="item"></app-tracking-item>

        <div *ngIf="!isLoading && determinedRequests?.length == 0" class="empty-box">
          <img src="./../../../../../../assets/images/empty1.svg" alt="">
          <h3>{{'empty_list' | translate}}</h3>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
