import { LocalstorageService } from './../../../core/services/localstorage.service';
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivationStart, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { ApiService } from "../../../core/services/api.service";
import { AuthService } from "src/app/core/services/auth.service";
import { Location } from "@angular/common";
import { LoadingService } from "src/app/core/services/loading.service";
import { TranslateService } from "@ngx-translate/core";
import { ROLE } from "src/app/core/models/enum/role.enum";
import { LocalStorageEnum } from "src/app/core/models/enum/local-storage.enum";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  admin: string = "1";
  sender_receiver: string = "2";
  viewer: string = "3";
  office_admin: string = "4";

  isLoading: boolean = false;
  type: string = 'ball-spin-fade';
  size: string = 'medium';
  name: string = 'user';
  fullScreen: boolean = true;

  constructor(private titleService: Title,
    private router: Router,
    private location: Location,
    private ls: LocalstorageService,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService) {

    this.loadingService.loadingEventEmitter.subscribe((isLoading: boolean) => {
      setTimeout(() => { this.isLoading = isLoading; }, 0);
    });

    let lang: string = this.authService.getLocale();
    this.translate.addLangs(this.authService.getAvailableLocales());
    this.translate.setDefaultLang(lang);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((x) => x instanceof ActivationStart),
        filter((x) => x["snapshot"]),
        filter((x) => x["snapshot"]["data"]),
        filter((x) => x["snapshot"]["data"]["title"]),
        map((x) => x["snapshot"]["data"]["title"])
      )
      .subscribe((event) => {
        this.titleService.setTitle(event);
      });

      let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
      if (user && user.role._id.toString() == ROLE.OFFICER) {
        this.router.navigateByUrl("documents/all");
      }

    // if (localStorage.getItem("user_role_id") == "2") {
    //   if (
    //     this.location.path() !== "/documents/all" &&
    //     this.location.path() !== "/documents/types"
    //   ) {
    //     this.router.navigateByUrl("documents/all");
    //   }
    // } else if (localStorage.getItem("user_role_id") == "3") {
    //   if (this.location.path() !== "/documents/all") {
    //     this.router.navigateByUrl("documents/all");
    //   }
    // }
  }
}
