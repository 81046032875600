import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, Observable, filter } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { IChatRoom } from '../models/chat-room.model';
import { LocalStorageEnum } from '../models/enum/local-storage.enum';
import { IChatMessageResponse } from '../models/responses/chat-message.response';
import { AuthService } from './auth.service';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class TempChatService {
  public chatLoadEnd$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public chatLoadSubs: Subscription;
  public isLoading: boolean;

  private _tasks = 0;
  private socket: Socket;

  private room$: BehaviorSubject<any> = new BehaviorSubject(null);
  private activeRoom$: BehaviorSubject<IChatRoom> = new BehaviorSubject(null);
  private messages$: BehaviorSubject<any> = new BehaviorSubject(null);
  private privateMsg$: BehaviorSubject<any> = new BehaviorSubject(null);
  private refreshUserRooms$: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(
    private ls: LocalstorageService,
    private http: HttpClient,
    private authService: AuthService
    
  ) {}

  public initSocket() {
    if (!this.socket) {
      this.socket = io(environment.baseUrl, {
        transports: ['websocket'],
        withCredentials: true,
        auth: {
          token: this.ls.get(LocalStorageEnum.token)
        }
      });
  
      this.socket.on("connect_error", (err) => {
        console.log("%c/** SOCKET CONNECTION ERROR /", err.message, `color: orange`);
        console.log('--------------------------------------------------------------');
        console.log(' ');
      })
  
      this.socket.on("disconnect", (err) => {
        console.log(`%c/** SOCKET DISCONNECTED / ${err}`, `color: red`);
        console.log('--------------------------------------------------------------');
        console.log(' ');
  
        this.socket.removeAllListeners();
        this.socket.close();
      })
  
      this.socket.on("connect", () => {
        console.log(`%c/** SOCKET CONNECTED /`, `color: green`);
        console.log('--------------------------------------------------------------');
        console.log(' ');
        
      })
    }
  }

  public disconnectSocket() {
    this.socket.disconnect();
    this.socket.removeAllListeners();
    this.socket = null;
  }

  public reConnectSocket() {
    this.socket.connect();
  }

  public emitRefreshRoom(value: boolean | null) {
    // console.log(`emit refresh room from %c${from}`, `color: orange`)
    this.refreshUserRooms$.next(value);
  }

  public onRefreshRoom(): Observable<boolean> {
    return this.refreshUserRooms$.asObservable();
  }

  public emitSendMessage(text: string, room: string) {
    // console.log(`%clog emit sent msg`, `color: orange`)
    this.socket.emit('chat-message', { text, room });
  };

  public onSendMessage(): Observable<any> {
    this.privateMsg$.next(null);
    this.socket.off('chat-message');
    this.socket.on('chat-message', (response) => {
      // console.log('on sent message', response);
      this.privateMsg$.next(response);
    });
    return this.privateMsg$.asObservable().pipe(filter(response => response != null));
  };

  public emitUploadFile(files: any[], room: string) {
    let { size, type, name } = files[0]
    let file = files[0];
    let metadata = {
      size,
      type,
      name
    }

    this.socket.emit("upload-file", { file, metadata, room });
  };

  public emitUploadFileAndText(files: any[], room: string, text?: string) {
    let re_frm_files = files.map(file => {
      let { size, type, name } = file;
      let metadata = { size, type, name };

      return {
        buffer_file: file,
        metadata: metadata
      }
    });

    let data = {
      files: re_frm_files,
      room: room,
      text: text
    }

    this.socket.emit("upload-files", data);
  };

  public onUploadFileAndText(): Observable<any> {
    const commonRes$: BehaviorSubject<any> = new BehaviorSubject(null);
    commonRes$.next(null);

    this.socket.off('upload-files');
    this.socket.on('upload-files', (response) => {
      commonRes$.next(response);
    });

    return commonRes$.asObservable().pipe(filter(response => response != null));
  }

  public emitGetAllMessages(data: any) {
    this.socket.emit('get-message', data);
  };

  public emitGetAllUnseenMessages(data: any) {
    this.socket.emit('get-message', data);
  };

  /**
   * 
   * @param key 1: use for get all unseen messages and mark all of them to seen
   * @param key 2: use for get all messages
   * @returns 
   */
  public onGetAllMessages(): Observable<IChatMessageResponse> {
    this.messages$.next(null);
    this.socket.off('get-message');
    this.socket.on('get-message', (response) => {
      this.messages$.next(response);
    });

    return this.messages$.asObservable().pipe(filter(response => response != null));
  };

  public onGetAllUnseenMessages(): Observable<IChatMessageResponse> {
    this.messages$.next(null);
    this.socket.off('get-message');
    this.socket.on('get-message', (response) => {
      this.messages$.next(response);
    });

    return this.messages$.asObservable().pipe(filter(response => response != null));
  };

  public emitMarkMessageAsSeen(data: { chats?: string[], room?: string, seen: boolean }) {
    this.socket.emit('seen-message', data);
  }

  public onMarkMessageAsSeen(): Observable<any> {
    const commonRes$: BehaviorSubject<any> = new BehaviorSubject(null);
    commonRes$.next(null);

    this.socket.off('seen-message');
    this.socket.on('seen-message', (response) => {
      commonRes$.next(response);
    });

    return commonRes$.asObservable().pipe(filter(response => response != null));
  }


  // THIS EVENT EMIT (TO SOCKETS THAT IN THE SAME GROUP ONLY) WHEN SENT MESSAGE
  public onReceiveInternalMessage(): Observable<any> {
    this.privateMsg$.next(null);
    this.socket.off('message');
    this.socket.on('message', (response: any) => {
      this.privateMsg$.next(response);
    });

    return this.privateMsg$.asObservable().pipe(filter(response => response != null));
  };


  // THIS EVENT EMIT (TO ALL OTHERS SOCKETS, EVEN IF IT'S NOT IN THE SAME GROUP) WHEN SENT MESSAGE
  public onReceiveIntAndExtMessage() {
    this.refreshUserRooms$.next(null);
    this.socket.off('refresh-list');
    this.socket.on('refresh-list', (response: any) => {
      this.refreshUserRooms$.next(true);
    })

    return this.refreshUserRooms$.asObservable().pipe(filter(response => response != null));
  };

  public emitCreateRoom(data: any) {
    const commonRes$: BehaviorSubject<any> = new BehaviorSubject(null);
    commonRes$.next(null);
    this.socket.emit('create-room', data);
  };

  public onCreateRoom(): Observable<any> {
    const commonRes$: BehaviorSubject<any> = new BehaviorSubject(null);
    commonRes$.next(null);

    this.socket.on('create-room', (response) => {
      commonRes$.next(response);
    });

    return commonRes$.asObservable().pipe(filter(response => response != null));
  };

  public setActiveRoom(room: IChatRoom) {
    this.activeRoom$.next(room);
  }

  public getActiveRoom(): Observable<IChatRoom> {
    return this.activeRoom$.asObservable().pipe(filter(response => response != null));
  }

  public emitGetRooms() {
    // console.log(`%cemit get rooms from ${from}`, `color: orange`)
    this.socket.emit('get-user-rooms', {});
  };

  public onGetRooms(): Observable<{ rooms: IChatRoom[] }> {
    this.room$.next(null);
    this.socket.off('get-user-rooms');
    this.socket.on('get-user-rooms', (response) => {
      // console.log(`%con get rooms from ${from}`, `color: orange`)
      this.room$.next(response);
    });

    return this.room$.asObservable().pipe(filter(response => response != null));
  };

  public emitJoinRoom(room: string) {
    this.socket.emit('join-room', { room: room });
  };

  public onJoinRoom(): Observable<any> {
    const commonRes$: BehaviorSubject<any> = new BehaviorSubject(null);
    commonRes$.next(null);

    this.socket.on('join-room', (response) => {
      commonRes$.next(response);
    });

    return commonRes$.asObservable().pipe(filter(response => response != null));
  };

  public emitLeftRoom(room: string) {
    this.socket.emit('left-room', { room: room });
  };

  public getDocument(id: string) {
    const path = `${environment.baseUrl}chats/${id}`;
    return this.http.get(path, { headers: this.getHeader(), responseType: 'arraybuffer' });
  }

  public getImageDoc(id: string) {
    const path = `${environment.baseUrl}chats/${id}`;
    return this.http.get(path, { headers: this.getHeader(), responseType: 'blob' });
  }

  public getHeader() {
    const token = this.authService.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token} `
    });
  }
}

