import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleModel } from 'src/app/core/models/role.model';
import { NewAPIService } from 'src/app/core/services/new-api.service';

@Component({
  selector: 'app-user-role-checkbox-dialog',
  templateUrl: './user-role-checkbox-dialog.component.html',
  styleUrls: ['./user-role-checkbox-dialog.component.scss']
})
export class UserRoleCheckboxDialogComponent implements OnInit {

  roles: RoleModel[] = [];
  responseValue: number[] = [];
  existsRolesFromUser: number[] = [];
  constructor(
    private newApi: NewAPIService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string,
      filename?: string,
      existsRoles: number[]
    }
  ) { }

  ngOnInit(): void {
    if (!this.data.title) {
      this.data.title = "User roles"
    }
    this.newApi.getRoles().subscribe(response => {
      if (response.status) {
        this.roles = response.roles.filter(role => role._id > 3);
        this.appInit();
      }
    })
  }
  appInit(): void {
    if (this.data.existsRoles && this.data.existsRoles) {
      this.responseValue = [...this.data.existsRoles];
      this.existsRolesFromUser = this.data.existsRoles;
    }
  }
  isExistsRole(RoleID: number): boolean {
    if (!(this.data.existsRoles && this.data.existsRoles))
      return false
    return this.data.existsRoles.indexOf(RoleID) >= 0
  }
  userRoleCheckBoxChange(item: RoleModel, event): void {
    if (event.checked)
      this.responseValue.push(item._id);
    else
      this.responseValue.splice(this.responseValue.indexOf(item._id), 1);
  }
}
