import {Injectable} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root'})
export class LocaleService {
  language: string = 'en';

  private behavior_subject_language = new BehaviorSubject<any>(this.language);

  newLanguage = this.behavior_subject_language.asObservable();

  constructor(private auth: AuthService) { }

  bindLanguage(language: string) {
    this.auth.saveLocale(language);
    this.behavior_subject_language.next(language);
  }
}