import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Data {
  title: string
  descriptions: string[]
  negative: string
  positive: string
}

@Component({
  selector: 'app-alert-confirm-dialog',
  templateUrl: './alert-confirm-dialog.component.html',
  styleUrls: ['./alert-confirm-dialog.component.scss']
})
export class AlertConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void {
  }

}
