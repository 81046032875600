<h2 mat-dialog-title>{{ (reference.title || 'reason.title') | translate }}</h2>
<ng-template [ngIf]="data.request">
    <p *ngIf="data.request.tmp_model == 'document'">
        {{'reason.reject_request' | translate}} : <b>{{ data.request.reference.title }}</b> | {{'reason.request_by' | translate}} 
        <b>{{ data.request.requestor.first_name }} {{ data.request.requestor.last_name }}</b>
    </p>

    <p *ngIf="data.request.tmp_model == 'user'">
        {{'reason.reject_request' | translate}} : <b>{{ data.request.reference.title }} {{ data.request.reference.last_name }} {{ data.request.reference.first_name }}</b> | {{'reason.request_by' | translate}} 
        <b>{{ data.request.requestor.first_name }} {{ data.request.requestor.last_name }}</b>
    </p>
</ng-template>
<p *ngFor="let text of reference.questions || []">
    {{ text | translate }} <span class="accent-note">{{ reference.model == 'user' ? reference.data.first_name + " " + reference.data.last_name : reference.data.title }}</span> ?
</p>
<form class="example-form" [formGroup]="reasonFormGroup" (ngSubmit)="sumitReason()">
    <div mat-dialog-content>
        <mat-form-field class="example-full-width" appearance="outline">
                <textarea matInput [placeholder]="'reason.placeholder' | translate" type="text" [formControl]="reason" [required]="reference.reasonRequire"></textarea>
                <mat-error *ngIf="reason.hasError('required')">
                    {{'global.form.can_not_save'|translate}}{{'reason.placeholder'|translate}}{{'global.form.be_empty'|translate}}
                </mat-error>
        </mat-form-field>
        <mat-dialog-actions align="end">
            <button  class="m-r-10" mat-raised-button mat-dialog-close>{{'reason.button.cancel' | translate}}</button>
            <button type="submit" mat-raised-button [color]="data.isNegative ? 'warn' : 'accent'" [disabled]="reasonFormGroup.invalid">{{'reason.button.next' | translate}}</button>
        </mat-dialog-actions>
    </div>
</form>
