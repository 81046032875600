import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { DatePipe, Location, TitleCasePipe } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription, combineLatest, of, merge } from 'rxjs';
import { tap, map, debounceTime, catchError, startWith } from 'rxjs/operators';
import { fade } from 'src/app/core/animations/common-animations';
import { CabinetModel } from 'src/app/core/models/cabinet.model';
import { DocumentTypeModel } from 'src/app/core/models/document-type.model';
import { DocumentModel } from 'src/app/core/models/document.model';
import { DocumentModel as DocumentModelEnum } from 'src/app/core/models/emum/document-model.enum';
import { DOC_TYPES } from 'src/app/core/models/enum/doc_type.enum';
import { LocalStorageEnum } from 'src/app/core/models/enum/local-storage.enum';
import { MODELS } from 'src/app/core/models/enum/model.enum';
import { REQUEST_TYPE } from 'src/app/core/models/enum/request-type.enum';
import { STATUSES } from 'src/app/core/models/enum/request_status.enum';
import { ROLE } from 'src/app/core/models/enum/role.enum';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { DOC_STATE } from 'src/app/core/models/enum/states.enum';
import { IExternalDoc } from 'src/app/core/models/external-doc';
import { IFile } from 'src/app/core/models/file';
import { MoreForm } from 'src/app/core/models/more-form.model';
import { OfficeModel } from 'src/app/core/models/office.model';
import { OrganizationModel } from 'src/app/core/models/organization.model';
import { PeriodicElement } from 'src/app/core/models/periodic-element.model';
import { ProgressModel } from 'src/app/core/models/progress.model';
import { CreateDocumentResponse } from 'src/app/core/models/responses/create-document.response';
import { RoleModel } from 'src/app/core/models/role.model';
import { UserModel } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { NewAPIService } from 'src/app/core/services/new-api.service';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../../dialogs/alert/alert.component';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { UserListSelectComponent } from '../../dialogs/user-list-select/user-list-select.component';
import { UserRoleCheckboxDialogComponent } from '../../dialogs/user-role-checkbox-dialog/user-role-checkbox-dialog.component';
import { ViewFileDialogComponent } from '../../dialogs/view-file-dialog/view-file-dialog.component';
import { MessageComponent } from '../../snackbar/message/message.component';

export const BUCKET_EXTERNAL_DOC = 'external_document';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
  animations: [
    fade
  ]
})
export class DocumentFormComponent implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA]; // [ENTER, COMMA, SPACE];
  readonly PDF_PAGE_WARNING = 120;
  readonly BASE_URL: string = environment.baseUrl;
  keywords: string[] = [];
  formGroup: FormGroup;
  fileList: ProgressModel<IFile>[] = [];
  oldFileList: IFile[] = [];
  folders: CabinetModel[] = new Array();
  types: DocumentTypeModel[] = [];
  isRequestingCabinet: boolean = true;
  offices: OfficeModel[] = [];
  personalCodes: any[] = [];
  fileCancellable = true;
  parentDocuments: DocumentModel[] = [];
  filteredParentDocuments: Observable<DocumentModel[]>;
  isLoading: boolean = false;
  isOpenedUserRoleDialog: boolean = false;
  originX: HorizontalConnectionPos = 'start';
  originY: VerticalConnectionPos = 'bottom';
  overlayX: HorizontalConnectionPos = 'end';
  overlayY: VerticalConnectionPos = 'bottom';
  isFlying: boolean = false;
  isNotUserRoles: boolean = true;
  organizationList: OrganizationModel[] = [];
  filteredOrgs: Observable<OrganizationModel[]>;

  editingDocument: DocumentModel;
  editingDocumentID: string;

  editExternalDoc: IExternalDoc;
  editExternalDocID: string;
  isDocFromExternal: boolean = false;

  isEditing = false;
  isRequestDocument: boolean;
  isInDocument: boolean;
  havePublicFile: boolean = false;
  states: MoreForm[] = [];
  state_soft_only: any = null;
  is_soft_only: boolean = false;
  qualities: MoreForm[] = [];
  accessibilities: MoreForm[] = [];
  selectedAccessibility: MoreForm;
  currentDate = new Date(Date.now());
  disableSubmitBtn: boolean = true;
  documentsLoading = true;
  is_public: boolean = false;
  is_for_sensitivity: boolean = false;
  is_for_staff: boolean = false;
  is_expired: boolean = false;
  currentUserOfficeID: string = '';
  auto_letter_type: string = '';
  date_range: Array<string> = [];
  checkNormalDate: Boolean = true;
  auto_date: string = '';
  isPersonalCodeInp: boolean = false;
  default_date: string = (new Date('2025-01-01')).toISOString();
  cabinets: CabinetModel[] = [];
  boxes: CabinetModel[] = [];
  userRoles: RoleModel[];
  isPublicFileRequired: boolean = false;
  currentUserRole: string;
  deletedFiles: string[] = [];
  deletedOldFiles: string[] = [];
  lang: string = this.ls.get(LocalStorageEnum.language);
  fileEmpty: boolean = false;
  selectedItem: any = null;
  selectedLetterType: any = null;
  DOC_TYPES: any = DOC_TYPES;
  boxFormControl: FormControl;
  folderFormControl: FormControl;
  totalPdfPage: number = 0;
  today: Date = new Date();
  submiting: boolean = false;
  selectedUsers: UserModel[] = new Array();
  submitSubscription: Subscription = new Subscription();
  selectUserDisplayCol: string[] = ["no", "code", "username", "office", "remove"];
  request;
  showPublicFileCheckbox: boolean = false;
  reason: string; // reason of rejected request

  // professionalRoles = [ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER, ROLE.WRITER];

  // isProfessional: boolean;

  isProfessionalOfficer: boolean = false;
  isPostOfficer: boolean = false;
  isTemporaryDoc: boolean = false;

  user_id: string;
  isReqTypeCreate: boolean = false;
  isReqTypeVerify: boolean = false;
  isReqStatusPending: boolean = false;
  isReqStatusVerified: boolean = false;
  isReqStatusRejected: boolean = false;

  typeOptions: DocumentTypeModel[] = [];
  offceOptions: OfficeModel[] = [];

  typeSearchControl = new FormControl('');
  officeSearchControl = new FormControl('');

  @ViewChild(MatTable) selectedUsersTable: MatTable<PeriodicElement>;

  constructor(private formBuilder: FormBuilder,
    private newApi: NewAPIService,
    private route: ActivatedRoute,
    private ls: LocalstorageService,
    private location: Location,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) {

    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.currentUserRole = user.role._id.toString();
    this.currentUserOfficeID = user.office;
    this.user_id = user._id;

    this.isProfessionalOfficer = [ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER, ROLE.WRITER].includes(this.currentUserRole as ROLE);
    this.isPostOfficer = this.currentUserRole == ROLE.POST_OFFICER;

    this.translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      event.lang = this.authService.getLocale();
    });
  }

  goBack() {
    this.location.back();
  }

  isExistsID(model: any, id: string) {
    if (Array.isArray(model)) {
      if (model.find(m => m == id)) return true;
      return false;
    }
    return model == id
  }

  checkboxChanges(event: MatCheckboxChange, formName: string) {
    const valueChange = event.source.value;
    const target = this.formGroup.get(formName);
    const value: string[] = [...target.value];
    if (!value.includes(valueChange))
      value.push(valueChange);
    else
      value.splice(value.indexOf(valueChange), 1);
    target.patchValue(value);
  }

  radioChanges(event: MatRadioChange, formName: string) {
    this.formGroup.get(formName).patchValue(event.value);
  }

  ngOnInit() {
    this.isRequestDocument = this.route.snapshot.data.isRequest;
    this.boxFormControl = new FormControl('');
    this.folderFormControl = new FormControl('');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

    this.formGroup = this.formBuilder.group({
      title: ['', Validators.required],
      objective: [null],
      states: [null, Validators.required],
      state_soft_only: [[]],
      accessibilities: ['', Validators.required],
      clear_date: [''],
      quality: [null, Validators.required],
      type_id: [null, Validators.required],
      reference_id: [''],
      summary: ['',],
      office_id: [null, Validators.required],
      cabinet: [null],
      box: [null],
      date: ['', Validators.required],
      expired_date: ['']
    });

    // this.formGroup.get("cabinet").valueChanges.subscribe(this.onCabinetChange.bind(this));
    // this.formGroup.get("cabinet").disable();
    // this.formGroup.get("box").disable();

    // this.formGroup.get("office_id").valueChanges.subscribe(value => {
    //   if (!value || !this.isProfessionalOfficer) return;
    //   this.formGroup.get("cabinet").disable();
    //   this.isRequestingCabinet = true;
    //   this.newApi.getCabinetsByOfficeID(value).subscribe(response => {
    //     if (response.status == 1) {
    //       this.isRequestingCabinet = false;
    //       this.cabinets = response.cabinets;
    //       if (this.cabinets.length)
    //         this.formGroup.get("cabinet").enable();
    //     }
    //   });
    // });

    this.route.params.subscribe(param => {
      if (param.id) {
        this.isLoading = true;
        if (this.isRequestDocument) {
          this.newApi.newGetRequestById(param.id).subscribe(res => {
            if (res.status == SERVER_STATUS.SUCCESS) {
              this.isEditing = true;
              let val = res.request.reference;
              this.request = res.request;
              this.editingDocument = val;
              this.oldFileList = this.editingDocument.files;
              this.fileEmpty = this.oldFileList.length == 0 && this.fileList.length == 0;
              this.selectedLetterType = val.type;
              this.selectedUsers = val.id_cards;
              this.currentDate = val.expired_date as Date;
              this.selectedLetterType = val.letter_type;
              this.reason = res.request.reason;
              this.isReqTypeCreate = res.request.type.name == REQUEST_TYPE.CREATE;
              this.isReqTypeVerify = res.request.type.name == REQUEST_TYPE.VERYFY;
              this.isReqStatusPending = res.request.status.status_name == STATUSES.PENDING;
              this.isReqStatusVerified = res.request.status.status_name == STATUSES.VERIFIED;
              this.isReqStatusRejected = res.request.status.status_name == STATUSES.REJECTED;

              this.patchValuesToFields(this.editingDocument);

              this.newApi.getAccessibilities().subscribe(acc_res => {
                if (res.status != SERVER_STATUS.SUCCESS) {
                  return;
                }

                this.accessibilities = acc_res.accessibilities;
                this.selectedAccessibility = this.accessibilities.find(item => item._id == val.accessibilities[0]);
                this.showPublicFileCheckbox = !!(this.selectedAccessibility.name !== ACCESSIBLITY_CODE.PUBLIC);
              })

              if (this.isReqTypeCreate && this.isReqStatusRejected) {
                this.isEditing = false;

              } else if ((this.isReqTypeCreate || this.isReqTypeVerify) && (this.isReqStatusPending || this.isReqStatusVerified)) {
                this.isEditing = true;
              }

              // this.isTemporaryDoc = res.request.onModel == MODELS.TEMPORARY;
              // this.isEditing = res.request.type.name != REQUEST_TYPE.CREATE;

              // if (!val.role_ids) {
              //   this.isNotUserRoles = true;
              // } else {
              //   this.isNotUserRoles = false;
              // }
            }
          })
          return;
        }

        this.newApi.getDocumentById(param.id).subscribe((res: any) => {
          this.isLoading = false;
          if (res.status == SERVER_STATUS.SUCCESS) {
            this.editingDocument = res.doc;
            this.editingDocumentID = res.doc._id;
            this.selectedLetterType = res.doc.type;
            this.selectedUsers = res.doc.id_cards;
            this.isEditing = true;
            this.currentDate = res.doc.expired_date;
            this.oldFileList = this.editingDocument.files;
            this.totalPdfPage = res.doc.pages;
            this.patchValuesToFields(this.editingDocument);

            this.newApi.getAccessibilities().subscribe(acc_res => {
              if (res.status != SERVER_STATUS.SUCCESS) {
                return;
              }

              this.accessibilities = acc_res.accessibilities;
              this.selectedAccessibility = this.accessibilities.find(item => item._id == res.doc.accessibilities[0]);
              this.showPublicFileCheckbox = !!(this.selectedAccessibility.name !== ACCESSIBLITY_CODE.PUBLIC);
            })

            // if (!res.doc.role_ids) {
            //   this.isNotUserRoles = true;
            // } else {
            //   this.isNotUserRoles = false;
            // }
          }
        });

      } else if (param.ext_id) {
        this.isLoading = true;

        this.newApi.getExternalDocumentById(param.ext_id).subscribe(res => {
          this.isLoading = false;
          if (res.status == SERVER_STATUS.SUCCESS) {
            this.isDocFromExternal = true;
            this.editExternalDoc = res.external_documents;
            this.editExternalDocID = res.external_documents._id;
            this.oldFileList = this.editExternalDoc.files.map(file => {
              return {
                _id: file._id,
                name: file.name,
                bucketName: BUCKET_EXTERNAL_DOC
              }
            });
            this.patchExternalDocForm(this.editExternalDoc);

            this.newApi.getAccessibilities().subscribe(acc_res => {
              if (res.status != SERVER_STATUS.SUCCESS) {
                return;
              }

              this.accessibilities = acc_res.accessibilities;
              // this.showPublicFileCheckbox = !!(this.selectedAccessibility.name !== ACCESSIBLITY_CODE.PUBLIC);
            })
          }
        });

      } else {
        this.newApi.getAccessibilities().subscribe(res => {
          if (res.status != SERVER_STATUS.SUCCESS) {
            return;
          }

          this.accessibilities = res.accessibilities;
        })
      }
    });

    combineLatest([this.newApi.getStates(), this.newApi.getQualities(), /* this.newApi.getAccessibilities() */])
      .subscribe((responses: any[]) => {
        this.states = responses[0].states.filter((item: any) => item._id != DOC_STATE.SOFT_ONLY);
        this.state_soft_only = responses[0].states.find((item: any) => item._id == DOC_STATE.SOFT_ONLY);
        this.qualities = responses[1].qualities;
        // this.accessibilities = responses[2].accessibilities;
      });

    this.getUserRole();

    combineLatest([this.newApi.getOfficesTree(), this.newApi.getLetterTypeTree()/*, this.newApi.getCabinetsTree()*/])
      .pipe(
        tap(
          () => {
            this.isLoading = false;
          },
          (err) => (this.isLoading = true),
          () => (this.isLoading = false)
        )
      ).subscribe((response: any) => {
        this.offices = [];

        if (response[0].status) {
          response[0].offices.forEach((office: any) => {
            this.offices.push(...office.childs);

            office.childs.forEach((nested_office: any) => {
              if (!this.isProfessionalOfficer && (office.childs.length > 0) && (nested_office._id == this.editingDocument?.office._id)) {
                this.formGroup.patchValue({ office_id: '' });
              }
            });
          });
        }
        this.offceOptions = this.offices;
        this.traverseCollapse(this.offices[0]);

        if (response[1].status) {
          this.types = response[1].letter_types;
          this.typeOptions = this.types;
        }
        // if (response[2].status) this.cabinets = response[2].cabinets;
      });
  }

  onRadioStateChange(e: MatRadioChange) {
    if (e.value == DOC_STATE.COPY) {
      this.formGroup.get('state_soft_only').patchValue([DOC_STATE.SOFT_ONLY]);
      this.formGroup.get('state_soft_only').disable();
      this.is_soft_only = true;
      this.formGroup.get('cabinet').reset();
      this.formGroup.get('cabinet').updateValueAndValidity();

    } else {
      this.formGroup.get('state_soft_only').patchValue([]);
      this.formGroup.get('state_soft_only').enable();
      this.is_soft_only = false;
      this.formGroup.get('cabinet').reset();
      this.formGroup.get('cabinet').updateValueAndValidity();
    }
  }

  onStateOptionChange() {
    const values = this.formGroup.getRawValue();
    let soft_only = values.state_soft_only.find((item: any) => item == DOC_STATE.SOFT_ONLY);
    this.is_soft_only = !!soft_only;
    this.formGroup.get('cabinet').reset();
    this.formGroup.get('cabinet').updateValueAndValidity();
  }

  filterOrganization(name: string): OrganizationModel[] {
    if (typeof name !== 'string') {
      return [];
    }
    return this.organizationList.filter(org =>
      org.name
        .toLowerCase()
        .indexOf(name.toLowerCase()) !== -1);
  }

  filterParentDocuments(value: string): DocumentModel[] {
    if (typeof value !== 'string') {
      return [];
    }
    return this.parentDocuments.filter(x =>
      x.title.toLowerCase().indexOf(value.toLowerCase()) !== -1 || x.reference_id.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  displayOrgs(value: OrganizationModel): any {
    if (value) {
      return value.name;
    } else {
      return value;
    }
  }

  getCabinetByObjID(id: string): string {
    const targetCabinet = this.cabinets.find(cabinet => cabinet._id == id);
    if (targetCabinet)
      return targetCabinet.code;
    return '';
  }

  getBoxCodeByObjID(id: string): string {
    const targetBox = this.boxes.find(box => box._id == id);
    if (targetBox)
      return targetBox.code
    return '';
  }

  displayParent(value: DocumentModel): any {
    if (value) {
      return value.title;
    } else {
      return value;
    }
  }

  private startDeletedFileInTarget(): void {
    combineLatest([
      ...this.deletedFiles.map(id => this.newApi.deleteFile(id)),
      ...this.deletedOldFiles.map(id => this.newApi.deleteFileById(this.editingDocumentID, id))
    ])
      .subscribe();
  }

  addNewFileToDelete(id: number): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this.translate.instant('file_dialog.title'),
        subtitle: this.translate.instant('file_dialog.comfirm'),
        positiveText: this.translate.instant('file_dialog.positive'),
        negativeText: this.translate.instant('file_dialog.negative')
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        this.deletedFiles.push(this.fileList[id]._id);
        this.fileList.splice(id, 1);
        this.calculateTotalNumberPage();
        if (!this.fileList.length && !this.oldFileList.length) this.fileEmpty = true;
        // if ((this.oldFileList.length + this.fileList.length) == 0) {
        //   this.formGroup.get('date').reset();
        //   this.formGroup.get('type_id').reset();
        //   this.formGroup.get('reference_id').reset();
        // }
      }
    })
  }

  addOldFileToDelete(index: number): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this.translate.instant('file_dialog.title'),
        subtitle: this.translate.instant('file_dialog.comfirm'),
        positiveText: this.translate.instant('file_dialog.positive'),
        negativeText: this.translate.instant('file_dialog.negative')
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        this.deletedOldFiles.push(this.oldFileList[index]._id);
        this.oldFileList.splice(index, 1);
        this.calculateTotalNumberPage();
        if (!this.fileList.length && !this.oldFileList.length) this.fileEmpty = true;
        // if ((this.oldFileList.length + this.fileList.length) == 0) {
        //   this.formGroup.get('date').reset();
        //   this.formGroup.get('type_id').reset();
        //   this.formGroup.get('reference_id').reset();
        // }
      }
    });
  }

  onViewFile(file: any) {
    this.dialog.open<ViewFileDialogComponent>(ViewFileDialogComponent, {
      height: "90vh",
      autoFocus: false,
      disableClose: true,
      backdropClass: "file-viewer",
      panelClass: "custom-file-container",
      data: {
        file,
        is_download: false,
        is_external_file: !!(file.bucketName && file.bucketName == BUCKET_EXTERNAL_DOC)
      }
    })
  }

  patchValuesToFields(doc: DocumentModel) {
    this.formGroup.get("cabinet").disable();
    this.isRequestingCabinet = true;
    /**
     * Reset office which is parent when user's role is not professonal officer
     */
    this.offices.forEach((item: any) => {
      if (!this.isProfessionalOfficer && !this.isPostOfficer && (item.childs.length > 0) && (item._id == this.editingDocument?.office._id)) {
        this.formGroup.patchValue({ office_id: '' });
      }
    });

    // if (this.isProfessionalOfficer) {
    //   this.newApi.getCabinetsByOfficeID(doc.office._id).subscribe(response => {
    //     if (response.status == 1) {
    //       this.cabinets = response.cabinets;
    //       if (this.cabinets.length)
    //         this.formGroup.get("cabinet").enable();
    //       this.formGroup.patchValue({
    //         ...doc,
    //         type_id: doc.type._id,
    //         office_id: doc.office._id,
    //         expired_date: doc.expired_date,
    //         cabinet: doc.cabinet ? doc.cabinet._id : null,
    //         box: doc.box ? doc.box._id : '',
    //         accessibilities: Array.isArray(doc.accessibilities) ? doc.accessibilities[0] : doc.accessibilities
    //       });
    //     }
    //   });
    // }

    this.personalCodes = doc.id_cards;

    let soft_only = doc.states.find((item: any) => item._id == DOC_STATE.SOFT_ONLY);
    doc.state_soft_only = soft_only ? [soft_only._id] : [];
    this.is_soft_only = !!soft_only;

    let common_state = doc.states.find((item: any) => item._id != DOC_STATE.SOFT_ONLY);
    doc.states = common_state ? common_state._id : '';

    let doc_state_copy = !!(doc.states as unknown as string == DOC_STATE.COPY);
    if (doc_state_copy) this.formGroup.get('state_soft_only').disable();

    doc.accessibilities = doc.accessibilities.map((item: any) => item._id);
    doc.quality = doc.quality._id;
    if (this.isProfessionalOfficer) {
      this.formGroup.get("cabinet").enable();
      this.newApi.getCabinetsByOfficeID(doc.office._id).subscribe((response: any) => {
        if (response.status) {
          this.cabinets = response.cabinets;
          this.onOfficeChange(doc.office);
          // this.onCabinetChange({ value: doc.cabinet ? doc.cabinet._id : null });

          let selectedCabinet: any = this.cabinets.find(cabinet => doc.cabinet?._id == cabinet?._id);
          if (selectedCabinet) this.boxes = selectedCabinet.childs;
          this.formGroup.patchValue({
            ...doc,
            type_id: doc.type._id,
            office_id: doc.office._id,
            expired_date: doc.expired_date,
            cabinet: (doc.cabinet && !this.is_soft_only) ? doc.cabinet._id : '',
            box: doc.box ? doc.box._id : '',
            accessibilities: doc.accessibilities[0]
          });
        }
      });
    } else {
      this.formGroup.patchValue({
        ...doc,
        type_id: doc.type._id,
        office_id: doc.office._id,
        expired_date: doc.expired_date,
        cabinet: (doc.cabinet && !this.is_soft_only) ? doc.cabinet._id : '',
        box: doc.box ? doc.box._id : '',
        accessibilities: doc.accessibilities[0]
      });
    }
    this.personalCodes = doc.id_cards;

    // disable field whose fields are of external documents
    if (doc.external_document && doc.external_document._id) {
      this.formGroup.get('title').disable();
      this.formGroup.get('reference_id').disable();
      this.formGroup.get('date').disable();
    }

    try {
      this.is_public = doc.is_public;
      this.is_for_staff = doc.is_for_staff;
      this.is_expired = doc.is_expired;

      if (doc.date.includes('-')) {
        this.checkNormalDate = true;
        this.auto_date = doc.date;
      }
      else {
        this.auto_date = doc.date;
        this.checkNormalDate = false;
      }

      this.keywords = doc.keywords ? doc.keywords.split(',') : [];
    } catch (err: any) {
    }
  }

  patchExternalDocForm(doc: IExternalDoc) {
    this.formGroup.patchValue({
      title: doc.title,
      reference_id: doc.reference_id,
      date: doc.date
    });

    this.formGroup.get('title').disable();
    this.formGroup.get('reference_id').disable();
    this.formGroup.get('date').disable();
  }

  editDocument() {
    /*================ Validate Empty File ================*/
    if (!this.fileList.length && !this.oldFileList.length) {
      this.fileEmpty = true;
      this.unCompleteRequirementDialog();
      return;
    }


    /*================ Validate Formgroup ================*/
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.unCompleteRequirementDialog();
      return;
    }


    /*=============== Validate Public File ===============*/
    // if (this.accessibilities.find(access => access._id == values.accessibilities).name == "Public") {
    //   if (!newDocument.files.filter(file => file.is_public).length || !newDocument.files.filter(file => file.is_public).length) {
    //     this.isPublicFileRequired = true;
    //     this.unCompleteRequirementDialog();
    //     return;
    //   }
    // }


    /*==================Submitting Data==================*/
    this.submiting = true;
    if (this.isRequestDocument) this.onEditRequestDocument();
    else this.onEditDocument();
  }

  onEditRequestDocument() {
    const values = this.formGroup.getRawValue();
    let newDocument: DocumentModel = {
      title: values.title,
      objective: values.objective || null,
      type_id: values.type_id || null,
      quality: values.quality || null,
      clear_date: this.transformDateApiFormat(values.clear_date),
      states: [
        values.states,
        ...values.state_soft_only
      ],
      accessibilities: [values.accessibilities],
      reference_id: values.reference_id,
      summary: values.summary,
      keywords: this.keywords.join(','),
      office_id: values.office_id ? values.office_id : null,
      id_cards: this.selectedUsers.map(user => user.personal_code),
      is_expired: this.is_expired,
      date: this.transformDateApiFormat(values.date),
      files: [
        ...this.oldFileList,
        ...this.fileList
      ],
      expired_date: this.transformDateApiFormat(values.expired_date),
      cabinet: values.cabinet || null,
      box: values.box || null,
      request_id: this.isRequestDocument ? this.request._id : undefined,
      role_ids: values.role_ids
    }


    this.submitSubscription = this.newApi.updateRequestDocumentById(this.request._id, newDocument).subscribe({
      next: (response => {
        if (response.status == SERVER_STATUS.ERROR_CLIENT && response.message == 'Duplicate Document') {
          this.snackbar.open(this.translate.instant('new_doc.dialog.error.duplicate_doc'), '', { duration: 2000, panelClass: 'panelClass-error' });
          return;

        } else if (response.status != SERVER_STATUS.SUCCESS) {
          this.snackbar.open(this.translate.instant('new_doc.dialog.error.update_doc'), '', { duration: 2000, panelClass: 'panelClass-error' });
          return;
        };

        if (this.currentUserRole == ROLE.ADMIN) {
          // this.startDeletedFileInTarget();
          this.resetAndNavigate(response);

        } else {
          // this.router.navigateByUrl('/requests/document');
          this.goBack();
        }

      }),
      error: (err => {
        throw new Error(JSON.stringify(err));
      }),
      complete: (() => this.submiting = false)
    });
  }

  onEditDocument() {
    const values = this.formGroup.getRawValue();
    let newDocument: DocumentModel = {
      title: values.title,
      objective: values.objective,
      type_id: values.type_id,
      quality: values.quality,
      clear_date: this.transformDateApiFormat(values.clear_date),//this.datePipe.transform(values.clear_date, 'yyyy-MM-dd'),
      states: [
        values.states,
        ...values.state_soft_only
      ],
      accessibilities: values.accessibilities,
      reference_id: values.reference_id,
      summary: values.summary,
      keywords: this.keywords.join(','),
      office_id: values.office_id ? values.office_id : null,
      id_cards: this.selectedUsers.map(user => user.personal_code),
      is_expired: this.is_expired,
      date: this.transformDateApiFormat(values.date),//this.datePipe.transform(values.date, "yyyy-MM-dd"),
      files: [
        ...this.oldFileList.map(old => ({ id: old._id, is_public: old.is_public, bucketName: old.bucketName })),
        ...this.fileList.map((file) => ({ id: file._id, is_public: file.is_public || false }))
      ],
      expired_date: this.transformDateApiFormat(values.expired_date),
      cabinet: values.cabinet,
      box: values.box,
      request_id: this.isRequestDocument ? this.request._id : undefined,
      role_ids: values.role_ids
    }

    let edit_id = this.isDocFromExternal ? this.editExternalDoc._id : this.editingDocument._id;

    this.submitSubscription = this.newApi.editDocument(edit_id, newDocument).subscribe({
      next: (response => {
        if (response.status == SERVER_STATUS.ERROR_CLIENT && response.message == 'Duplicate Document') {
          this.snackbar.open(this.translate.instant('new_doc.dialog.error.duplicate_doc'), '', { duration: 2000, panelClass: 'panelClass-error' });
          return;
        }
        else if (response.status != SERVER_STATUS.SUCCESS) {
          this.snackbar.open(this.translate.instant('new_doc.dialog.error.update_doc'), '', { duration: 2000, panelClass: 'panelClass-error' });
          return;
        };
        if (this.currentUserRole == ROLE.ADMIN) {
          // this.startDeletedFileInTarget();
          this.resetAndNavigate(response);
        }
        else this.router.navigateByUrl('/requests/document');
      }),
      error: (err => {
        throw new Error(JSON.stringify(err));
      }),
      complete: (() => this.submiting = false)
    });
  }

  onActionAlreadyExist(response: any): void {
    console.log(response)
    if (response.onModel == DocumentModelEnum.Document) { }
  }

  public createDocument() {
    // if (this.isTemporaryDoc) {
    //   this.createDocumentTemporary();

    // } else {

    // }
    if (this.fileList.length == 0 && this.oldFileList.length == 0) {
      this.fileEmpty = true;
      this.formGroup.markAllAsTouched();
      this.unCompleteRequirementDialog();
      return;
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.unCompleteRequirementDialog();
      return;
    }
    const values = this.formGroup.getRawValue();
    const newDocument = {
      title: values.title,
      objective: values.objective,
      type_id: values.type_id,
      quality: values.quality,
      states: [
        values.states,
        ...values.state_soft_only
      ],
      clear_date: this.transformDateApiFormat(values.clear_date),//this.datePipe.transform(values.clear_date, 'yyyy-MM-dd'),
      accessibilities: values.accessibilities,
      reference_id: values.reference_id,
      summary: values.summary,
      id_cards: this.selectedUsers.map(user => user.personal_code),
      keywords: this.keywords.join(','),
      office_id: values.office_id ? values.office_id : null,
      is_expired: this.is_expired,
      date: this.transformDateApiFormat(values.date),//this.datePipe.transform(values.date, 'yyyy-MM-dd'),
      files: [
        ...this.oldFileList.map((file) => ({ id: file._id, is_public: file.is_public || false, bucketName: file.bucketName })),
        ...this.fileList.map((file) => ({ id: file._id, is_public: file.is_public || false }))
      ],
      expired_date: this.transformDateApiFormat(values.expired_date),// this.datePipe.transform(values.expired_date, 'yyyy-MM-dd'),
      request_id: this.isRequestDocument ? this.request._id : undefined,
      cabinet: values.cabinet,
      box: values.box,
      external_document: this.editExternalDocID || undefined
    }

    if (this.accessibilities.find(access => access._id == values.accessibilities).name == "Public") {
      if (!newDocument.files.filter(file => file.is_public).length || !newDocument.files.filter(file => file.is_public).length) {
        this.isPublicFileRequired = true;
        this.unCompleteRequirementDialog();
        return;
      }
    }

    console.log('new doc submit', newDocument);


    this.submiting = true;
    this.submitSubscription = this.newApi.createDocument(newDocument)
      .subscribe({
        next: response => {
          if (response.status != SERVER_STATUS.SUCCESS) {
            if (response.onModel) {
              const redirectTo = response.onModel == DocumentModelEnum.Document ? ["/", "documents", "detail", response.data[0]._id] : ["/", "requests", "document", "detail", "document", response.data[0].request];
              this.snackbar.openFromComponent(MessageComponent, {
                data: {
                  message: 'new_doc.already_exist.' + response.onModel,
                  action: true,
                  actionTxt: "notification.button.view_detail",
                  actionIcon: "visibility",
                  actionColor: 'accent',
                  onAction: () => {
                    this.router.navigate(redirectTo)
                  },
                },
                duration: 5000,
                panelClass: ['action-snackbar', 'panelClass-error']
              });
              return;
            }
            this.snackbar.open(this.translate.instant('global.error.sww'), ' ', { duration: 2000, panelClass: 'panelClass-error' })
            return;
          }
          this.startDeletedFileInTarget();
          this.resetDocumentForm();

          if (this.isRequestDocument) {
            this.router.navigateByUrl('/requests/document');
          }
          // if (this.currentUserRole == ROLE.ADMIN) {
          //   this.resetAndNavigate(response);
          // }
          // else
          //   this.router.navigateByUrl('/requests/document');
        },
        complete: () => {
          this.submiting = false;
        }
      });
  }

  /**
   * In case the document is temporary document.
   * This state that the doc is not fully created just yet but it has the informations.
   * That's y we pasted all those value into form and use the route Create instead of Edit.
   * 
   * @Note: Method Currently Unused
   */
  public createDocumentTemporary() {
    if (!this.fileList.length && !this.oldFileList.length) {
      this.fileEmpty = true;
      this.unCompleteRequirementDialog();
      return;
    }

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.unCompleteRequirementDialog();
      return;
    }

    const values = this.formGroup.getRawValue();
    let newDocument = {
      title: values.title,
      objective: values.objective,
      type_id: values.type_id,
      quality: values.quality,
      clear_date: this.transformDateApiFormat(values.clear_date),
      states: values.states,
      accessibilities: values.accessibilities,
      reference_id: values.reference_id,
      summary: values.summary,
      keywords: this.keywords.join(','),
      office_id: values.office_id ? values.office_id : null,
      id_cards: this.selectedUsers.map(user => user.personal_code),
      is_expired: this.is_expired,
      date: values.date,
      files: [
        ...this.oldFileList.map(old => ({ id: old._id, is_public: old.is_public })),
        ...this.fileList.map((file) => ({ id: file._id, is_public: file.is_public || false }))
      ],
      expired_date: values.expired_date || null,
      cabinet: values.cabinet,
      box: values.box,
      role_ids: values.role_ids
    }

    if (this.accessibilities.find(access => access._id == values.accessibilities).name == "Public") {
      if (!newDocument.files.filter(file => file.is_public).length || !newDocument.files.filter(file => file.is_public).length) {
        this.isPublicFileRequired = true;
        this.unCompleteRequirementDialog();
        return;
      }
    }

    this.submiting = true;
    this.submitSubscription = this.newApi.createDocument(newDocument)
      .subscribe(response => {
        if (response.status < 1) {
          return;
        }
        if (this.currentUserRole == ROLE.ADMIN) {
          this.startDeletedFileInTarget();
          this.resetAndNavigate(response);
        }
        else
          this.router.navigateByUrl('/requests/document');
      });
  }

  calculateTotalNumberPage(dialog: boolean = false): void {
    var counter = 0;
    if (this.oldFileList)
      counter += this.oldFileList.reduce((prev, current) => prev + current.pages, 0);
    if (this.fileList)
      counter += this.fileList.reduce((prev, current) => prev + current.pages, 0);
    this.totalPdfPage = counter;
    if (dialog) {
      if (this.totalPdfPage >= this.PDF_PAGE_WARNING) {
        this.dialog.open(ConfirmDialogComponent, {
          width: '450px',
          autoFocus: true,
          disableClose: true,
          data: {
            title: this.translate.instant('new_doc.dialog.total_page.title'),
            subtitle: this.translate.instant('new_doc.dialog.total_page.confirm'),
            hightlight: this.PDF_PAGE_WARNING,
            lastSubtitle: this.translate.instant('new_doc.dialog.total_page.confirm_question'),
            positiveText: this.translate.instant('new_doc.dialog.total_page.accept'),
            negativeText: this.translate.instant('new_doc.dialog.total_page.reject')
          }
        }).afterClosed().subscribe(response => {
          if (response) return;
          let lastFile = this.fileList[this.fileList.length - 1];
          this.newApi.deleteFile(lastFile._id).subscribe(response => {
            if (response.status == SERVER_STATUS.SUCCESS) {
              this.fileList.splice(this.fileList.indexOf(lastFile), 1);
              this.calculateTotalNumberPage();
            }
          })
        })
      }
      //  else {
      //   this.dialog.open(AlertComponent, {
      //     width: '450px',
      //     data: {
      //       mainTitle: this.translate.instant('new_doc.dialog.total_page.title'),
      //       desc: this.translate.instant('new_doc.dialog.total_page.alert') + ' ' + this.totalPdfPage
      //     }
      //   })
      // }
    }
  }

  resetAndNavigate(response: CreateDocumentResponse) {
    this.formGroup.reset();
    this.keywords = [];
    this.fileList = [];
    this.navigateToDetail(response.doc);
  }

  resetDocumentForm() {
    this.formGroup.reset();
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
    this.keywords = [];
    this.fileList = [];
    this.oldFileList = [];
    this.selectedUsers = [];
    this.formGroup.get('state_soft_only').patchValue([]);
  }

  private unCompleteRequirementDialog(): void {
    let container = document.getElementById("infinite-scroller");
    this.dialog.open(AlertComponent, {
      width: '450px',
      data: {
        desc: this.translate.instant("new_doc.dialog.error.requirement.title"),
      }
    }).afterClosed().subscribe(() => {
      container.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  getEventMessage(event: HttpEvent<Response>, progress: ProgressModel): ProgressModel {
    switch (event.type) {
      case HttpEventType.Sent:
        return { ...progress, message: 'Uploading' };
      case HttpEventType.UploadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return { ...progress, progress: percentDone };
      case HttpEventType.Response:
        return { ...progress, completed: true, message: 'Completed', ...event.body };
      default:
        return { ...progress };
    }
  }

  add(event: MatChipInputEvent): void {
    const { input, value } = event;

    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  isNotDefaultRole(roles: number[]) {
    return roles.filter(id => id < 5 && id !== 1).length > 0;
  }

  remove(keyword: string): void {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  unUploadedFiles: ProgressModel[] = [];

  // private deepFindChilds<T>(references: any[], queryCode: string): T {
  //   references.map(reference => {
  //     if (reference.code == queryCode)
  //       return reference;
  //   });
  // }

  onFileUploaded(event: Event) {
    const fileElement = event.target as HTMLInputElement;
    const files = fileElement.files;
    Array.from(files).forEach((file, i) => {
      this.unUploadedFiles.push({
        file,
        filename: file.name,
        progress: 0,
        message: 'Pending',
        completed: false,
        index: i,
        error: false
      })
    });

    this.fileEmpty = false;

    this.fileCancellable = false;
    const filesArray = this.unUploadedFiles.map((progress, index) => {
      return this.newApi.uploadFile(progress.file, progress.file.name)
        .pipe(map(event => {
          return this.getEventMessage(event, progress);
        }, debounceTime(500)),
          catchError(e => {
            return of({ ...progress, error: true })
          }));
    });

    this.formGroup.disable();
    merge(...filesArray)
      .pipe(tap(e => { }, e => { }, () => {
        this.formGroup.enable();
        this.unUploadedFiles = [];
        // update form
        if (!this.isEditing) {
          let titleSplit = this.fileList[0].name.split("_");
          let docType, code, title = "", date;
          const changedDate = this.changeToGNumber(titleSplit[1]).split("-");
          date = new Date(parseInt(changedDate[2]), parseInt(changedDate[1]) - 1, parseInt(changedDate[0]));
          this.newApi.getLetterTypeByCode(titleSplit[0]).subscribe(response => {
            if (response.status == 1) {
              docType = response.letter_type._id;
            }
            if (titleSplit.length < 4) {
              title = titleSplit[2] ? titleSplit[2].slice(0, titleSplit[2].search(/\./i)) : '';
            } else if (titleSplit.length == 4) {
              code = titleSplit[2] != "គ្មាន" ? titleSplit[2] : null;
              title = titleSplit[3];
            }
            let arr_title: Array<string> = title.split('.');
            title = arr_title[0];

            let val = this.formGroup.getRawValue();
            this.formGroup.patchValue({
              type_id: docType || val.type_id,
              title: title || val.title,
              reference_id: code || val.reference_id,
              date: date || val.date
            });
          })
        }
      }))
      .subscribe((filesResponse: any) => {
        this.unUploadedFiles[filesResponse.index].progress = filesResponse.progress;
        this.unUploadedFiles[filesResponse.index].error = filesResponse.error;
        if (filesResponse.completed) {
          this.unUploadedFiles[filesResponse.index].completed = filesResponse.completed;
          this.fileList.push({
            file: filesResponse.filename,
            name: filesResponse.filename,
            _id: filesResponse.file._id,
            pages: filesResponse.file.metadata.pages,
            is_public: false,
            message: filesResponse.message,
            progress: filesResponse.progress,
            completed: filesResponse.completed,
          });
          this.calculateTotalNumberPage(true);
        }
      });
    // already upload files
    // try {
    //   let arrString = fileElement.value.split('\\');

    //   arrString = arrString[arrString.length - 1].split('_');
    //   let finalArrayString = arrString.splice(0, 3);

    //   if (arrString.join('_') != '') finalArrayString.push(arrString.join('_'));

    //   let date, reference_id = this.formGroup.value.reference_id, title;
    //   this.auto_letter_type = finalArrayString[0];

    //   if (finalArrayString.length == 3) {
    //     date = finalArrayString[1];
    //     if (finalArrayString[2].includes('.')) {
    //       title = finalArrayString[2].slice(0, finalArrayString[2].indexOf('.'));
    //     } else {
    //       title = finalArrayString[2];
    //     }
    //   } else if (finalArrayString.length > 3) {
    //     date = finalArrayString[1];
    //     reference_id = finalArrayString[2];
    //     if (finalArrayString[3].includes('.')) {
    //       title = finalArrayString[3].slice(0, finalArrayString[3].indexOf('.'));
    //     } else {
    //       title = finalArrayString[3];
    //     }
    //   }

    //   if (date && date.includes('-')) {
    //     this.checkNormalDate = true;
    //     let arrDate = date.split('-');
    //     if (arrDate[0] < 10) arrDate[0] = '0' + arrDate[0];
    //     if (arrDate[1] < 10) arrDate[1] = '0' + arrDate[1];

    //     this.auto_date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
    //   }
    //   else {
    //     this.auto_date = date ? date + "-01-01" : '';
    //     this.checkNormalDate = false;
    //   }

    //   this.selectedItem = null;
    //   this.findItemInTree(this.types, this.auto_letter_type);
    //   this.selectedLetterType = this.selectedItem;

    //   if (this.auto_date) this.formGroup.get('date').setValue(this.auto_date);
    //   if (this.selectedItem) this.formGroup.get('type_id').setValue(this.selectedItem?._id);
    // }
    // catch (err) {
    //   console.error(err)
    // }
  }

  get cabinetControl(): AbstractControl {
    return this.formGroup.get('cabinet');
  }
  get officeControl(): AbstractControl {
    return this.formGroup.get('office_id');
  }

  changeToGNumber(string: string): string {
    const numbers: string[] = ['០', '១', '២', '៣', '៤', '៥', '៦', '៧', '៨', '៩'];
    return string.replace(/១|២|៣|៤|៥|៦|៧|៨|៩|០|/g, (match) => {
      if (!match)
        return ""
      return numbers.indexOf(match).toString();
    })
  }

  onOfficeSelectChange(): void {
    const value = this.officeControl.value;
    if (!value || !this.isProfessionalOfficer) return;
    this.cabinetControl.disable();
    this.isRequestingCabinet = true;
    this.newApi.getCabinetsByOfficeID(value).subscribe(response => {
      if (response.status == 1) {
        this.isRequestingCabinet = false;
        this.cabinets = response.cabinets;
        if (this.cabinets.length)
          this.cabinetControl.enable();
      }
    });
  }

  // onCabinetChange(cabinetID) {
  //   this.folders = [];
  //   this.boxes = [];
  //   let selectedCabinet: any = this.cabinets.find(cabinet => cabinetID == cabinet._id);
  //   if (selectedCabinet) {
  //     this.boxes = selectedCabinet.childs;
  //     this.boxes.forEach(box => {
  //       this.folders.push(...box.childs)
  //     });
  //   }
  // }

  onOfficeChange(office) {
    if (office && office?.childs?.length == 0) {
      this.formGroup.patchValue({ cabinet: office.cabinet?._id });
      let selectedCabinet: any = this.cabinets.find(cabinet => office.cabinet?._id == cabinet?._id);
      if (selectedCabinet) this.boxes = selectedCabinet.childs;
    }
    else {
      this.formGroup.patchValue({ cabinet: '' });
    }
  }

  /////// Personal Codes Start ///////
  personalFormVisible = true;
  personalSelectable = true;
  personalRemovable = true;
  personalAddOnBlur = true;
  readonly personalSeparatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  onAddPersonalCode(event: MatChipInputEvent) {
    let code = event.value;
    if (!this.personalCodes.includes(code) && code !== '') {
      this.personalCodes.push(code);
      event.input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      event.input.value = '';
    } else if (this.personalCodes.includes(code)) {
      this.dialog.open(AlertComponent, {
        width: '450px',
        autoFocus: false,
        disableClose: true,
        data: {
          desc: this.translate.instant('personal_code.exists_code')
        }
      }).afterClosed().subscribe(() => {
        event.input.value = code;
        event.input.focus();
      })
    }
  }

  onAddPersonalCodeByBtn(input: HTMLInputElement): void {
    const code = input.value;
    if (!this.personalCodes.includes(code) && code !== '') {
      this.personalCodes.push(code);
      input.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      input.value = '';
    } else if (this.personalCodes.includes(code)) {
      this.dialog.open(AlertComponent, {
        width: '450px',
        autoFocus: false,
        disableClose: true,
        data: {
          desc: this.translate.instant('personal_code.exists_code')
        }
      }).afterClosed().subscribe(() => {
        input.value = code;
        input.focus();
      })
    }
  }

  onPersonalCodesChange(event, index: number) {
    if (!this.personalCodes.includes(event.value) && event.value !== '') {
      this.personalCodes[index] = event.value;
    } else if (this.personalCodes.includes(event.value) && this.personalCodes[index] !== event.value) {
      this.dialog.open(AlertComponent, {
        width: '450px',
        disableClose: true,
        data: {
          title: event.value + " " + this.translate.instant('personal_code.exists_code')
        }
      }).afterClosed().subscribe(() => {
        event.value = this.personalCodes[index];
        event.focus();
      })
    } else if (event.value === '') {
      this.removePersonalCode(index)
    }
  }

  removePersonalCode(id: number) {
    let subtitle: string = this.translate.instant("new_doc.minister_id.delete.subtitle");
    this.dialog.open(ConfirmDialogComponent, {
      width: '550px',
      disableClose: true,
      data: {
        title: this.translate.instant("new_doc.minister_id.delete.title"),
        subtitle: subtitle.replace('CODE', this.personalCodes[id]),
        positiveText: this.translate.instant("new_doc.minister_id.delete.positive"),
        negativeText: this.translate.instant("new_doc.minister_id.delete.negative")
      }
    }).afterClosed().subscribe(response => {
      if (!response) return;
      this.personalCodes.splice(id, 1);
    })
  }

  /////// Personal Codes End ////////

  clearAllFiles() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this.translate.instant('file_dialog.title'),
        subtitle: this.translate.instant('file_dialog.comfirm_all'),
        positiveText: this.translate.instant('file_dialog.positive'),
        negativeText: this.translate.instant('file_dialog.negative')
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        this.fileEmpty = true;
        this.deletedFiles = this.fileList.map(file => file._id);
        this.deletedOldFiles = this.oldFileList.map(file => file._id);
        this.oldFileList = [];
        this.fileList = [];

        // this.formGroup.get('date').reset();
        // this.formGroup.get('type_id').reset();
        // this.formGroup.get('reference_id').reset();
      }
    });
  }

  onRemoveFile(file: HTMLInputElement | File, i: number) {
    this.fileList.splice(i, 1);
  }

  onRemoveOldFile(docID: string, fileID: string) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: this.translate.instant('file_dialog.title'),
        subtitle: this.translate.instant('file_dialog.comfirm'),
        positiveText: this.translate.instant('file_dialog.positive'),
        negativeText: this.translate.instant('file_dialog.negative')
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        this.newApi.deleteFileById(docID, fileID).subscribe(res => {
          if (res.status) {
            this.newApi.getDocumentById(docID).subscribe(res => {
              this.oldFileList = res.doc.files;
            });
          } else {
            this.showErrorMessage('Error while deleting document type.');
          }
        });
      }
    });
  }

  deleteFileNotInDocument(idFile: string): void {
    this.newApi.deleteFileNotInDocument(idFile).subscribe(response => {
      if (response.status)
        this.fileList.splice(this.fileList.findIndex(list => list._id === idFile), 1);
    })
  }

  updatePublicFileRequired(e): void {
    // const isHaveFile: boolean = this.fileList.length > 0 || this.oldFileList.length > 0;
    // if (this.accessibilities.find(access => access._id == e.value).name != "Public" || !isHaveFile) {
    //   this.isPublicFileRequired = false;
    //   return;
    // };

    // if (!this.fileList.filter(file => file.is_public).length && !this.oldFileList.filter(file => file.is_public).length) {
    //   this.isPublicFileRequired = true
    // }

    if (this.accessibilities.find(access => access._id == e.value).name != "Public") this.showPublicFileCheckbox = true;
    else this.showPublicFileCheckbox = false;

    // if (this.fileList.length > 0) {
    //   for (let file of this.fileList) {
    //     file.is_public = false;
    //   }
    // }

    // if (this.oldFileList.length > 0) {
    //   for (let file of this.oldFileList) {
    //     file.is_public = false;
    //   }
    // }


    if (this.fileList.length > 0) {
      for (let file of this.fileList) {
        file.is_public = true;
      }
    }

    if (this.oldFileList.length > 0) {
      for (let file of this.oldFileList) {
        file.is_public = true;
      }
    }
  }

  onFilePublicToggle(event, reference) {
    reference.is_public = event.checked;
    let val = this.formGroup.value;
    if (val.accessibilities && this.accessibilities.find(access => access._id == val.accessibilities).name == "Public") {
      if (this.fileList.filter(file => file.is_public).length || this.oldFileList.filter(file => file.is_public).length) {
        this.isPublicFileRequired = false;
      } else {
        this.isPublicFileRequired = true;
      }
    }
    // if (this.fileList.filter(file => file.is_public).length || this.oldFileList.filter(file => file.is_public).length)
  }

  onSetFileAccessibilityPublic() {

  }

  private getUserRole(): void {
    this.newApi.getRoles().subscribe(response => {
      this.userRoles = response.roles;
    })
  }

  getRoleById(id: number) {
    if (id == 1 || id == 2)
      return
    return Array.isArray(this.userRoles) && this.userRoles.find(role => role._id === id)
  }

  private showErrorMessage(message: string) {
    console.error(message);
  }

  // private randomData() {
  //   const chance = Chance();
  //   this.formGroup.patchValue({
  //     reference_id: chance.integer({ min: 0, max: 10000 })
  //   });
  // }

  private navigateToDetail(doc: DocumentModel) {
    this.router.navigate(['/documents/detail', doc._id, false]).catch(error => {
      this.router.navigate(['/documents/all']);
      // TODO: Error navigating
    });
  }

  clickCollapse(office: OfficeModel) {
    if (!office.collapse) {
      this.traverseCollapse(office);
    }
  }

  // private appendChildType() {
  //   this.newApi.getLetterTypes().subscribe(response => {
  //     this.types.map((sets: DocumentTypeModel) => {
  //       sets.childs = response.letter_types.filter((type: LetterType) => type.parent._id === sets._id);
  //       this.types.push(sets);
  //     });
  //   })
  // }

  traverseCollapse(office: OfficeModel) {
    if (!office) return;
    office.collapse = true;
    if (office.childs.length) {
      office.childs.forEach(of => this.traverseCollapse(of));
    }
  }

  datepicker(e, pickerRef): void {
    e.target.blur();
    pickerRef.open();
  }

  changeOldFileUserPermission(i: number): void {
    var role_ids = [];
    if (this.oldFileList[i].role_ids)
      role_ids = this.oldFileList[i].role_ids;
    this.dialog.open(UserRoleCheckboxDialogComponent, {
      width: '500px',
      disableClose: true,
      data: {
        title: this.translate.instant('document.new.who_can_see_this_file'),
        existsRoles: role_ids,
        filename: this.oldFileList[i].name,
      }
    }).afterClosed().subscribe(response => {
      this.oldFileList[i].role_ids = response
    })
  }

  changeFileUserPermission(fileIndex: number): void {
    var role_ids = [];
    if (this.fileList[fileIndex].role_ids) {
      role_ids = this.fileList[fileIndex].role_ids
    }
    this.dialog.open(UserRoleCheckboxDialogComponent, {
      width: '500px',
      disableClose: true,
      data: {
        title: this.translate.instant('document.new.who_can_see_this_file'),
        existsRoles: role_ids,
        filename: this.fileList[fileIndex].file.name
      }
    }).afterClosed().subscribe(response => {
      this.fileList[fileIndex].role_ids = response;
    })
  }

  // openUserRoleForm(): void {
  //   var data = this.formGroup.get('role_ids').value;
  //   this.dialog.open(UserRoleCheckboxDialogComponent, {
  //     width: '500px',
  //     disableClose: true,
  //     data: {
  //       title: this.translate.instant('document.set.who_can_see'),
  //       existsRoles: data
  //     }
  //   }).afterClosed().subscribe(response => {
  //     this.formGroup.patchValue({
  //       role_ids: response
  //     });

  //     if (response.length) {
  //       this.isNotUserRoles = false;
  //     } else {
  //       this.isNotUserRoles = true;
  //     }
  //   })
  // }

  onSelectUser(): void {
    this.dialog.open<UserListSelectComponent>(UserListSelectComponent, {
      width: "900px",
      disableClose: true,
      data: {
        users: this.selectedUsers
      }
    }).afterClosed().subscribe((response: { status: boolean, users: UserModel[] }) => {
      if (!response.status) return;
      this.selectedUsers = response.users.slice();
      this.selectedUsersTable?.renderRows();
    })
  }

  removeSelectedUser(index: number): void {
    let subtitle: string = this.translate.instant("new_doc.minister_id.delete.subtitle");
    this.dialog.open(ConfirmDialogComponent, {
      width: '550px',
      disableClose: true,
      data: {
        title: this.translate.instant("new_doc.minister_id.delete.title"),
        subtitle: subtitle.replace('CODE', this.selectedUsers[index].first_name + " " + this.selectedUsers[index].last_name),
        positiveText: this.translate.instant("new_doc.minister_id.delete.positive"),
        negativeText: this.translate.instant("new_doc.minister_id.delete.negative")
      }
    }).afterClosed().subscribe(response => {
      if (!response) return;
      this.selectedUsers.splice(index, 1);
      this.selectedUsersTable?.renderRows();
    });
  }

  calculateExpireDate(e, inpType) {
    var types = [];
    this.types.forEach(type => {
      types.push(...type.childs)
    })
    if (inpType == 'select') {
      this.selectedLetterType = types.find(type => type._id == e.value);
    }

    var date = new Date();
    if (this.selectedLetterType && this.selectedLetterType.archive_saving_duration) {
      this.formGroup.get('expired_date').patchValue(new Date(date.getFullYear() + this.selectedLetterType.archive_saving_duration, date.getMonth(), date.getDate()))
    }
  }

  ngOnDestroy(): void {
    this.submitSubscription.unsubscribe();
  }

  updateAddButton(e): void {
    this.isPersonalCodeInp = e.target.value != '';
  }

  changeExpireValidate(change: MatCheckboxChange): void {
    //const expiredDate = this.formGroup.get('expired_date');
    // const duration = this.formGroup.get('duration');
    this.is_expired = change.checked;
    /*
    if (change.checked) {
      // expiredDate.enable();
      expiredDate.setValidators(Validators.required);
      expiredDate.updateValueAndValidity();
      // duration.disable();
      // duration.clearValidators();
      // duration.updateValueAndValidity();
    } else {
      // expiredDate.disable();
      expiredDate.clearValidators();
      expiredDate.updateValueAndValidity();
      // duration.enable();
      // duration.setValidators(Validators.required);
      // duration.updateValueAndValidity();  
    }
    */
  }

  private transformDateApiFormat(date: string | Date): string {
    let value = moment(date);
    if (value.isValid()) {
      return value.format('yyyy-MM-DD');
    }
    return null;
  }

  keypressIDCard(event: any) {
    var ew = event.which;
    var valid: boolean = false;

    if (ew == 32) valid = true;
    else if (48 <= ew && ew <= 57) valid = true; // number in english (human name)
    else if (65 <= ew && ew <= 90) valid = true;
    else if (97 <= ew && ew <= 122) valid = true;
    else valid = false;
    return valid;
  }

  getFormControl(name: string): AbstractControl {
    return this.formGroup.get(name);
  }

  findItemInTree(arr: Array<any>, code: string) {
    arr.forEach((item: any) => {
      if (item.code == code) this.selectedItem = item;
      this.findItemInTree(item.childs, code);
    });
  }

  onTitleChange() {
    let title: string = this.formGroup.get('title').value;
    title = title.trim();
    this.formGroup.get('title').setValue(title);
  }

  onTypeSearchChange(e: any) {
    this.typeOptions = this._filterType(e.target.value);
  }

  private _filterType(value: string): DocumentTypeModel[] {
    if (value) {
      return this.types
        .map(group => ({ type: group.type, childs: this._filterChildType(group.childs, value) }))
        .filter(group => group.childs.length > 0);
    }

    return this.types;
  }

  private _filterChildType(opt: DocumentTypeModel[], value: string) {
    const filterValue = value.toLowerCase();
    return opt.filter(item => item.type.toLowerCase().includes(filterValue));
  }


  onOfficeSearchChange(e: any) {
    this.offceOptions = this._filterOffice(e.target.value);
  }

  private _filterOffice(value: string): OfficeModel[] {
    if (value) {
      return this.offices
        .map(group => ({ name: group.name, childs: this._filterChildOffice(group.childs, value), code: group.code, _id: group._id }))
        .filter(group => group.childs.length > 0 || group.name.includes(value));
    }

    return this.offices;
  }

  private _filterChildOffice(opt: OfficeModel[], value: string) {
    const filterValue = value.toLowerCase();
    return opt.filter(item => item.name.toLowerCase().includes(filterValue));
  }

  onReferenceChange(e: any) {
    let value = e.target.value;
    let updated_value = value.split('-').join('/');

    this.formGroup.get('reference_id').patchValue(updated_value);
  }

  onChangeIsBook(e: MatCheckboxChange, file: any) {
    console.log(e, file);
    if (e.checked) file.is_book = true;
    else delete file.is_book;
  }

  calculateSheet(page: number) {
    return Math.ceil(page / 2);
  }
}

export enum ACCESSIBLITY_CODE {
  PUBLIC = "Public",
  OFFICER = "Officer",
  HIGHLY_SENSITIVE = "Highly Sensitive"
}