import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { NewRequestService } from './new-request.service';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class NewExcelService {
  constructor(
    private request: NewRequestService
  ) {}

  // exportExcel(column: string[], data: any[]): void {
  //   this.request.get(this.logo)
  //   .subscribe((response: any) => {
      
  //     if (!response.body) return;
  //     response.body.arrayBuffer().then(buffer => {
  //       const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
  //       const logo = workbook.addImage({
  //         buffer,
  //         extension: "jpeg"
  //       });
  //       workbook.xlsx.readFile("/assets/excel-template.xlsx").then(console.log);
  //       const ws = workbook.addWorksheet();
  //       ws.columns = column.map(col => ({ key: col }));
  //       ws.columns[0].width = 8;
  //       this.headers.forEach((_, index) => {
  //         let row = ws.getRow(index+1);
  //         row.values = [this.headers[index]];
  //         row.alignment = { vertical: "middle", horizontal: "center" };
  //         row.font = { size: 14, name: "Khmer OS Muol Light" }
  //       });
  //       let row3 = ws.getRow(3);
  //       row3.values = [this.headerSpace];
  //       row3.alignment = { vertical: "middle", horizontal: "center" };
  //       row3.font = { size: 38, name: "Tacteing" };
  //       ws.addRow(["sdkasdk", "adsfsdf", "dsfgsa"]);
  //       ws.addImage(logo, {
  //         tl: { col: 1.8, row: 4 },
  //         ext: { width: 120, height: 120 },
  //       });
  //       const afterLogoCell = ws.getCell("B11");
  //       afterLogoCell.value = this.mef;
  //       afterLogoCell.alignment = { horizontal: "center", vertical: "middle" };
  //       afterLogoCell.font = { name: "Khmer OS Muol Light", size: 12 };
  //       ws.columns[1].width = this.mef.length * 1.2;
  //       ws.mergeCells("B5:B10");
  //       ws.mergeCells(1, 1, 1, column.length);
  //       ws.mergeCells(2, 1, 2, column.length);
  //       ws.mergeCells(3, 1, 3, column.length);
  //       workbook.xlsx.writeBuffer()
  //       .then(this.saveFile)
  //     })
  //   })
  // }
  
  getTemplate(title: string, office?: string, stt?: string): Promise<ExcelJS.Workbook> {
    return new Promise((resovle, reject) => {
      let templateUrl = "/assets/templates/excel-template.xlsx";
      this.request.get(templateUrl, {}, false, false, "arrayBuffer").subscribe((response) => {
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(response as any)
        .then((wb) => {
          const worksheet = wb.getWorksheet(1);
          const officeCell = worksheet.getCell("A5");
          const titleCell = worksheet.getCell("A6");
          const sTitle = worksheet.getCell("A7");
          titleCell.value = title;
          sTitle.value = stt;
          officeCell.value = office;
          resovle(wb);
        })
        .catch(reject);
      });
    })
  }

  /**
   * Get Undetermined Request Report Template
   * @param title 
   * @param office 
   * @param stt 
   * @returns 
   */
  getUndeterminedRequestTemplate(title: string, office?: string, stt?: string): Promise<ExcelJS.Workbook> {
    return new Promise((resovle, reject) => {
      let templateUrl = "/assets/templates/excel-template.xlsx";
      this.request.get(templateUrl, {}, false, false, "arrayBuffer").subscribe((response) => {
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(response as any)
        .then((wb) => {
          const worksheet = wb.getWorksheet(1);
          const officeCell = worksheet.getCell("A5");
          const titleCell = worksheet.getCell("A6");
          const sTitle = worksheet.getCell("A7");
          officeCell.font = { name: "Khmer MEF2", size: 9 };
          titleCell.font = { name: "Khmer MEF1", size: 8, bold: true };
          titleCell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
          sTitle.alignment = titleCell.alignment;
          titleCell.value = title;
          sTitle.value = stt;
          officeCell.value = office;
          resovle(wb);
        })
        .catch(reject);
      });
    })
  }

  // exportExcel(data: any[], title: string, office?: string, stt?: string): void {
  //   let templateUrl = "/assets/templates/excel-template.xlsx";
  //   this.request.get(templateUrl).subscribe((response: any) => {
  //     if (!response.body) return;
  //     const worksheet: ExcelJS.Workbook = new ExcelJS.Workbook();
  //     response.body.arrayBuffer().then(buffer => {
  //       worksheet.xlsx.load(buffer).then(wb => {
  //         const worksheet = wb.getWorksheet(1);
  //         const officeCell = worksheet.getCell("A9");
  //         const titleCell = worksheet.getCell("A10");
  //         const sTitle = worksheet.getCell("A11");
  //         officeCell.font = { name: "Khmer OS Battambang", size: 8 };
  //         titleCell.font = { name: "Khmer OS Muol Light", size: 8 };
  //         titleCell.alignment = { vertical: "middle", horizontal: "center" };
  //         sTitle.alignment = titleCell.alignment;
  //         titleCell.value = title;
  //         if (sTitle) sTitle.value = stt;
  //         if (office) officeCell.value = office;
  //         data.forEach((val, i) => {
  //           worksheet.addRow([i, val.document.title])
  //         })
  //         wb.xlsx.writeBuffer().then(this.saveFile)
  //       })
  //     });
  //   });
  // }
  // private saveFile(blobPath: BlobPart): void {
  //   const file = new Blob([blobPath], {
  //     type: EXCEL_TYPE as string
  //   });
  //   FileSaver.saveAs(file, new Date().getTime() + EXCEL_EXTENSION);
  // }
}

// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import * as Excel from "exceljs/dist/exceljs.min.js";
// import { DatePipe } from '@angular/common';
// import * as FileSaver from 'file-saver';

// @Injectable({
//   providedIn: 'root'
// })
// export class ExcelService {
//   minis_acc: any = {
//     portal: {
//       aba: "001656306",
//       acleda: "00010386935210"
//     },
//     moc: {
//       aba: "001660245",
//       acleda: "00010394144218"
//     },
//     gdt_service: {
//       aba: "001803581",
//       acleda: "00010192791515"
//     },
//     gdt_patent: {
//       aba: "",
//       acleda: ""
//     },
//     mlvt: {
//       aba: "001803428",
//       acleda: "00010394167417"
//     }
//   };

//   constructor(private http: HttpClient,
//     private datePipe: DatePipe,
//     ) {
//   }

//   /**
//    * For Summary
//    * @param data 
//    * @param bank 
//    */
//   generateExcel(data: any, bank: string, payment_type: string) {
//     const header = this.validateDataByBank([
//       "ល.រ.",
//       "ក្រសួង-ស្ថាប័ន",
//       "សាខាពន្ធដារ",
//       "លេខគណនី",
//       "ចំនួនសាច់​ប្រាក់​​តាមសាខា​​ប្រាក់ សម្រាប់ " + (bank == "ABA" ? "CARD" : (bank == "ACLEDA" ? "MPGS" : "")),
//       "ចំនួនសាច់​ប្រាក់​សរុប​​ត្រូវ​​ផ្ទេរចូល សម្រាប់ " + (bank == "ABA" ? "CARD" : (bank == "ACLEDA" ? "MPGS" : "")),
//       "ចំនួនសាច់ប្រាក់​​តាម​សាខា​ប្រាក់​​សម្រាប់ " + (bank == "ABA" ? "ABA PAY" : (bank == "ACLEDA" ? "XPAY" : "")),
//       "ចំនួនសាច់​ប្រាក់​សរុប​​ត្រូវ​​ផ្ទេរចូល សម្រាប់ " + (bank == "ABA" ? "ABA PAY" : (bank == "ACLEDA" ? "XPAY" : "")),
//       "ផ្សេងៗ"
//     ], bank, payment_type);

//     const row_data = [
//       this.validateDataByBank([
//         "1",
//         "ក្រសួងពាណិជ្ជកម្ម",
//         "",
//         this.getDataByBank(this.minis_acc.moc, bank),
//         "",
//         this.formatNumber(data.moc.card) + " រៀល",
//         "",
//         this.formatNumber(data.moc.bank_transfer) + " រៀល",
//         ""
//       ], bank, payment_type),
//       this.validateDataByBank([
//         "2",
//         "អគ្គនាយកដ្ឋានពន្ធដារ នៃក្រសួងសេដ្ឋកិច្ច និងហិរញ្ញវត្ថុ",
//         "",
//         this.getDataByBank(this.minis_acc.gdt_service, bank),
//         "",
//         this.formatNumber(data.gdt.service.card) + " រៀល",
//         "",
//         this.formatNumber(data.gdt.service.bank_transfer) + " រៀល",
//         "សេវា"
//       ], bank, payment_type),
//       this.validateDataByBank([
//         "3",
//         "អគ្គនាយកដ្ឋានពន្ធដារ នៃក្រសួងសេដ្ឋកិច្ច និងហិរញ្ញវត្ថុ",
//         "",
//         this.getDataByBank(this.minis_acc.gdt_patent, bank),
//         "",
//         this.formatNumber(data.gdt.patent.card) + " រៀល",
//         "",
//         this.formatNumber(data.gdt.patent.bank_transfer) + " រៀល",
//         "ពន្ធ"
//       ], bank, payment_type),
//       ...this.generateTaxBranches(data.gdt.tax_branches, bank, payment_type),
//       this.validateDataByBank([
//         "4",
//         "ក្រសួងការងារ និងបណ្តុះបណ្តាលវិជ្ជាជីវៈ",
//         "",
//         this.getDataByBank(this.minis_acc.mlvt, bank),
//         "",
//         this.formatNumber(data.mlvt.card) + " រៀល",
//         "",
//         this.formatNumber(data.mlvt.bank_transfer) + " រៀល",
//         ""
//       ], bank, payment_type)
//     ];

//     let all_cards = [
//       data.moc.card,
//       data.gdt.service.card,
//       data.gdt.patent.card,
//       data.mlvt.card
//     ];

//     let bank_transfers = [
//       data.moc.bank_transfer,
//       data.gdt.service.bank_transfer,
//       data.gdt.patent.bank_transfer,
//       data.mlvt.bank_transfer
//     ];

//     let url = "/assets/templates/excel-template.xlsx";
//     this.http.get(url, { responseType: 'arraybuffer' }).subscribe((file: ArrayBuffer) => {
//       let workbook: Excel.Workbook = new Excel.Workbook();
//       let that = this;

//       workbook.xlsx.load(file).then(function () {
//         let worksheet = workbook.getWorksheet(1);

//         worksheet.getCell('A9').value = `លេខប្រតិបត្តិការ៖ ${data.ref_no}OBR`;
//         worksheet.getCell('A10').value = `${data.date_kh}`;
//         worksheet.getCell('A11').value = `${data.date_en}`;
//         worksheet.getCell('A14').value = `${that.getDesc(bank)}`;

//         worksheet.addRow([]);

//         //Add Header Row
//         let headerRow = worksheet.addRow(header);
//         if (bank != 'ABA') {
//           worksheet.mergeCells(`E16:F16`);
//           worksheet.mergeCells(`G16:H16`);
//         }

//         // Cell Style : Fill and Border
//         headerRow.eachCell((cell) => {
//           cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//           cell.font = {
//             name: 'Khmer OS Muol Light',
//             size: 8
//           };
//           cell.alignment = {
//             wrapText: true,
//             vertical: 'middle',
//             horizontal: 'center'
//           };
//         });

//         // Add Data and Conditional Formatting
//         row_data.forEach((d, index) => {
//           let dataRow = worksheet.addRow(d);
//           if (bank != 'ABA') {
//             worksheet.mergeCells(`E${17 + index}:F${17 + index}`);
//             worksheet.mergeCells(`G${17 + index}:H${17 + index}`);
//           }

//           dataRow.eachCell((cell) => {
//             cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//             cell.font = {
//               name: 'Khmer OS Battambang',
//               size: 8
//             };
//             cell.alignment = {
//               wrapText: true,
//               vertical: 'middle',
//               horizontal: 'center'
//             };
//           });
//         });

//         let data_row_index = 21 + (data.gdt.tax_branches ? data.gdt.tax_branches.length : 0);

//         // សរុប
//         let semiTotalRow = worksheet.addRow(that.validateDataByBank(["សរុប", "", "", "", that.sumNumber(all_cards) + " រៀល", "", that.sumNumber(bank_transfers) + " រៀល", "", ""], bank, payment_type));
//         worksheet.mergeCells(`A${data_row_index}:D${data_row_index}`);

//         if (bank == "ABA") {
//           worksheet.mergeCells(`E${data_row_index}:F${data_row_index}`);
//           worksheet.mergeCells(`G${data_row_index}:H${data_row_index}`);
//         }
//         else if (bank == "ACLEDA") {
//           worksheet.mergeCells(`E${data_row_index}:H${data_row_index}`);
//         }

//         semiTotalRow.eachCell((cell) => that.setCellStyle(cell));

//         // សរុបរួម
//         let totalRow = worksheet.addRow(["សរុបរួម", "", "", "", that.sumNumber([...all_cards, ...bank_transfers]) + " រៀល", "", "", "", ""]);
//         worksheet.mergeCells(`A${data_row_index + 1}:D${data_row_index + 1}`);
//         worksheet.mergeCells(`E${data_row_index + 1}:H${data_row_index + 1}`);
//         totalRow.eachCell((cell) => that.setCellStyle(cell));

//         // (សរុបរួម៖ ... រៀលគត់)
//         let finalTotal = worksheet.addRow([`(សរុបរួម៖ ${data.total_kh_word}រៀលគត់)`, "", "", "", "", "", "", "", ""]);
//         worksheet.mergeCells(`A${data_row_index + 2}:I${data_row_index + 2}`);
//         finalTotal.eachCell((cell) => that.setCellStyle(cell));

//         worksheet.addRow([]);

//         // សម្គាល់៖ របាយការណ៏ទូទាត់ ...
//         if (data.actual_transaction_date) {
//           let note = worksheet.addRow([`សម្គាល់៖ របាយការណ៏ទូទាត់សាច់ប្រាក់នេះ ជាប្រតិបត្តិការចំណូលជាក់ស្តែងសម្រាប់៖`, "", "", "", "", "", "", "", ""]);
//           worksheet.mergeCells(`A${data_row_index + 4}:I${data_row_index + 4}`);
//           note.eachCell((cell) => that.setNormalCellStyle(cell));
//         }

//         // CARD
//         if ((bank == 'ABA' || (bank == 'ACLEDA' && payment_type == 'CARD')) && data.actual_transaction_date) {
//           let card = worksheet.addRow(["", `- ${bank == "ABA" ? "CARD" : (bank == "ACLEDA" ? "MPGS" : "")} : ${that.getNoticedDate(data.actual_transaction_date.card)}`, "", "", "", "", "", "", ""]);
//           worksheet.mergeCells(`B${data_row_index + 5}:I${data_row_index + 5}`);
//           card.eachCell((cell) => that.setNormalCellStyle(cell));
//         }

//         // BANK TRANSFER
//         if ((bank == 'ABA' || (bank == 'ACLEDA' && payment_type == 'BANK_TRANSFER')) && data.actual_transaction_date) {
//           let bank_transfer = worksheet.addRow(["", `- ${bank == "ABA" ? "ABA PAY" : (bank == "ACLEDA" ? "XPAY" : "")} : ${that.getNoticedDate(data.actual_transaction_date.bank)}`, "", "", "", "", "", "", ""]);
//           worksheet.mergeCells(`B${data_row_index + (bank == 'ABA' ? 6 : 5)}:I${data_row_index + (bank == 'ABA' ? 6 : 5)}`);
//           bank_transfer.eachCell((cell) => that.setNormalCellStyle(cell));
//         }

//         //Generate Excel File with given name
//         workbook.xlsx.writeBuffer().then((row_data) => {
//           let blob = new Blob([row_data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//           that.fileSaver.saveBlob(blob, 'របាយការណ៏ទូទាត់សាច់ប្រាក់.xlsx');
//         });
//       });
//     });
//   }


//   /**
//    * For Detail Page
//    * @param data 
//    * @param bank 
//    */
//   generateDetailExcel(data: any, bank: string) {
//     const header = [
//       "លេខប្រតិបត្តិ",
//       "",
//       "ថ្ងៃទី",
//       "ប្រភេទ",
//       "ចំនួនសាច់ប្រាក់",
//       "ផ្សេងៗ"
//     ];

//     let row_data = [];
//     let total_amount: number = 0;

//     if (data.payments) {
//       data.payments.forEach(item => {
//         total_amount += item.total_amount;
//         row_data.push([
//           item.tran_id,
//           "",
//           this.datePipe.transform(item.payment_date, 'dd-MMM-yyyy, h:mm:ss a'),
//           item.payment_type,
//           this.formatNumber(item.total_amount) + " រៀល",
//           ""
//         ]);
//       });
//     }

//     let url = "/assets/templates/excel-template-detail.xlsx";
//     this.http.get(url, { responseType: 'arraybuffer' }).subscribe((file: ArrayBuffer) => {
//       let workbook: Excel.Workbook = new Excel.Workbook();
//       let that = this;

//       workbook.xlsx.load(file).then(function () {
//         let worksheet = workbook.getWorksheet(1);

//         worksheet.getCell('A9').value = `លេខប្រតិបត្តិការ៖ ${data.ref_no}OBR`;
//         worksheet.getCell('A10').value = `${data.date_kh}`;
//         worksheet.getCell('A11').value = `${data.date_en}`;
//         worksheet.getCell('A12').value = "របាយការណ៏ចំណូល";
//         worksheet.getCell('A14').value = `${that.getDesc(bank)}`;

//         worksheet.addRow([]);

//         //Add Header Row
//         let headerRow = worksheet.addRow(header);
//         worksheet.mergeCells('A16:B16');

//         // Cell Style : Fill and Border
//         headerRow.eachCell((cell) => {
//           cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//           cell.font = {
//             name: 'Khmer OS Muol Light',
//             size: 8
//           };
//           cell.alignment = {
//             wrapText: true,
//             vertical: 'top',
//             horizontal: 'center'
//           };
//         });

//         // Add Data and Conditional Formatting
//         let data_row_index: number = 17;
//         row_data.forEach((d, index) => {
//           let dataRow = worksheet.addRow(d);
//           worksheet.mergeCells(`A${data_row_index + index}:B${data_row_index + index}`);

//           dataRow.eachCell((cell) => {
//             cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//             cell.font = {
//               name: 'Khmer OS Battambang',
//               size: 8
//             };
//             cell.alignment = {
//               wrapText: true,
//               vertical: 'middle',
//               horizontal: 'center'
//             };
//           });
//         });

//         data_row_index += row_data.length;

//         let finalTotal = worksheet.addRow(["សរុប", "", "", "", `${that.formatNumber(total_amount)} រៀល`, ""]);
//         worksheet.mergeCells(`A${data_row_index}:D${data_row_index}`);
//         finalTotal.eachCell((cell) => {
//           cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//           cell.font = {
//             name: 'Khmer OS Battambang',
//             size: 8
//           };
//           cell.alignment = {
//             wrapText: true,
//             vertical: 'middle',
//             horizontal: 'center'
//           };
//         });

//         worksheet.addRow([]);

//         // សម្គាល់៖ របាយការណ៏ទូទាត់ ...
//         if (data.actual_transaction_date) {
//           let note = worksheet.addRow([`សម្គាល់៖ របាយការណ៏ចំណូលនេះ ជាប្រតិបត្តិការចំណូលជាក់ស្តែង${that.getNoticedDate(data.actual_transaction_date)}`, "", "", "", "", ""]);
//           worksheet.mergeCells(`A${data_row_index + 2}:F${data_row_index + 2}`);
//           note.eachCell((cell) => that.setNormalCellStyle(cell));
//         }

//         //Generate Excel File with given name
//         workbook.xlsx.writeBuffer().then((data) => {
//           let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//           that.fileSaver.saveBlob(blob, 'របាយការណ៏ចំណូល.xlsx');
//         });
//       });
//     });
//   }

//   /**
//    * For Detail Page
//    * @param data 
//    * @param bank 
//    */
//    generateTransactionExcel(data: any, bank: string) {
//     const first_header = [
//       "ល.រ",
//       "លេខប្រតិបត្តិ",
//       "ថ្ងៃទី",
//       "ប្រភេទ",
//       "ចំនួនសាច់ប្រាក់សរុប",
//       "ចំនួនសាច់ប្រាក់",
//       "",
//       "",
//       "",
//       "GDT Branches"
//     ];
    
//     const second_header = [
//       "",
//       "",
//       "",
//       "",
//       "",
//       "MOC",
//       "GDT Service",
//       "GDT Tax",
//       "MVLT",
//       ""
//     ];

//     let row_data = [];
//     let total_amount: number = 0;

//     if (data.payments) {
//       data.payments.forEach((item: any, index: number) => {
//         total_amount += item.total_amount;
//         row_data.push([
//           index + 1,
//           item.tran_id,
//           this.datePipe.transform(item.payment_date, 'dd-MMM-yyyy, h:mm:ss a'),
//           item.payment_type,
//           this.formatNumber(item.total_amount) + " រៀល",
//           this.formatNumber(item.moc_amount) + " រៀល",
//           this.formatNumber(item.gdt_amount) + " រៀល",
//           this.formatNumber(item.gdt_tax_amount) + " រៀល",
//           this.formatNumber(item.mlvt_amount) + " រៀល",
//           item.gdt_branch
//         ]);
//       });
//     }

//     let url = "/assets/templates/excel-template-transaction.xlsx";
//     this.http.get(url, { responseType: 'arraybuffer' }).subscribe((file: ArrayBuffer) => {
//       let workbook: Excel.Workbook = new Excel.Workbook();
//       let that = this;

//       workbook.xlsx.load(file).then(function () {
//         let worksheet = workbook.getWorksheet(1);

//         worksheet.getCell('A9').value = `លេខប្រតិបត្តិការ៖ ${data.ref_no}OBR`;
//         worksheet.getCell('A10').value = `${data.date_kh}`;
//         worksheet.getCell('A11').value = `${data.date_en}`;
//         worksheet.getCell('A12').value = "របាយការណ៏បង់ប្រាក់លម្អិត";
//         worksheet.getCell('A14').value = `${that.getDesc(bank)}`;

//         worksheet.addRow([]);

//         //Add First Header Row
//         let firstHeaderRow = worksheet.addRow(first_header);
//         that.setHeaderStyle(firstHeaderRow);

//         //Add Second Header Row
//         let secondHeaderRow = worksheet.addRow(second_header);
//         that.setHeaderStyle(secondHeaderRow);

//         worksheet.mergeCells('A16:A17');
//         worksheet.mergeCells('B16:B17');
//         worksheet.mergeCells('C16:C17');
//         worksheet.mergeCells('D16:D17');
//         worksheet.mergeCells('E16:E17');
//         worksheet.mergeCells('F16:I16');
//         worksheet.mergeCells('J16:J17');

//         // Add Data and Conditional Formatting
//         let data_row_index: number = 18;
//         row_data.forEach((d: any) => {
//           let dataRow = worksheet.addRow(d);

//           dataRow.eachCell((cell) => {
//             cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//             cell.font = {
//               name: 'Khmer OS Battambang',
//               size: 8
//             };
//             cell.alignment = {
//               wrapText: true,
//               vertical: 'middle',
//               horizontal: 'center'
//             };
//           });
//         });

//         data_row_index += row_data.length;

//         let finalTotal = worksheet.addRow(["សរុប", "", "", "", `${that.formatNumber(total_amount)} រៀល`, "", "", "", "", ""]);
//         worksheet.mergeCells(`A${data_row_index}:D${data_row_index}`);
//         worksheet.mergeCells(`E${data_row_index}:J${data_row_index}`);
//         finalTotal.eachCell((cell) => {
//           cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//           cell.font = {
//             name: 'Khmer OS Battambang',
//             size: 8
//           };
//           cell.alignment = {
//             wrapText: true,
//             vertical: 'middle',
//             horizontal: 'center'
//           };
//         });

//         //Generate Excel File with given name
//         workbook.xlsx.writeBuffer().then((data) => {
//           let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//           that.fileSaver.saveBlob(blob, 'របាយការណ៏បង់ប្រាក់លម្អិត.xlsx');
//         });
//       });
//     });
//   }

//   formatNumber(value: number | string) {
//     return parseInt(value.toString()).toLocaleString();
//   }

//   sumNumber(arrNum: Array<number>) {
//     let result: number = 0;
//     arrNum.forEach(item => {
//       result += item;
//     });
//     return this.formatNumber(result);
//   }

//   getDesc(bank: string) {
//     let main_acc_number = this.getDataByBank(this.minis_acc.portal, bank);
//     let bank_kh: string = '';

//     if (bank == 'ABA') bank_kh = "ធនាគារវឌ្ឍន:អាស៊ីចំកាត់ (ABA)";
//     else if (bank == 'ACLEDA') bank_kh = "ធនាគារអេស៊ីលីដា ភីអិលស៊ី (ACLEDA Bank Plc.)";

//     return `       យោងតាមអនុស្សរណ:នៃការយោគយល់គ្នា ចុះថ្ងៃទី ១៣ ខែ មីនា ឆ្នាំ ២០២០ ស្តីពីការប្រើប្រាស់សេវាកម្មរបស់${bank_kh} សម្រាប់ប្រតិបត្តិការចំណូលពីសេវាចុះបញ្ជីអាជីវកម្មតាមប្រព័ន្ធ​បច្ចេកវិទ្យាព័ត៌មាន ដែលប្រមូលដោយក្រសួងស្ថាប័នអនុវត្តនៅទូទាំងប្រទេស រវាង ក្រសួងសេដ្ធកិច្ច និងហិរញ្ញវត្ថុ និង ${bank_kh}, ការទូទាត់នេះ ត្រូវធ្វើឡើងជារៀងរាល់ថ្ងៃ នៅវេលាម៉ោង ២:៣០រសៀល តាមរយៈ ការផ្ទេរសាច់ប្រាក់ចេញពីគណនីចំណូលពីសេវាចុះបញ្ជីអាជីវកម្មតាមប្រព័ន្ធបច្ចេកវិទ្យាព័ត៌មានរបស់ក្រសួងសេដ្ឋកិច្ច និងហិរញ្ញវត្ថុ តាមគណនីលេខ ${main_acc_number} ទៅកាន់ក្រសួង-ស្ថាប័ន ដូចខាងក្រោម៖`;
//   }

//   validateDataByBank(data: Array<any>, bank: string, payment_type: string) {
//     if (bank == 'ACLEDA' && payment_type == 'BANK_TRANSFER') {
//       data.splice(4, 2);
//       data.splice(5, 0, ''); // add space between 'ចំនួនសាច់​ប្រាក់​​តាមសាខា​​ប្រាក់' & 'ចំនួនសាច់ប្រាក់​ត្រូវ​ផ្ទេរចូល'
//       data.splice(7, 0, ''); // add space between 'ចំនួនសាច់ប្រាក់​ត្រូវ​ផ្ទេរចូល' & 'ផ្សេងៗ'
//     }
//     else if (bank == 'ACLEDA' && payment_type == 'CARD') {
//       data.splice(6, 2); // remove CARD
//       data.splice(5, 0, ''); // add space between 'ចំនួនសាច់​ប្រាក់​​តាមសាខា​​ប្រាក់' & 'ចំនួនសាច់ប្រាក់​ត្រូវ​ផ្ទេរចូល'
//       data.splice(7, 0, ''); // add space between 'ចំនួនសាច់ប្រាក់​ត្រូវ​ផ្ទេរចូល' & 'ផ្សេងៗ'
//     }

//     return data;
//   }

//   generateTaxBranches(tax_branches: Array<any>, bank: string, payment_type: string) {
//     let data: Array<any> = [];
//     if (tax_branches) {
//       tax_branches.forEach((item: any) => {
//         data.push(this.validateDataByBank([
//           '',
//           '',
//           item.name,
//           this.getDataByBank(item, bank),
//           this.formatNumber(item.card) + " រៀល",
//           '',
//           this.formatNumber(item.bank_transfer) + " រៀល",
//           '',
//           ''
//         ], bank, payment_type));
//       });

//       return data;
//     } else {
//       return [];
//     }
//   }

//   setCellStyle(cell: any) {
//     cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//     cell.font = {
//       name: 'Khmer OS Battambang',
//       size: 8
//     };
//     cell.alignment = {
//       wrapText: true,
//       vertical: 'middle',
//       horizontal: 'center'
//     };
//   }

//   setNormalCellStyle(cell: any) {
//     cell.font = {
//       name: 'Khmer OS Battambang',
//       size: 8
//     };
//     cell.alignment = {
//       wrapText: true,
//       vertical: 'middle'
//     };
//   }

//   getNoticedDate(data: any) {
//     return `ចាប់ពីថ្ងៃទី ${this.datePipe.transform(data.start_date, 'dd-MMM-yyyy hh:mm a')} រហូតដល់ថ្ងៃទី ${this.datePipe.transform(data.end_date, 'dd-MMM-yyyy hh:mm a')} ។`;
//   }

//   getDataByBank(data: any, bank: string) {
//     let value: string = '';
//     switch (bank) {
//       case 'ABA': value = data.aba; break;
//       case 'ACLEDA': value = data.acleda; break;
//       default: value = data.aba; break;
//     }
//     return value;
//   }

//   setHeaderStyle(header: any) {
//     header.eachCell((cell) => {
//       cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
//       cell.font = {
//         name: 'Khmer OS Muol Light',
//         size: 8
//       };
//       cell.alignment = {
//         wrapText: false,
//         vertical: 'middle',
//         horizontal: 'center'
//       };
//     });
//   }
// }

// const header = this.validateDataByBank([
//   "ល.រ.",
//   "ក្រសួង-ស្ថាប័ន",
//   // "សាខាពន្ធដារ",
//   "លេខគណនី",
//   // "ចំនួនសាច់​ប្រាក់​​តាមសាខា​​ប្រាក់ សម្រាប់ Card",
//   "ចំនួនសាច់ប្រាក់​ត្រូវ​ផ្ទេរចូល សម្រាប់ Card",
//   // "ចំនួនសាច់ប្រាក់​​តាម​សាខា​ប្រាក់​​សម្រាប់ " + (bank == "ABA" ? "ABA PAY" : (bank == "ACLEDA" && payment_type == "BANK_TRANSFER" ? "ACLEDA PAY" : "")),
//   "ចំនួនសាច់​ប្រាក់​ត្រូវ​ផ្ទេរចូល សម្រាប់ " + (bank == "ABA" ? "ABA PAY" : (bank == "ACLEDA" && payment_type == "BANK_TRANSFER" ? "ACLEDA PAY" : "")),
//   "ផ្សេងៗ"
// ], bank, payment_type);