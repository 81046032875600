<h3 mat-dialog-title [ngClass]="{error: data.warn, success: data.success}" *ngIf="data.mainTitle" mat-dialog-title> {{ data.mainTitle }}</h3>
<mat-dialog-content>
    <div class="container">
        <p class="reset" *ngFor="let desc of data.descs">{{ desc }}</p>
    	<p class="reset" *ngIf="data.desc">{{ data.desc }}</p>
        <p *ngIf="data.code"><small>{{ data.code }}</small></p>
        <h3>{{ data.title }}</h3>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-dialog-close mat-raised-button color="accent">{{'alert_confirm' | translate}}</button>
</mat-dialog-actions>
