<div class="container fixed" id="infinite-scroller">
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
    <div class="sub-container doc-detail">
      <header class="card" *ngIf="isEditing">
        <button mat-icon-button color="warn" class="document-back" (click)="goBack()">
          <mat-icon aria-label="back">keyboard_arrow_left</mat-icon>
        </button>
        <div class="group">
          <h3 class="title">{{ editingDocument?.title || editExternalDoc?.title }}</h3>
          <h4 class="subtitle" *ngIf="editingDocument?.created_by?.name">{{ editingDocument?.created_by?.name }}</h4>
        </div>
        <div class="spacer"></div>

        <div class="end-button">
          <mat-icon *ngIf="reason" class="info" matTooltip="{{ reason }}" matTooltipClass="info-tooltip">info</mat-icon>
        </div>
      </header>

      <main>
        <input #file multiple accept="image/*, application/pdf" (change)="onFileUploaded($event)" type="file" hidden />
        <div class="entry-card">
          <form [formGroup]="formGroup">
            <div class="upload-area">
              <div class="flex-row">
                <div class="flex-col">
                  <button (click)="file.value = ''; file.click()" mat-flat-button [color]="fileEmpty ? 'warn' : 'primary'">
                    {{ "new_doc.browse_file" | translate }}
                  </button>
                  <mat-error *ngIf="fileEmpty" class="error-require m-t-10">
                    {{"global.form.can_not_save"|translate}}{{"global.form.file"|translate}}{{"global.form.be_empty"|translate}}
                  </mat-error>
                </div>
                <div class="flex-col">
                  <button [disabled]="!fileList.length && !oldFileList.length" mat-stroked-button class="m-l-5"
                    (click)="clearAllFiles()" color="warn">
                    {{ "new_doc.clear_all_files" | translate }}
                  </button>
                </div>
              </div>

              <mat-list *ngIf=" oldFileList.length != 0 || fileList.length != 0 || unUploadedFiles.length != 0" role="list">

                <!-------------------------------- OLD FILE LIST -------------------------------->
                <!------------------------------------------------------------------------------->
                <mat-list-item *ngFor="let progress of oldFileList; let i = index" role="listitem"
                  class="file-list m-t-20">
                  <div mat-line class="file-container">
                    <div class="right-side">
                      <mat-checkbox *ngIf="showPublicFileCheckbox" class="center-flex" [checked]="progress.is_public" (change)="onFilePublicToggle($event, progress)">{{ 'global.public' | translate }}</mat-checkbox>  
                      
                      <div class="file">
                        <mat-icon>attach_file</mat-icon> 
                        <p class="rest text" [title]="progress.name">{{ progress.name }}</p>
                        <span class="m-l-10 red" *ngIf="progress.pages">
                          ({{progress.pages}} {{progress.pages > 1 ? ('global.pages' | translate) : ('global.page' | translate)}})
                        </span>
                      </div>
                    </div>
  
                    <div class="left-side">
                      <button class="view-file" mat-icon-button (click)="onViewFile(progress);">
                        <mat-icon>visibility</mat-icon>
                      </button>

                      <button class="remove-file" *ngIf="!(progress.bucketName && progress.bucketName == 'external_document')" mat-icon-button (click)="addOldFileToDelete(i);">
                        <mat-icon class="red-text">cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                  <mat-progress-bar [value]="progress.progress" color="accent"></mat-progress-bar>
                </mat-list-item>
  

                <!-------------------------------- NEW FILE LIST -------------------------------->
                <!------------------------------------------------------------------------------->
                <mat-list-item *ngFor="let progress of fileList; let i = index" role="listitem" class="file-list new-file m-t-20">
                  <div mat-line class="file-container">
                    <div class="right-side">
                      <mat-checkbox *ngIf="showPublicFileCheckbox" class="center-flex" [checked]="progress.is_public" (change)="onFilePublicToggle($event, progress)">{{'global.public' | translate}}</mat-checkbox>
                      
                      <div class="file">
                        <mat-icon>attach_file</mat-icon> 
                        <p class="rest text" [title]="progress.name">{{ progress.name }}</p><span class="status-new">new</span>
                        <span class="m-l-10 red" *ngIf="progress.pages">
                          ({{ progress.pages }} {{progress.pages > 1 ? ('global.pages' | translate) : ('global.page' | translate)}})
                        </span>
                      </div>
                    </div>
                    <div class="left-side">
                      <button class="view-file" mat-icon-button (click)="onViewFile(progress);">
                        <mat-icon>visibility</mat-icon>
                      </button>
                      
                      <button class="remove-file" mat-icon-button (click)="addNewFileToDelete(i)">
                        <mat-icon class="red-text">cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="is-book" [ngClass]="{'with-detail': progress.is_book}">
                    <mat-checkbox (change)="onChangeIsBook($event, progress)">
                      {{ "global.is_book" | translate }}
                    </mat-checkbox>
                    <p *ngIf="progress.is_book">{{'global.book_info' | translate: {page: progress.pages, sheet: calculateSheet(progress.pages)} }}</p>
                  </div>
                  <mat-progress-bar [value]="progress.progress" color="accent"></mat-progress-bar>
                </mat-list-item>
  
                <ng-template ngFor let-unuploadedFile [ngForOf]="unUploadedFiles">
                  <mat-list-item *ngIf="!unuploadedFile.completed" role="listitem" class="file-list m-t-20">
                    <div mat-line class="file-container">
                      <div class="right-side">
                        <div class="file">
                          <mat-icon>attach_file</mat-icon> <p class="rest text" [title]="unuploadedFile.filename">{{ unuploadedFile.filename }}</p><span class="status-new">new</span>
                        </div>
                      </div>
                    </div>
                    <mat-progress-bar [value]="unuploadedFile.progress" color="accent"></mat-progress-bar>
                  </mat-list-item>
                </ng-template>
              </mat-list>
  
              <div class="between-x">
                <p>
                  <mat-error *ngIf="isPublicFileRequired" class="error-require public">
                    {{'new_doc.public_file_required' | translate}}
                  </mat-error>
                </p>
                <p class="red total-page" *ngIf="totalPdfPage">{{'global.total' | translate}}: {{ totalPdfPage }} {{totalPdfPage > 1 ? ('global.pages' | translate) : ('global.page' | translate)}}</p>
              </div>
            </div>
  
            <div fxLayout="row" class="flex-row header-field">
              <div class="title-control">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>{{ "new_doc.title" | translate }}</mat-label>
                  <input formControlName="title" matInput [placeholder]="'new_doc.title' | translate" type="text" (change)="onTitleChange()" required/>
                  <mat-error *ngIf="getFormControl('title').hasError('required')">
                    {{'global.form.can_not_save'|translate}}{{'new_doc.title' | translate}}{{'global.form.be_empty'|translate}}
                  </mat-error>
                </mat-form-field>
              </div>
  
              <mat-form-field color="accent" class="date-field" appearance="outline">
                <mat-label>{{ "new_doc.date" | translate }}</mat-label>
                <input matInput [max]="today" (dateChange)="calculateExpireDate($event, 'datePicker')" [matDatepicker]="date" formControlName="date" [placeholder]="'new_doc.date' | translate" (focus)="datepicker($event, date)" required>
                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
                <mat-error *ngIf="getFormControl('date').hasError('required')">
                  {{'global.form.can_not_save'|translate}}{{'new_doc.date' | translate}}{{'global.form.be_empty'|translate}}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field class="ref-id" color="accent" appearance="outline">
                <mat-label>{{ "new_doc.reference_id" | translate }}</mat-label>
                <input formControlName="reference_id" matInput [placeholder]="'new_doc.reference_id' | translate" (change)="onReferenceChange($event)" type="text" />
              </mat-form-field>
            </div>
  
            <div class="field-group">
              <mat-form-field color="accent" appearance="outline">
                <mat-label>{{ "new_doc.type" | translate }}</mat-label>
                <mat-select (selectionChange)="calculateExpireDate($event, 'select')" formControlName="type_id" [placeholder]="'new_doc.doc_type.placeholder' | translate" [required]="true">
                  <mat-form-field color="accent" appearance="fill" style="width: 100%;" class="decrease-form-field-infix">
                    <input matInput type="text"
                          (keyup)="onTypeSearchChange($event)" 
                          placeholder="{{'global.form.type_to_choose' | translate: {param: ('new_doc.type' | translate)} }}" />
                  </mat-form-field>

                  <div class="list-option">
                    <ng-template #typeList let-list>
                      <ng-container *ngFor="let type of list.data; let i = index">
                        <div [style.padding-left]="18 * list.index + 12 + 'px'"
                          class="tfield color-primary">
                          <button style="float: left; height: 45px; width: 45px;" (click)="
                              type.collapse = !!!type.collapse; clickCollapse(type)
                            " [style.visibility]="
                              type.childs.length ? 'show' : 'hidden'
                            " mat-icon-button>
                            <mat-icon *ngIf="!type.collapse">arrow_right</mat-icon>
                            <mat-icon *ngIf="type.collapse">arrow_drop_down</mat-icon>
                          </button>
                          <mat-option [disabled]="type.parent == null" style="width: calc(100% - 45px); float: right" [value]="type._id">
                            {{ type.type }}
                          </mat-option>
                        </div>
    
                        <ng-container *ngIf="type.childs">
                          <ng-container *ngTemplateOutlet="
                              typeList;
                              context: {
                                $implicit: {
                                  data: type.childs,
                                  index: list.index + 1,
                                  parent: type,
                                  collapse: true
                                }
                              }
                            ">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>

                    <div *ngIf="typeOptions.length == 0" class="empty-box">
                      <img src="./../../../../../../assets/images/empty1.svg" alt="">
                      <h3>{{'cabinet.empty' | translate}}</h3>
                    </div>
    
                    <ng-container *ngTemplateOutlet="
                        typeList;
                        context: {
                          $implicit: {
                            data: typeOptions,
                            index: 0,
                            parent: null,
                            collapse: true
                          }
                        }
                      ">
                    </ng-container>
                  </div>
                </mat-select>
                <mat-error *ngIf="getFormControl('type_id').hasError('required')">
                  {{'global.form.can_not_save'|translate}}{{'new_doc.type' | translate}}{{'global.form.be_empty'|translate}}
                </mat-error>
              </mat-form-field>
  
              <mat-form-field appearance="outline">
                <mat-label>{{ "new_doc.office" | translate }}</mat-label>
                <mat-select formControlName="office_id" [placeholder]="'new_doc.office' | translate" required (selectionChange)="onOfficeSelectChange()">
                  <mat-form-field color="accent" appearance="fill" style="width: 100%;" class="decrease-form-field-infix">
                    <input matInput type="text" 
                          (keyup)="onOfficeSearchChange($event)" 
                          placeholder="{{'global.form.type_to_choose' | translate: {param: ('new_doc.office' | translate)} }}" />
                  </mat-form-field>

                  <div class="list-option">
                    <mat-option value="" *ngIf="offceOptions.length > 0">
                      {{ "office.create_edit_dialog.form.none" | translate }}
                    </mat-option>
    
                    <ng-template #officeList let-list>
                      <ng-container *ngFor="let office of list.data; let i = index">
                        <div [style.padding-left]="18 * list.index + 12 + 'px'"
                          class="tfield color-primary">
                          <button style="float: left; height: 45px; width: 45px;" (click)="
                              office.collapse = !!!office.collapse;
                              clickCollapse(office)
                            " [style.visibility]="
                              office.childs.length ? 'show' : 'hidden'
                            " mat-icon-button>
                            <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                            <mat-icon *ngIf="office.collapse">arrow_drop_down</mat-icon>
                          </button>
                          
                          <mat-option style="width: calc(100% - 45px); float: right" [value]="office._id"
                            [disabled]="!isProfessionalOfficer && !isPostOfficer && (office.childs.length > 0)"
                            (click)="onOfficeChange(office)">
                            {{ office.name }}
                          </mat-option>
                        </div>
    
                        <ng-container *ngIf="office.childs">
                          <ng-container *ngTemplateOutlet="
                              officeList;
                              context: {
                                $implicit: {
                                  data: office.childs,
                                  index: list.index + 1,
                                  parent: office,
                                  collapse: office.collapse
                                }
                              }
                            ">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
  
                    <div *ngIf="offceOptions.length == 0" class="empty-box">
                      <img src="./../../../../../../assets/images/empty1.svg" alt="">
                      <h3>{{'cabinet.empty' | translate}}</h3>
                    </div>
    
                    <ng-container *ngTemplateOutlet="
                        officeList;
                        context: {
                          $implicit: {
                            data: offceOptions,
                            index: 0,
                            parent: null,
                            collapse: true
                          }
                        }
                      ">
                    </ng-container>
                  </div>
                </mat-select>
                <mat-error *ngIf="getFormControl('office_id').hasError('required')">
                  {{'global.form.can_not_save'|translate}}{{'new_doc.office' | translate}}{{'global.form.be_empty'|translate}}
                </mat-error>
              </mat-form-field>
  
              <!--  CABINET  -->
              <mat-form-field color="accent" appearance="outline" *ngIf="(cabinets?.length > 0) && isProfessionalOfficer && !is_soft_only">
                <mat-label>{{ "new_doc.cabinet" | translate }}</mat-label>
                <mat-select formControlName="cabinet" [placeholder]="'new_doc.cabinet' | translate">
                  <mat-select-trigger>
                    <span class="mattriger-value"><mat-icon>dns</mat-icon> {{ getCabinetByObjID(formGroup.value.cabinet) || 'global.empty' | translate }} </span>
                  </mat-select-trigger>
                  <mat-option [value]="''"><mat-icon>dns</mat-icon> {{'global.empty' | translate}}</mat-option>
                  <mat-option *ngFor="let cabinet of cabinets" [value]="cabinet._id">
                    <mat-icon>dns</mat-icon> {{ cabinet.code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            
            <div class="form-box m-t-10">
              <div class="flex-row border relative exp-in" [ngClass]="{ 'error' : getFormControl('states').hasError('required') && getFormControl('states').touched }">
                <p class="legend">{{ "new_doc.state" | translate }}</p>
                  <mat-radio-group formControlName="states" class="flex-row max state">
                    <mat-radio-button
                      class="flex-1 align-center flex"
                      *ngFor="let state of states" [value]="state._id" (change)="onRadioStateChange($event)">
                      {{ lang != 'en' ? state.name_kh : state.name }}
                    </mat-radio-button>
                  </mat-radio-group>

                  <mat-selection-list class="flex-row max state" style="width: 30%;" #shoes formControlName="state_soft_only" disableRipple (selectionChange)="onStateOptionChange()">
                    <mat-list-option class="state-opt" *ngIf="state_soft_only" [value]="state_soft_only._id" disableRipple>
                      {{lang != 'en' ? state_soft_only.name_kh : state_soft_only.name}}
                    </mat-list-option>
                  </mat-selection-list>
              </div>
              <mat-error @fade *ngIf="getFormControl('states').hasError('required') && getFormControl('states').touched">
                {{'global.form.can_not_save'|translate}}{{'new_doc.state' | translate}}{{'global.form.be_empty'|translate}}
              </mat-error>
            </div>
  
            <div class="form-box">
              <div class="flex-row border relative" [ngClass]="getFormControl('quality').hasError('required') && getFormControl('quality').touched ? 'error' : null">
                <p class="legend">{{ "new_doc.quality" | translate }}</p>
                <mat-radio-group formControlName="quality" class="flex-row max-width">
                  <mat-radio-button
                    class="flex-1 align-center flex"
                    *ngFor="let quality of qualities" [value]="quality._id">
                    {{ lang != 'en' ? quality.name_kh : quality.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-error @fade *ngIf="getFormControl('quality').hasError('required') && getFormControl('quality').touched">
                {{'global.form.can_not_save'|translate}}{{'new_doc.quality' | translate}}{{'global.form.be_empty'|translate}}
              </mat-error>
            </div>
  
            <div class="form-box">
              <div class="flex-row border relative" [ngClass]="getFormControl('accessibilities').hasError('required') && getFormControl('accessibilities').touched ? 'error' : null">
                <p class="legend">{{ "new_doc.accessibility" | translate }}</p>
                <mat-radio-group formControlName="accessibilities" class="flex-row max-width" (change)="updatePublicFileRequired($event)">
                  <mat-radio-button
                    class="flex-1 align-center flex"
                    *ngFor="let access of accessibilities" [value]="access._id">
                    {{ lang != 'en' ? access.name_kh : access.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-error @fade *ngIf="getFormControl('accessibilities').hasError('required') && getFormControl('accessibilities').touched">
                {{'global.form.can_not_save'|translate}}{{'new_doc.accessibility' | translate}}{{'global.form.be_empty'|translate}}
              </mat-error>
            </div>
            
            <div class="field-group">
              <mat-form-field style="width: 100%; margin-right: 0px" color="accent" appearance="outline">
                <mat-label>{{ "new_doc.keyword" | translate }}</mat-label>
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let keyword of keywords" [selectable]="true" [removable]="true"
                    (removed)="remove(keyword)">
                    {{ keyword }}
                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="{{ 'new_doc.keyword' | translate }}" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)" />
                </mat-chip-list>
              </mat-form-field>
            </div>
  
            <div class="field-group check-box-container" *ngIf="selectedLetterType?.document_type?.name != DOC_TYPES.PERMANENT">
              <p class="title-field">{{ "new_doc.availible" | translate }}</p>
              
              <mat-checkbox (change)="changeExpireValidate($event)" [checked]="is_expired">
                {{ "new_doc.expired_doc" | translate }}
              </mat-checkbox>
  
              <mat-form-field color="accent" appearance="outline" class="doc-expired-date m-r-5">
                <mat-label>{{ "new_doc.expired_date" | translate }}</mat-label>
                <input matInput [matDatepicker]="expired_date" formControlName="expired_date"
                  [placeholder]="'new_doc.expired_date' | translate" (focus)="datepicker($event, expired_date)" />
                <mat-datepicker-toggle matSuffix [for]="expired_date"></mat-datepicker-toggle>
                <mat-datepicker #expired_date></mat-datepicker>
              </mat-form-field>
      
              <mat-form-field class="example-full-width" appearance="outline" class="doc-expired-date m-l-5">
                <mat-label>{{'new_doc.clear_expired' | translate}}</mat-label>
                <input matInput [matDatepicker]="expired_clear" formControlName="clear_date" (focus)="datepicker($event, expired_clear)">
                <mat-datepicker-toggle matSuffix [for]="expired_clear"></mat-datepicker-toggle>
                <mat-datepicker #expired_clear></mat-datepicker>
              </mat-form-field>
            </div>
  
            <mat-form-field color="accent" appearance="outline">
              <mat-label>{{ "new_doc.objective" | translate }}</mat-label>
              <textarea rows="2" formControlName="objective" matInput placeholder="{{ 'new_doc.objective' | translate }}"
                type="text"></textarea>
            </mat-form-field>
  
            <mat-form-field color="accent" appearance="outline">
              <mat-label>{{ "new_doc.summary" | translate }}</mat-label>
              <textarea rows="3" formControlName="summary" matInput placeholder="{{ 'new_doc.summary' | translate }}"
                type="text"></textarea>
            </mat-form-field>
  
            <!-- Personal Code Form -->
            <div class="flex-row border relative selected-user">
              <p class="legend">
                {{ "new_doc.personal_codes_input" | translate }}
              </p>
              <div class="flex-col max-width">
                <div style="margin-top: 12px;">
                  <button (click)="onSelectUser()" class="m-l-10" mat-raised-button>
                    <mat-icon>people</mat-icon> {{ 'new_doc.dialog.select.user' | translate }}
                  </button>
                </div>
  
                <div class="user-role-form-field" *ngIf="selectedUsers.length">
                  <table [dataSource]="selectedUsers" mat-table>
                    <ng-container matColumnDef="no">
                      <th mat-header-cell *matHeaderCellDef>
                        {{ 'new_doc.dialog.select.table.no' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let i = index">
                          {{ i + 1 }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="code" #selectedUsersTable>
                      <th mat-header-cell *matHeaderCellDef>
                        {{ 'new_doc.dialog.select.table.code' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let user">
                        {{ user.personal_code }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="username">
                      <th mat-header-cell *matHeaderCellDef>
                        {{ 'new_doc.dialog.select.table.username' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let user">
                          {{ user.last_name + " " + user.first_name }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="office" #selectedUsersTable>
                        <th mat-header-cell *matHeaderCellDef>
                          {{ 'new_doc.dialog.select.table.office' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let user">
                            {{ user.office.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="remove" #selectedUsersTable>
                      <th mat-header-cell *matHeaderCellDef align="center">
                        {{ 'new_doc.dialog.select.table.remove' | translate }}
                      </th>
                      <td mat-cell *matCellDef="let i = index" align="center">
                          <button (click)="removeSelectedUser(i)" mat-icon-button><mat-icon class="red-text">cancel</mat-icon></button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="selectUserDisplayCol"></tr>
                    <tr mat-row *matRowDef="let row; columns: selectUserDisplayCol"></tr>
                  </table>
                </div>
              </div>
            </div>
  
            <div class="field-group group-btn-submit">
              <button mat-raised-button (click)="goBack()" class="m-t-10">
                {{ "new_doc.button.cancel" | translate }}
              </button>
              <div class="add-space"></div>
              <button mat-raised-button [disabled]="submiting" color="accent" (click)="isEditing ? editDocument() : createDocument()" class="m-t-10">
                  {{ submiting ? ('global.loading' | translate) : isEditing ? ("new_doc.button.save" | translate) : ("new_doc.button.create" | translate) }}
              </button>
            </div>
          </form>
        </div>
        <mat-progress-bar *ngIf="submiting" color="accent" mode="indeterminate"></mat-progress-bar>
      </main>
    </div>
  </div>