import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageComponent } from './../dialogs/message/message.component';
import { NgModule } from '@angular/core';
import { CommonMaterialModule } from './common-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'src/app/core/pipe/pipe.module';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTypographyModule } from '@alyle/ui/typography';
import { LyThemeModule, LY_THEME } from '@alyle/ui';
import { CropDialogComponent } from '../dialogs/crop-dialog/crop-dialog.component';
import { MinimaDark, MinimaLight } from '@alyle/ui/themes/minima';
import { LySliderModule } from '@alyle/ui/slider';


@NgModule({
    declarations: [
        MessageComponent,
        CropDialogComponent
    ],
    imports: [
        CommonModule,
        CommonMaterialModule,
        ReactiveFormsModule,
        MatDialogModule,
        FormsModule,
        TranslateModule,
        PipeModule,

        LyImageCropperModule,
        LyButtonModule,
        LyThemeModule.setTheme('minima-dark'),
        LyIconModule,
        LyTypographyModule,
        LySliderModule
    ],
    exports: [
        CommonMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        PipeModule
    ],
    providers: [
        { provide: LY_THEME, useClass: MinimaLight, multi: true },
        { provide: LY_THEME, useClass: MinimaDark, multi: true }
    ]
})
export class SharedModule {

}
