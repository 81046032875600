import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { LocalStorageEnum } from 'src/app/core/models/enum/local-storage.enum';
import { REQ_TAB } from 'src/app/core/models/enum/request-tab.enum';
import { ROLE } from 'src/app/core/models/enum/role.enum';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { NavService } from 'src/app/core/services/nav.service';
import { NewAPIService } from 'src/app/core/services/new-api.service';

@Component({
  selector: 'app-user-request',
  templateUrl: './user-request.component.html',
  styleUrls: ['./user-request.component.scss']
})
export class UserRequestComponent implements OnInit {
  selectedIndex: number = 0;
  requests: Array<any> = [];
  isEmpty: boolean = false;
  isLoading: boolean = true;
  isPending = true;
  undeterminedRequests: Array<any> = [];
  determinedRequests: Array<any> = [];
  requestTypes: any = {};
  requestStatuse: any = {};
  currentPage: number = 1;
  limit: number = 10;
  isShowOptionFilter: boolean = false;
  options = {
    transitionDuration: ".2s"
  };
  statusTarget: string = '';
  current_user_role: string;
  commonStatuses: Array<string> = [];
  commonRequestTypes: Array<string> = [];
  isShowNumber: boolean = false;
  routeSubscription: Subscription = new Subscription();

  constructor(
    private newApi: NewAPIService,
    private ls: LocalstorageService,
    public nav: NavService,
    private title: Title,
    private routes: ActivatedRoute,
    private router: Router
  ) { 
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.current_user_role = user.role._id.toString();
  }

  ngOnInit(): void {
    this.routeSubscription = this.routes.paramMap.subscribe(params => {
      this.selectedIndex = parseInt(params.get("tab")) || 1;
      this.isPending = this.selectedIndex == 1;
    });
    this.isShowNumber = [ROLE.ADMIN, ROLE.APPROVER, ROLE.VERIFIER].includes(this.current_user_role as ROLE);
    this.title.setTitle("User Requests");
    this.nav.updatePendingCount();
    combineLatest([this.newApi.getRequestTypes(), this.newApi.getRequestsStatus()]).subscribe((res: any[]) => {
      res[0].requestTypes.forEach((type) => this.requestTypes[type.name] = type._id);
      res[1].statuses.forEach((status) => this.requestStatuse[status.status_name] = status._id);
      this.getRequestList();
    });
  }

  onTabChange(event) {
    this.router.navigate(["/requests/user", event.index + 1]);
    this.nav.updatePendingCount();
    this.currentPage = 1;
    this.statusTarget = '';
    this.isEmpty = false;
    switch (event.index) {
      case 0:
        this.isShowOptionFilter = false;
        this.isPending = true;
        this.isEmpty = false;
        this.undeterminedRequests = [];
        this.getUndeterminedRequests(this.dataJson);
        break;
      case 1:
        this.isShowOptionFilter = true;
        this.isPending = false;
        this.determinedRequests = [];
        this.getDeterminedRequests(this.dataJson);
    }
  }

  getUndeterminedStatuses() {
    const newObj = Object.assign({ all: '' }, this.requestStatuse);
    delete newObj.rejected;
    delete newObj.approved;
    if (this.current_user_role == ROLE.VERIFIER) delete newObj.verified;
    return newObj;
  }

  getDeterminedStatuses() {
    const newObj = Object.assign({ all: '' }, this.requestStatuse);
    delete newObj.pending;
    if (this.current_user_role != ROLE.VERIFIER) delete newObj.verified;
    return newObj;
  }

  onScrollDown() {
    this.currentPage++;
    this.getRequestList();
  }

  getUndeterminedRequests(data: any): void {
    this.isLoading = true;
    this.newApi.getRequestsByPage({
      is_determined: REQ_TAB.UNDETERMINED,
      ...data
    })
    .subscribe((res: any) => {
      this.isLoading = false;
      if (res.status == SERVER_STATUS.SUCCESS) {
        this.undeterminedRequests.push(...res.requests);
      }
    });
  }

  getDeterminedRequests(data: any): void {
    this.isLoading = true;
    this.newApi.getRequestsByPage({
      is_determined: REQ_TAB.DETERMINED,
      ...data
    }).subscribe((res: any) => {
      this.isLoading = false;
      if (res.status == SERVER_STATUS.SUCCESS) {
        this.determinedRequests.push(...res.requests);
      }
    });
  }

  onFilter(val: string) {
    this.statusTarget = val;
    this.currentPage = 1;
    this.undeterminedRequests = [];
    this.determinedRequests = [];
    this.getRequestList();
  }

  getRequestList() {
    if (this.isPending) {
      // this.setUndeterminedQuery();
      // let statuses = is_filter && seleted_status.length ? seleted_status : this.commonStatuses;
      // this.dataJson.status = statuses;
      this.getUndeterminedRequests(this.dataJson);
    }
    else {
      // this.setDeterminedQuery();
      // let statuses = is_filter && seleted_status.length ? seleted_status : this.commonStatuses;
      // this.dataJson.status = statuses;
      this.getDeterminedRequests(this.dataJson);
    }
  }

  // setUndeterminedQuery() {
  //   if (this.current_user_role == ROLE.VERIFIER) {
  //     this.commonStatuses = [
  //       this.requestStatuse.pending
  //     ];
  //   }
  //   else {
  //     this.commonStatuses = [
  //       this.requestStatuse.pending,
  //       this.requestStatuse.verified
  //     ];
  //   }

  //   this.commonRequestTypes = [
  //     this.requestTypes.Create,
  //     this.requestTypes.Update,
  //     this.requestTypes.Verify,
  //     this.requestTypes['Reset Password']
  //   ];
  // }

  // setDeterminedQuery() {
  //   if (this.current_user_role == ROLE.VERIFIER) {
  //     this.commonStatuses = [
  //       this.requestStatuse.verified,
  //       this.requestStatuse.rejected,
  //       this.requestStatuse.approved
  //     ];
  //   }
  //   else {
  //     this.commonStatuses = [
  //       this.requestStatuse.rejected,
  //       this.requestStatuse.approved
  //     ];
  //   }

  //   this.commonRequestTypes = [
  //     this.requestTypes.Create,
  //     this.requestTypes.Update,
  //     this.requestTypes.Verify,
  //     this.requestTypes["Reset Password"]
  //   ];
  // }

  get dataJson() {
    let data = {
      page: this.currentPage.toString(10),
      count: this.limit.toString(10),
      status: this.statusTarget || null,
      onModel: 'user'
    }
    return data;
  }
}
