<div id="crop-container">
    <div [withClass]="classes.actions">
        <button mat-raised-button bg="primary" (click)="_fileInput.click(); onVerifyBackground($event);"
            class="btn-browse-image">
            <ly-icon [withClass]="classes.icon">image</ly-icon>
            <span>{{ 'button.browse_file' | translate }}</span>
        </button>
        <input #_fileInput type="file" (change)="cropping.selectInputEvent($event); onVerifyBackground($event);"
            accept="image/*" hidden>
    </div>

    <div *ngIf="cropping.isLoaded">
        <button (click)="cropping.zoomOut()" mat-icon-button appearance="icon">
            <mat-icon>zoom_out</mat-icon>
        </button>
        <div *ngIf="cropping.isLoaded" [className]="classes.range"
            style="max-width: 150px; display: inline-block; height: 32px;">
            <input type="range" [className]="classes.rangeInput" [attr.min]="cropping.minScale" max="1"
                [(ngModel)]="scale" step="any">
        </div>
        <button (click)="cropping.zoomIn()" mat-icon-button appearance="icon">
            <mat-icon>zoom_in</mat-icon>
        </button>
        <button (click)="cropping.center()" mat-button>
            <ly-icon>filter_center_focus</ly-icon>
        </button>
        <button (click)="cropping.rotate(-90)" mat-button>
            <ly-icon>rotate_90_degrees_ccw</ly-icon>
        </button>
        <button (click)="cropping.fit()" mat-button>Fit</button>
        <button (click)="cropping.fitToScreen()" mat-button>Fit to screen</button>
    </div>

    <ly-img-cropper #cropping [withClass]="classes.cropping" [config]="myConfig" [(scale)]="scale"
        (cropped)="onCrop($event)">
        <span>Drag and drop image</span>
    </ly-img-cropper>

    <img id="load_image" *ngIf="selectedImage != undefined" [src]="selectedImage" style="display: none;">
    <img id="cropped_image" *ngIf="cropping.isCropped && selectedImage != undefined" [src]="croppedImg"
        style="display: none;" (load)="onLoadImage('cropped_image')">

    <div id="backGroundColor" class="background-color-box"></div>

    <!-- <p class="text-note">{{ 'crop_img_msg.photo_doc' | translate }}</p> -->

    <div class="btn-group">
        <button [mat-dialog-close]="false" mat-raised-button class="btn-exit">
            <mat-icon>exit_to_app</mat-icon> {{ 'crop_img_msg.exit' | translate }}
        </button>
        <button *ngIf="cropping.isLoaded" (click)="cropping.crop()" mat-raised-button class="btn-crop">
            {{ 'crop_img_msg.confirm' | translate }}
        </button>
    </div>
</div>