import { LocalStorageEnum } from './../models/enum/local-storage.enum';
import { LocalstorageService } from './localstorage.service';
import { environment } from './../../../environments/environment';
import { NewRequestService } from './new-request.service';
import { NewAPIService } from './new-api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { LoginResponse } from '../models/responses/login.response';
import { Router } from '@angular/router';
import { NavService } from './nav.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userRoleID: string;
  public userID: string;
  public locale: string;
  public officeID: string;
  public arrOfficeIds: Array<string> = [];

  logOutEventEmitter: EventEmitter<boolean> = new EventEmitter();
  logInEventEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private newApiService: NewAPIService,
    private ls: LocalstorageService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private requestService: NewRequestService,
    private localStorage: LocalStorageService,
    private router: Router
    ) {
  }

  public authenticate(user) {
    return this.newApiService.login(user)
      .pipe(tap((res: any) => {
        if (res.status === 1) {
          this.onAuthenticated(res);
        } else {
          if (res.message == "Incorrect username or password")
            this.snackbar.open(this.translate.instant("sign_in.incorrect"), "", {
              duration: 2000,
              panelClass: "panelClass-error"
            });
          throw new Error('Error');
        }
      }));
  }

  public hasLoggedIn(): boolean {
    return !!this.getToken();
  }

  public saveToken(token: string) {
    this.ls.set(LocalStorageEnum.token, token);
  }

  public saveRefreshToken(refreshToken: string) {
    this.ls.set(LocalStorageEnum.refresh_token, refreshToken);
  }

  public saveUser(data: any) {
    this.ls._setEncryption(LocalStorageEnum.user, JSON.stringify(data));
  }

  public saveUserRole(userRoleID: string) {
    this.userRoleID = userRoleID;
    this.ls._setEncryption(LocalStorageEnum.user_role_id, userRoleID);
  }

  public saveUserID(userID: string) {
    this.userID = userID;
    this.ls._setEncryption(LocalStorageEnum.user_id, userID);
  }

  public saveLocale(locale: string) {
    this.locale = locale;
    this.ls.set(LocalStorageEnum.language, locale);
  }

  public saveOfficeID(officeID: string) {
    this.officeID = officeID;
    this.ls.set(LocalStorageEnum.offic_id, officeID);
  }

  public getUserRole(): string {
    if (this.userRoleID) {
      return this.userRoleID;
    }
  }
  public getUserRoleFromLS(): string {
    return this.ls._getDecryption(LocalStorageEnum.user_role_id);
  }

  public getUserID(): string {
    if (this.userID) {
      return this.userID;
    }
  }

  public getOfficeID(): string {
    if (this.officeID) {
      return this.officeID;
    }
  }

  public getLocale(): string {
    var lang = this.ls.get(LocalStorageEnum.language);
    return lang ? lang : 'km';
  }

  public getToken() {
    return this.ls.get(LocalStorageEnum.token);
  }

  public getAvailableLocales() {
    return ['km, en'];
  }

  public clearToken() {
    localStorage.clear();
  }

  public signOut() {
    localStorage.clear();
    this.router.navigate(['/sign-in']);
  }

  private onAuthenticated(response: LoginResponse) {
    this.localStorage.setJSON(LocalStorageEnum.tokenExpiryTime, response.expired_date);
    this.ls.set(LocalStorageEnum.token, response.token);
    this.ls.set(LocalStorageEnum.refresh_token, response.refresh_token);
    this.ls._setEncryption(LocalStorageEnum.user, JSON.stringify(response.user));
  }

  public getRefreshToken() {
    const url = environment.baseUrl + "login/refresh-token";
    return this.requestService.postJSONWithoutMessage(url, { is_refresh_token: true }).pipe(map((res: any) => {
      if (res.status === 1) {
        this.onAuthenticated(res);
      }
    }))
  }
}
