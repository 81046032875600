import { LocalStorageEnum } from './../models/enum/local-storage.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor() { }

  save(key: LocalStorageEnum, value: string) {
    localStorage.setItem(key, value);
  }

  get(key: LocalStorageEnum) {
    return localStorage.getItem(key);
  }

  delete(key: LocalStorageEnum) {
    localStorage.removeItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  // setEncryption(key: string, value: string) {
  //   const prefix = btoa(key).replace(/=/g, '');
  //   const base64 = btoa(unescape(encodeURIComponent(value)));
  //   localStorage.setItem(key, btoa(prefix + base64));
  // }

  // getDecryption(key: string) {
  //   const prefix = btoa(key).replace(/=/g, '');
  //   const item = localStorage.getItem(key);
  //   if (!item) {
  //     return null;
  //   }
  //   try {
  //     const base64 = atob(item).replace(prefix, '');
  //     return atob(base64);
  //   } catch (error) {
  //     return null;
  //   }
  // }

  setSpecialCharacter(key: string, value: string) {
    const prefix = btoa(unescape(encodeURIComponent(key))).replace(/=/g, '');
    const base64 = btoa(unescape(encodeURIComponent(value)));
    localStorage.setItem(key, btoa(prefix + base64));
  }

  getSpecialCharacter(key: string) {
    const prefix = btoa(unescape(encodeURIComponent(key))).replace(/=/g, '');
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      const base64 = decodeURIComponent(escape(window.atob(item))).replace(prefix, '');
      return decodeURIComponent(escape(window.atob(base64)));
    } catch (error) {
      return null;
    }
  }

  setObject(key: string, values: object) {
    const value = JSON.stringify(values);
    this.set(key, value);
  }


  _setEncryption(key: string, value: string) {
    const prefix = btoa(key).replace(/=/g, '');
    const base64 = btoa(this.toBinary(value));
    localStorage.setItem(key, btoa(prefix + base64));
  }

  _getDecryption(key: string) {
    const prefix = btoa(key).replace(/=/g, '');
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      const base64 = atob(item).replace(prefix, '');
      return this.fromBinary(atob(base64));
    } catch (error) {
      return null;
    }
  }

  toBinary(value: string) {
    const codeUnits = new Uint16Array(value.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = value.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }

  fromBinary(encoded_value: string) {
    let binary = atob(encoded_value);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }
}
