import { LocalStorageEnum } from 'src/app/core/models/enum/local-storage.enum';
import { LocalstorageService } from './../services/localstorage.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NewAPIService } from '../services/new-api.service';
import { SERVER_STATUS } from '../models/enum/server_response.enum';
import { NewRequestService } from '../services/new-request.service';
import { environment } from 'src/environments/environment';
import * as Moment from 'moment';
import { LocalStorageService } from '../services/local-storage.service';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private ls: LocalstorageService,
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    const expiryTime = this.localStorage.getJSON<number>(LocalStorageEnum.tokenExpiryTime) * 1000;

    if (moment().isBefore(expiryTime)) {
      if (route.data.roles && route.data.roles.indexOf(user.role._id.toString()) === -1) {
        this.router.navigate(['sign-in']);
        return false;
      }
      return true;
    }
    else {
      return !this.router.navigate(['sign-in'], { queryParams: { returnUrl: state.url } });
    }
  }
}
