import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      headerTitle?: string
      title: string
      subtitle: string
      lastSubtitle: string
      isDelete: boolean
      isPasswordReset: boolean
      label: string
      info: string
      name: string
      request: any
      hightlight: string
      isNegative?: boolean
      positiveText: string
      negativeText: string
    },
    private title: Title
    
  ) {}


  ngOnInit(): void {
    if (this.data.headerTitle) this.title.setTitle(this.data.headerTitle);
  }
}
