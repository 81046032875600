<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <ng-template [ngIf]="data.request">
    <p *ngIf='data.request.tmp_model == "document"'>{{'notification.button.' + (data.isDelete ? 'delete' : data.request.status.status_name) | translate }} : <b>{{ data.request.reference.title }}</b> | {{'reason.request_by' | translate}} 
      <b>{{ data.request.requestor.first_name }} {{ data.request.requestor.last_name }}</b></p>
  </ng-template>
  <p *ngIf="data.name && data.label">{{ data.label }} <span class="value">{{ data.name }}</span></p>
  <p>
    <span *ngIf="data.info">{{ data.info }}</span> 
    <span>{{ data.subtitle }}</span>
    <span color="accent" class="accent"> {{ data.hightlight }}</span>
    <span> {{data.lastSubtitle}}</span>
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false">{{ data.negativeText }}</button>
  <button class="m-l-10" mat-raised-button [color]="data.isNegative ? 'warn' : 'accent'" [mat-dialog-close]="true">
    {{ data.positiveText }}
  </button>
</mat-dialog-actions>