import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string, action?: boolean, actionIcon?: string, actionTxt?: string, actionColor: string, onAction: Function /* Localize String */ }
  ) { }

  ngOnInit(): void {
  }

}
