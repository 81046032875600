import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleDatePipe } from './locale-date.pipe';
import { LocaleNumberPipe } from './locale-number.pipe';
import { ImageReaderPipe } from './image-reader.pipe';
import { EpllipsisTextPipe } from './epllipsis-text.pipe';
import { FileSizePipe } from './file-size.pipe';
import { LoadImagePipe } from './load-image.pipe';
import { ChatTimePipe } from './chat-time.pipe';



@NgModule({
  declarations: [
    LocaleDatePipe,
    LocaleNumberPipe,
    ImageReaderPipe,
    EpllipsisTextPipe,
    FileSizePipe,
    LoadImagePipe,
    ChatTimePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LocaleDatePipe,
    LocaleNumberPipe,
    ImageReaderPipe,
    EpllipsisTextPipe,
    FileSizePipe,
    LoadImagePipe,
    ChatTimePipe
  ]
})
export class PipeModule { }
