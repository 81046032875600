<div class="main-container">
    <div class="fixed-header">
        <div class="header-container">
            <div class="left-side">
                <a routerLink="">
                    <img src="/assets/logo/MEF1.png" alt="" />
                </a>
            </div>
            <span class="spacer"></span>
            <div class="right-side">
                <!-- <button (click)="toggleHeaderMenu()" class="header-menu-toggle">
                    <div class="hamburger_wrapper">
                        <div id="hamburger" [ngClass]="isOpenHeaderMenu && 'close'">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                    </div>
                </button> -->
                <button mat-button *ngIf="lang == 'km' || !lang" (click)="switchLanguage('en')" class="btn-lang">
                    <img src="./../../../../../assets/images/english.png" alt="">
                </button>
                <button mat-button *ngIf="lang == 'en'" (click)="switchLanguage('km')" class="btn-lang">
                    <img src="./../../../../../assets/images/khmer.png" alt="">
                </button>
                <!-- <ul class="header-menu" [ngClass]="isOpenHeaderMenu && 'now-active'">
                    <li>
                        <button mat-button *ngIf="lang == 'km' || !lang" (click)="switchLanguage('en')" class="btn-lang">
                            <img src="./../../../../../assets/images/english.png" alt="">
                        </button>
                        <button mat-button *ngIf="lang == 'en'" (click)="switchLanguage('km')" class="btn-lang">
                            <img src="./../../../../../assets/images/khmer.png" alt="">
                        </button>
                    </li>
                    <li>
                        <button mat-button class="sign-in-btn" (click)="login()">
                                <svg class="sign-in-inner" fill="#FFFFFF" enable-background="new 0 0 24 24" height="30" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="m15.5 12.5h-15c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h15c.276 0 .5.224.5.5s-.224.5-.5.5z"/>
                                    </g>
                                    <g>
                                        <path d="m11.5 16.5c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l3.647-3.647-3.646-3.646c-.195-.195-.195-.512 0-.707s.512-.195.707 0l4 4c.195.195.195.512 0 .707l-4 4c-.098.097-.226.146-.354.146z"/>
                                    </g>
                                    <g>
                                        <path d="m12 23c-4.655 0-8.823-2.947-10.372-7.333-.092-.261.045-.546.305-.638.261-.09.546.045.638.305 1.408 3.987 5.197 6.666 9.429 6.666 5.514 0 10-4.486 10-10s-4.486-10-10-10c-4.232 0-8.021 2.679-9.428 6.667-.092.26-.378.395-.638.305-.26-.092-.397-.377-.305-.638 1.548-4.387 5.716-7.334 10.371-7.334 6.065 0 11 4.935 11 11s-4.935 11-11 11z"/>
                                    </g>
                                </svg>
                                <span class="sign-in-inner">{{ 'sign_in.button.sign_in' | translate }}</span>
                        </button>
                    </li>
                </ul> -->
                <!-- <button mat-button [ngClass]="lang == 'en' ? 'active' : null" (click)="switchLanguage('en')" class="btn-lang">
                    <p>english</p>
                </button>
                <button mat-button [ngClass]="lang == 'km' ? 'active' : null" (click)="switchLanguage('km')" class="btn-lang">
                    <p>ខ្មែរ</p>
                </button> -->
            </div>
        </div>
    </div>

    <div class="view-block">
        <router-outlet></router-outlet>
    </div>

    <div class="footer">
        <div class="footer-menu">
            <ul id="menu">
                <li>
                    <a routerLink="/support/howto"><i class="fa fa-chevron-right fa-xs"></i>Term of Use</a>
                </li>
                <li>
                    <a routerLink="/howto/index"><i class="fa fa-chevron-right fa-xs"></i>Operating instruction</a>
                </li>
                <li>
                    <a routerLink="/page/faq"><i class="fa fa-chevron-right fa-xs"></i>Q & A</a>
                </li>
            </ul>
        </div>
    </div>
</div>