import { LocalstorageService } from './../../../core/services/localstorage.service';
import { RoleModel } from './../../../core/models/role.model';
import { OfficeModel } from './../../../core/models/office.model';
import { FormGroup, Validators, FormControl, AbstractControl, Form } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { UserModel } from './../../../core/models/user.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NewAPIService } from 'src/app/core/services/new-api.service';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Position } from './../../../core/models/position.model'
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ROLE } from 'src/app/core/models/enum/role.enum';
import { LocalStorageEnum } from 'src/app/core/models/enum/local-storage.enum';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SERVER_STATUS } from 'src/app/core/models/enum/server_response.enum';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import Validation from 'src/app/core/validators/password.validator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageComponent } from '../../snackbar/message/message.component';
import { STATUSES } from 'src/app/core/models/enum/request_status.enum';
import { CropDialogComponent } from '../crop-dialog/crop-dialog.component';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('150ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('150ms', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('fadeOpacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('150ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CreateUserDialogComponent implements OnInit {
  formGroup: FormGroup;
  maxAge: Date = new Date();
  avatarLoading: boolean = false;
  isCanEditPassword: boolean = false;
  @ViewChild("container") container: ElementRef;
  manageOfficeFormGroup: FormGroup;
  offices: OfficeModel[] = [];
  allOffices: OfficeModel[] = new Array();
  roles: RoleModel[] = [];
  positions: Position[] = [];
  isEditing: boolean = false;
  lang: string = '';
  selectedOfficeIDs: Array<string> = [];
  preventOfficeIDs: Array<string> = [];
  parentOfSelectedOffice: any = null;
  private proffessionalId: string = '';
  isShowRoleFormField: boolean = false;
  roleModel: number;
  avatarId: string = '';
  self_id: string = localStorage.getItem("user_id");
  role_id: string = localStorage.getItem("user_role_id");
  isPositionNotOfficer: boolean = true;
  isEmptyAvatar: boolean = true;
  avatarUrl: any = '';
  passwordStrongRegEx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  isSelfEdit: boolean = false;
  today: Date = new Date();
  user: UserModel;
  isRequest: boolean;
  private isUnchecked: boolean = false;
  private manage_office_ids: Array<string> = [];
  private autoSelectOfficeIds: Array<string> = [];
  private _isLoading: boolean;
  current_user: UserModel = null;
  submitSubscription: Subscription = new Subscription();
  pwdControl: FormControl = new FormControl("", Validators.pattern(this.passwordStrongRegEx));
  rePwdControl: FormControl = new FormControl("", Validators.pattern(this.passwordStrongRegEx));
  selectedManageOfficeID: string[] = new Array();
  passwordShow: boolean = false;
  selectedManageOffice: SelectionModel<string> = new SelectionModel();
  public get isLoading() {
    return this._isLoading;
  }

  public set isLoading(value: boolean) {
    this._isLoading = value;
    if (value) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  titles: Array<any> = [
    {
      name: "អគ្គបណ្ឌិតសភាចារ្យ",
      value: "អគ្គបណ្ឌិតសភាចារ្យ"
    },
    {
      name: "ឯកឧត្តម",
      value: "ឯកឧត្តម"
    },
    {
      name: "លោកជំទាវ",
      value: "លោកជំទាវ"
    },
    {
      name: "លោក",
      value: "លោក"
    },
    {
      name: "លោកស្រី",
      value: "លោកស្រី"
    },
    {
      name: "អ្នកនាង",
      value: "អ្នកនាង"
    },
    {
      name: "កញ្ញា",
      value: "កញ្ញា"
    }
  ];

  readonly DEPARTMENT_OFFICER_NAME = String.fromCharCode(6050, 6018, 6098, 6018, 6043, 6081, 6017, 6070, 6034, 6071, 6016, 6070, 6042, 6042, 6020, 47, 6050, 6018, 6098, 6018, 6035, 6070, 6041, 6016, 6042, 6020, 47, 6050, 6035, 6075, 6036, 6098, 6042, 6034, 6070, 6035, 6050, 6020, 6098, 6018, 6039, 6070, 6038);
  isAllowOutDepOption: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private newApi: NewAPIService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private ls: LocalstorageService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<CreateUserDialogComponent>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserModel, isRequest: boolean, requestID: string, isCreateReq: boolean }
  ) {

    this.user = this.data ? (this.data.user || this.data as unknown as UserModel) : null;
    this.isRequest = this.data && this.data.isRequest;
    this.current_user = JSON.parse(this.ls._getDecryption(LocalStorageEnum.user));
    this.role_id = this.current_user.role._id.toString();
    this.self_id = this.current_user._id;
    this.formGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      title: [''],
      pwd: this.pwdControl,
      "re-pwd": this.rePwdControl,
      role: ['', Validators.required],
      dob: '',
      office: ['', Validators.required],
      position: ['', Validators.required],
      avatar: [''],
      personal_code: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9]+$/)
      ]),
      is_highly_sensitive: [false],
      is_allow_out_dep: [false]
    });

    this.manageOfficeFormGroup = formBuilder.group({
      manage_office: [[], Validators.required]
    })

    if (this.user) {
      this.pwdControl.clearValidators();
      this.rePwdControl.clearValidators();
      this.isSelfEdit = this.self_id == this.user._id;
      this.isAllowOutDepOption = this.user.position.name_kh == this.DEPARTMENT_OFFICER_NAME;
      this.avatarId = this.user.avatar;
      this.formGroup.patchValue({
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        username: this.user.username,
        dob: this.user.dob,
        title: this.user.title,
        role: this.user.role ? this.user.role._id : '',
        office: this.user.office ? this.user.office._id : '',
        position: this.user.position ? this.user.position._id : '',
        avatar: this.user.avatar,
        personal_code: this.user.personal_code,
        email: this.user.email ? this.user.email : '',
        is_highly_sensitive: this.user.is_highly_sensitive,
        is_allow_out_dep: this.user.is_allow_out_dep
      });
      this.manageOfficeFormGroup.controls['manage_office'].setValue(this.user.manage_office_ids);
      this.getRoleByPositionId(this.user.position ? this.user.position._id : '');

      if (this.user.avatar) {
        this.avatarLoading = true;
        this.newApi.getAvatars(this.user.avatar)
          .subscribe((avatar: any) => {
            this.avatarLoading = false;
            this.avatarUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(avatar));
            this.isEmptyAvatar = false;
            URL.revokeObjectURL(this.avatarUrl);
          });
      }
      this.selectedOfficeIDs = this.user.manage_office_ids;
      this.isEditing = true;
    }
    else {
      // this.formGroup.disable();
      // setTimeout(() => {
      //   this.formGroup.enable();
      // }, 850)
    }
    this.pwdControl.valueChanges.subscribe(
      password => {
        if (password) {
          this.pwdControl.setValidators([Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
          this.rePwdControl.setValidators([Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
          if (password && this.rePwdControl.value && password != this.rePwdControl.value) {
            this.rePwdControl.setErrors({
              notUnique: true
            });
          } else {
            this.rePwdControl.setErrors({
              notUnique: false
            });
            this.rePwdControl.updateValueAndValidity({ emitEvent: false });
          }
          return;
        }
        this.pwdControl.setErrors(null);
        this.rePwdControl.clearValidators();
      },
      error => {
        throw new Error(error)
      }
    );
    this.rePwdControl.valueChanges.subscribe(password => {
      if (password != this.pwdControl.value) {
        this.pwdControl.setValidators([Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]);
        this.pwdControl.updateValueAndValidity();
        this.rePwdControl.setErrors({
          notUnique: true
        });
      } else {
        this.rePwdControl.setErrors(null);
      }
    });
  }

  // private updateValidateRoleField(): void {
  //   this.formGroup.get("role").setValidators(Validators.required);
  //   this.formGroup.get("role").updateValueAndValidity();
  // }
  // private clearValidateRoleField(): void {
  //   this.formGroup.get("role").clearValidators();
  //   this.formGroup.get("role").updateValueAndValidity();
  // }

  ngOnInit() {
    this.maxAge = new Date(this.today.getFullYear() - 18, this.today.getMonth(), this.today.getDate());
    let roleID = this.current_user.role._id as unknown as ROLE;
    this.isCanEditPassword = roleID == ROLE.ADMIN || roleID == ROLE.APPROVER;
    this.getOffice();
    this.getAllOffice();
    this.getPosition((positions) => {
      this.proffessionalId = positions.find((position) => position.name_kh === "មន្រ្តីជំនាញ")._id;
      if (this.user) {
        this.isPositionNotOfficer = this.user.position._id != positions.find(position => position.name == "Officer")._id;
        if (this.user.position._id == this.proffessionalId) {
          this.isShowRoleFormField = true;
          this.formGroup.get("role").patchValue(this.user.role._id);
        }
      }
    });
    this.lang = this.authService.getLocale();
  }
  private getAllOffice(): void {
    this.newApi.getOfficesTreeForDocumentForm().subscribe((response: any) => {
      if (response.status == 1) {
        this.allOffices = response.offices;
      }
    })
  }
  getPosition(callback: (positions: Position[]) => void): void {
    this.newApi.getPosition().subscribe(response => {
      if (response.status) {
        this.positions = response.positions;
        callback(response.positions);
      }
    });
  }

  getOffice() {
    if (this.user && this.self_id == this.user._id) {
      this.newApi.getOfficesTreeForDocumentForm().subscribe((response: any) => {
        this.offices = response.offices;
        if (this.user) {
          this.user.manage_office_ids.forEach((item: string) => {
            this.onAutoAddParent(item);
          });
        }
      });
      return;
    }

    this.newApi.getOfficesTree().subscribe((response: any) => {
      this.offices = response.offices;
      if (this.current_user) {
        this.current_user.manage_office_ids.forEach((item: string) => {
          this.onAutoAddParent(item);
        });
      }
    });
  }

  checkMangeOffice(id: string): boolean {
    return this.current_user.manage_office_ids.includes(id);
  }

  getRoleByPositionId(position_id: string) {
    this.newApi.getRoleByPositionId(position_id).subscribe((res: any) => {
      this.roles = res.roles;
    },
      err => console.error(err)
    )
  }

  onCreate() {
    if (this.formGroup.invalid) {
      this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
      this.formGroup.markAllAsTouched();
      return;
    }
    if (!this.pwdControl.value) {
      this.pwdControl.setErrors({
        required: true
      });
      return;
    } else if (this.formGroup.value.pwd != this.formGroup.value["re-pwd"]) {
      this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
      this.rePwdControl.setErrors({
        "notUnique": true
      });
      return;
    }
    const val = this.formGroup.value;
    const manageOfficeIds = this.manageOfficeFormGroup.value.manage_office;
    const newUser = {
      first_name: val.firstName,
      last_name: val.lastName,
      username: val.username,
      dob: val.dob || null,
      password: val.pwd,
      title: val.title,
      role_id: val.role,
      avatar: val.avatar,
      email: val.email,
      position: val.position,
      personal_code: val.personal_code,
      office_id: val.office,
      manage_office_ids: this.isPositionNotOfficer ? manageOfficeIds : [],
      is_highly_sensitive: val.is_highly_sensitive,
      is_allow_out_dep: val.is_allow_out_dep
    }
    this.submitSubscription.unsubscribe();
    this.submitSubscription = this.newApi.createUser(newUser)
      .subscribe({
        next: res => {
          if (res.status == SERVER_STATUS.SUCCESS) {
            this.dialogRef.close({ status: true });
            if (this.role_id != ROLE.ADMIN)
              this.router.navigateByUrl('/requests/user');
          } else if (res.status == SERVER_STATUS.ERROR_CLIENT) {
            if (Array.isArray(res.data.password) && res.data.password.includes("Password must contain min 8 letters, with at least a symbol, upper and lower case letters and a number")) {
              this.pwdControl.setErrors({
                "pwd-strong": true
              });
              this.rePwdControl.setErrors({
                "pwd-strong": true
              });
            }
            if (Array.isArray(res.data.personal_code) && res.data.personal_code.includes("Personal Code already exist"))
              this.formGroup.get("personal_code").setErrors({
                exist: true
              });
            if (Array.isArray(res.data.manage_office_ids) && res.data.manage_office_ids.includes("Manage Offices are required"))
              this.manageOfficeFormGroup.setErrors({
                required: true
              })
            if (Array.isArray(res.data.username) && res.data.username.includes("Username already exist"))
              this.getFormControl("username").setErrors({
                exist: true
              });
            this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
          }
        },
        error: err => console.error(err)
      });
  }

  onEdit() {
    if (this.formGroup.invalid) {
      this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.formGroup.value.pwd != this.formGroup.value["re-pwd"]) {
      this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
      this.formGroup.markAllAsTouched();
      this.rePwdControl.setErrors({
        "notUnique": true
      });
      return;
    }
    const val = this.formGroup.value;
    const manageOfficeIds = this.manageOfficeFormGroup.value.manage_office;
    const newUser = {
      first_name: val.firstName,
      last_name: val.lastName,
      username: val.username,
      password: val.pwd,
      confirm_password: val['re-pwd'],
      dob: val.dob || null,
      title: val.title,
      role_id: val.role,
      avatar: val.avatar,
      email: val.email,
      personal_code: val.personal_code,
      position: val.position,
      office_id: val.office,
      manage_office_ids: this.isPositionNotOfficer ? manageOfficeIds : [],
      is_highly_sensitive: val.is_highly_sensitive,
      is_allow_out_dep: val.is_allow_out_dep
    }
    this.submitSubscription.unsubscribe();
    // this.isLoading = true;
    const id = this.isRequest ? this.data.requestID : this.user._id;
    this.submitSubscription = this.newApi.editUser(id, newUser)
      .subscribe({
        next: (res: any) => {
          if (res.status == SERVER_STATUS.SUCCESS && this.self_id == this.user._id) {
            // this.ls.setEncryption(LocalStorageEnum.user, JSON.stringify(res.user));
            this.newApi.getProfile()
              .subscribe(response => {
                if (response.status == SERVER_STATUS.SUCCESS) {
                  this.ls._setEncryption(LocalStorageEnum.user, JSON.stringify(response.profile));
                }
              })
          } else if (res.status == SERVER_STATUS.ERROR_CLIENT) {
            if (Array.isArray(res.data.password) && res.data.password.includes("Password must contain min 8 letters, with at least a symbol, upper and lower case letters and a number")) {
              this.pwdControl.setErrors({
                "pwd-strong": true
              });
              this.rePwdControl.setErrors({
                "pwd-strong": true
              });
            }
            if (Array.isArray(res.data.personal_code) && res.data.personal_code.includes("Personal Code already exist"))
              this.formGroup.get("personal_code").setErrors({
                exist: true
              });
            if (Array.isArray(res.data.manage_office_ids) && res.data.manage_office_ids.includes("Manage Offices are required"))
              this.manageOfficeFormGroup.setErrors({
                required: true
              })
            if (Array.isArray(res.data.username) && res.data.username.includes("Username already exist"))
              this.getFormControl("username").setErrors({
                exist: true
              });
            if (Array.isArray(res.data.manage_office_ids) && res.data.manage_office_ids.includes("Invalid value"))
              this.manageOfficeFormGroup.setErrors({ invalid: true });
            this.container.nativeElement.scrollTo({ behavior: 'smooth', top: 0 });
            this.snackbar.openFromComponent(MessageComponent, {
              data: {
                message: 'new_request_service.fail'
              },
              panelClass: "panelClass-error",
              duration: 1500
            })
            return;
          }

          if (this.role_id != ROLE.ADMIN) {
            this.router.navigateByUrl('/requests/user');
          }
          this.dialogRef.close({ status: true });
        },
        error: err => console.error(err),
        // () => {
        //   this.isLoading = false;
        // }
      });
  }
  // NEW CODE START 1
  // isEmptyChildSelected(office: OfficeModel): boolean {
  //   if (!office) return false;
  //   let selectedLength: number = 0;
  //   office.childs.forEach(child => {
  //     this.selectedOfficeIDs.includes(child._id) && selectedLength++;
  //   })
  //   return selectedLength == 0;
  // }

  // isAllChildSelected(office: OfficeModel): boolean {
  //   let isSelect: boolean = true;
  //   if (office.childs.length == 0) return false;
  //   office.childs.forEach(child => {
  //     if (!this.selectedOfficeIDs.includes(child._id)) isSelect = false;
  //   });
  //   return isSelect;
  // }
  // // NEW CODE END 1

  // onCheckBoxChange(event: any, list /* LIST IS NEW ARG */) {
  //   console.log(list)
  //   this.autoSelectOfficeIds = [];
  //   let office_data = event.source.value;
  //   this.accessAllOfficeChilds(office_data);
  //   if (event.checked) {
  //     this.selectedOfficeIDs.push(...this.autoSelectOfficeIds.filter((item: string) => !this.selectedOfficeIDs.includes(item)));
  //     this.preventOfficeIDs.push(...this.autoSelectOfficeIds.filter((item: string) => item != office_data._id));
  //     // NEW CODE
  //     if (list.index == 0) return;
  //     !this.selectedOfficeIDs.includes(list.parent._id) && this.selectedOfficeIDs.push(list.parent._id);
  //     // END NEW CODE
  //   }
  //   else if (!event.checked) {
  //     this.selectedManageOfficeID.splice(this.selectedManageOfficeID.indexOf(office_data._id), 1);
  //     this.selectedOfficeIDs = this.selectedOfficeIDs.filter((item: string) => !this.autoSelectOfficeIds.includes(item));
  //     this.preventOfficeIDs = this.preventOfficeIDs.filter((item: string) => !this.autoSelectOfficeIds.includes(item));
  //     // NEW CODE
  //     if (list.index < 2) {
  //       this.selectedOfficeIDs.splice(this.selectedOfficeIDs.indexOf(office_data._id), 1);
  //     };
  //     // console.log(office_data)
  //     console.log(this.selectedOfficeIDs);
  //     if (list.parent && this.isEmptyChildSelected(list.parent)) {
  //       this.selectedOfficeIDs.includes(list.parent._id) && this.selectedOfficeIDs.splice(this.selectedOfficeIDs.indexOf(list.parent._id))
  //     }
  //     // END NEW CODE
  //   }
  //   this.manageOfficeFormGroup.setErrors({
  //     required: this.selectedOfficeIDs.length == 0 && this.selectedManageOfficeID.length == 0
  //   });
  // }

  onAvatarChange(e) {
    // if (!e.target.value) return;

    // this.avatarLoading = true;
    // this.newApi.uploadAvatars(e.target.files[0]).subscribe((res: any) => {
    //   this.avatarId = res.avatar._id;
    //   if (res.status == SERVER_STATUS.SUCCESS) {
    //     this.formGroup.get('avatar').patchValue(res.avatar._id);
    //     this.newApi.getAvatars(res.avatar._id).subscribe(response => {
    //       this.avatarLoading = false;
    //       this.avatarUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response as any));
    //       this.isEmptyAvatar = false;
    //       URL.revokeObjectURL(this.avatarUrl);
    //     });
    //   }
    // })
  }

  openImageCropDialog() {
    const dialogRef = this.dialog.open(CropDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: {},
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data != undefined && data.file != undefined) {
        this.avatarLoading = true;
        this.newApi.uploadAvatars(data.file).subscribe((res: any) => {
          this.avatarId = res.avatar._id;
          if (res.status == SERVER_STATUS.SUCCESS) {
            this.formGroup.get('avatar').patchValue(this.avatarId);
            this.newApi.getAvatars(this.avatarId).subscribe(response => {
              this.avatarLoading = false;
              this.avatarUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response as any));
              this.isEmptyAvatar = false;
              URL.revokeObjectURL(this.avatarUrl);
            });
          }
        })
      }
    });
  }

  onAutoAddParent(id: string) {
    this.parentOfSelectedOffice = null;
    this.filterParent(id, null, this.offices);

    if (this.parentOfSelectedOffice) {
      let children_ids: Array<string> = this.parentOfSelectedOffice.childs.map((item: any) => item._id);

      if (children_ids.every((item: string) => this.selectedOfficeIDs.includes(item))) {
        if (this.checkMangeOffice(this.parentOfSelectedOffice._id) || (this.user && (this.self_id == this.user._id))) {
          this.selectedOfficeIDs.push(this.parentOfSelectedOffice._id);
          this.preventOfficeIDs.push(...children_ids);
        }
        this.onAutoAddParent(this.parentOfSelectedOffice._id);
      }
    }
  }

  onDatePicker(e: any, date: MatDatepicker<any>): void {
    e.target.blur();
    date.open();
  }

  isAutoCheck(officeID: string) {
    return this.manage_office_ids.some(ele => ele === officeID);
  }

  clickCollapse(office: OfficeModel) {
    if (!office.collapse) {
      this.traverseCollapse(office);
    }
  }

  traverseCollapse(office: OfficeModel) {
    office.collapse = false;
    if (office.childs.length) {
      office.childs.forEach(of => this.traverseCollapse(of));
    }
  }

  // private shallowOffice(): OfficeModel[] {
  //   const 
  // }

  private findOffice(id: string): OfficeModel {
    const officesChild: OfficeModel[] = new Array();
    function each(office: OfficeModel) {
      officesChild.push(...office.childs);
      if (office.childs.length)
        office.childs.forEach(office => {
          each(office)
        })
    }
    this.offices.forEach(each);
    const find = this.offices.find(office => office._id == id);
    if (find)
      return find;
    return officesChild.find(office => office._id == id);
  }

  onOfficeChange(event: any) {
    // const office = this.findOffice(event.value);
    this.selectedManageOfficeID = [];
    let offices: string[] = new Array();
    const each = (office): string[] => {
      offices.push(office._id);
      if (!office.childs.length) return offices;
      office.childs.forEach(_office => {
        offices.push(_office._id);
        if (_office.childs.length)
          each(_office)
      });
      return Array.from(new Set(offices));
    }
    this.selectedManageOfficeID = each(this.findOffice(event.value));
    this.manageOfficeFormGroup.setErrors({
      required: this.selectedOfficeIDs.length == 0 && this.selectedManageOfficeID.length == 0
    });
    // this.autoSelectOfficeIds = [];
    // this.offices.forEach(office => {
    //   if (office._id === event.value) {
    //     this.accessAllOfficeChilds(office);
    //   }
    // });

    // if (!this.isUnchecked) {
    //   this.manage_office_ids.push(...this.autoSelectOfficeIds);
    // } else if (this.isUnchecked) {
    //   this.manage_office_ids = this.manage_office_ids.filter(item => !this.autoSelectOfficeIds.includes(item));
    // }
  }

  accessAllOfficeChilds(office): any {
    this.autoSelectOfficeIds.push(office._id);
    if (office.childs.length) {
      office.childs.forEach(obj => {
        this.accessAllOfficeChilds(obj);
      });
    }
  }

  filterParent(id: string, parent: any, items: Array<any>) {
    items.forEach((item: any) => {
      if (item._id == id) {
        this.parentOfSelectedOffice = parent;
      }
      else if (item.childs.length) {
        this.filterParent(id, item, item.childs);
      }
    });
  }

  checkOfficeID(id: string): boolean {
    return this.manage_office_ids.some(e => e === id);
  }

  positionGetChange(event: string) {
    this.isAllowOutDepOption = this.positions.find(position => position._id == event).name == this.DEPARTMENT_OFFICER_NAME;
    this.isShowRoleFormField = (event === this.proffessionalId);
    this.isPositionNotOfficer = event != this.positions.find(position => position.name == 'Officer')._id;
    this.formGroup.get("role").patchValue('');
    if (this.isShowRoleFormField) {
      this.newApi.getRoleByPositionId(event).subscribe((response: any) => {
        this.roles = response.roles;
      });
      if (this.user && this.user.position._id == this.proffessionalId) {
        this.formGroup.get('role').patchValue(this.user.role._id);
      }
      // this.formGroup.get('role').setValidators(Validators.required);
      // this.formGroup.get('role').updateValueAndValidity();
    } else {
      this.isLoading = true;
      this.newApi.getRoleByPositionId(event).subscribe((response: any) => {
        this.isLoading = false;
        if (response.roles.length)
          this.formGroup.get('role').patchValue(response.roles[0]._id);
      });
      // this.formGroup.get('role').clearValidators();
      // this.formGroup.get('role').updateValueAndValidity();
    }
  }
  getFormControl(name: string): AbstractControl {
    return this.formGroup.get(name);
  }
  toggleVisiblePassword(inp: HTMLInputElement): void {
    inp.type = inp.type == "password" ? "text" : "password";
  }
}
