<div class="toolbar" mat-dialog-title>
  <h3 class="m-b-20">{{'user.user_list' | translate}}</h3>
  <form [formGroup]="filterForm">
      <mat-form-field appearance="outline">
          <mat-label>{{'user.search' | translate}}</mat-label>
          <input matInput
            formControlName="searchQuery"
            type="text"
            [placeholder]="'user.search' | translate">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="header-filter m-l-20">
          <mat-label>{{'user.filter.office' | translate}}</mat-label>
          <mat-select formControlName="office">
            <mat-option [value]="''">
                {{ 'user.filter.all_office' | translate }}
            </mat-option>
            <ng-template #officeList let-list>
              <ng-container *ngFor="let office of list.data; let i = index">
                <div [style.padding-left]="18 * list.index + 12 + 'px'"
                  class="tfield color-primary">
                  <button style="float: left; height: 45px; width: 45px;" 
                    [style.visibility]="
                      office.childs.length ? 'show' : 'hidden'
                    " mat-icon-button>
                    <mat-icon *ngIf="!office.collapse">arrow_right</mat-icon>
                    <mat-icon *ngIf="office.collapse">arrow_drop_down</mat-icon>
                  </button>
                  <mat-option [title]="office.name" style="width: calc(100% - 45px); float: right" [value]="office._id">
                    {{ office.name }}
                  </mat-option>
                </div>

                <ng-container *ngIf="office.childs">
                  <ng-container *ngTemplateOutlet="
                      officeList;
                      context: {
                        $implicit: {
                          data: office.childs,
                          index: list.index + 1,
                          parent: office,
                          collapse: office.collapse
                        }
                      }
                    ">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>

            <ng-container *ngTemplateOutlet="
                officeList;
                context: {
                  $implicit: {
                    data: manageOffice,
                    index: 0,
                    parent: null,
                    collapse: false
                  }
                }
              ">
            </ng-container>
          </mat-select>
        </mat-form-field>
  </form>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate" color="accent"></mat-progress-bar>
<mat-dialog-content>
    <div class="container">
        <table mat-table [dataSource]="users">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'new_doc.dialog.select.table.select' | translate }}
                </th>
                <td mat-cell *matCellDef="let user">
                    <mat-checkbox (change)="onSelectionChange($event)" [value]="user.personal_code" [checked]="user.selected || selectedUsersID.includes(user.personal_code)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'new_doc.dialog.select.table.code' | translate }}
              </th>
              <td mat-cell *matCellDef="let user">
                  {{ user.personal_code }}
              </td>
            </ng-container>
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'new_doc.dialog.select.table.username' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.last_name + " " + row.first_name }}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="office">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'new_doc.dialog.select.table.office' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    {{ row.office.name }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>
        <div *ngIf="isEmpty" class="empty-show">
          <mat-icon class="cloud-off-icon">cloud_off</mat-icon>
          <h3>{{'cabinet.empty' | translate}}</h3>
        </div>
        <mat-paginator
          #paginator
          *ngIf="totalUser > limit"
          [length]="totalUser"
          class="paginator"
          (page)="onPageChange($event)"
          [pageSize]="limit"
          [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
        </mat-paginator>
        <div class="try-again" *ngIf="isTryAgain">
            <p class="smg">Somethinks When Wrong</p>
            <button mat-fab>
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button [mat-dialog-close]="{ status: false }" mat-raised-button>{{ 'global.form.update.ignore' | translate }}</button>
    <button [mat-dialog-close]="{ status: true, users: selectedUsers}" mat-raised-button color="accent">{{ 'global.form.update.submit' | translate }}</button>
</mat-dialog-actions>