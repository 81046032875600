import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestsRoutingModule } from './requests-routing.module';
import { UserRequestComponent } from './components/user-request/user-request.component';
import { DocumentRequestComponent } from './components/document-request/document-request.component';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TrackingItemModule } from 'src/app/shared/modules/tracking-item.module';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { DetailRequestComponent } from './components/detail-request/detail-request.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileViewerComponent } from 'src/app/shared/components/file-viewer/file-viewer.component';
import { SnackbarModule } from 'src/app/shared/modules/snackbar.module';
import { DocumentFormModule } from 'src/app/shared/modules/document-form.module';
import { LocaleNumberPipe } from 'src/app/core/pipe/locale-number.pipe';
import { PipeModule } from 'src/app/core/pipe/pipe.module';
import { AlertConfirmDialogModule } from 'src/app/shared/dialogs/alert-confirm-dialog/alert-confirm-dialog.module';


@NgModule({
  declarations: [UserRequestComponent, DocumentRequestComponent, DetailRequestComponent, FileViewerComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatNativeDateModule,
    TranslateModule,
    MatProgressBarModule,
    MatTooltipModule,
    PipeModule,
    AlertConfirmDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    TrackingItemModule,
    InfiniteScrollModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatBadgeModule,
    SnackbarModule,
    DocumentFormModule,
    MatChipsModule,
    RequestsRoutingModule,
    MatExpansionModule
  ],
  providers: [
    InfiniteScrollDirective,
    LocaleNumberPipe
  ]
})
export class RequestsModule { }
