import {animate, state, style, transition, trigger} from '@angular/animations';

export const fadeInToRight = [trigger('fadeInToRight', [
  state('void', style({opacity: 0, transform: 'translateX(-30px) scale(.9) '})),
  transition('void <=> *', animate('.35s ease-out'))
])];


export const fadeInToLeft = [trigger('fadeInToLeft', [
  state('void', style({opacity: 0, transform: 'translateX(30px)'})),
  transition('void <=> *', animate('.35s ease-out'))
])];

export const zoomIn = [trigger('zoomIn', [
  state('void', style({opacity: 0, transform: 'scale(1.2)'})),
  transition('void <=> *', animate('.3s ease'))
])];


export const fade = trigger('fade', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate('150ms', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('150ms', style({ height: 0, opacity: 0 }))
  ])
]);

export const fadeOpacity = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('250ms', style({ opacity: 1 })),
  ]),
  // transition(':leave', [
  //   animate('250ms', style({ opacity: 0 }))
  // ])
]);