import { Router } from '@angular/router';
import { NewAPIService } from './../../../core/services/new-api.service';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserModel } from 'src/app/core/models/user.model';
import { FormControl, Validators, FormGroup, FormBuilder, AbstractControl, Form } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-submit-reason-dialog',
  templateUrl: './submit-reason-dialog.component.html',
  styleUrls: ['./submit-reason-dialog.component.scss']
})
export class SubmitReasonDialogComponent implements OnInit {
  reference: { [key: string]: any } = {};
  reasonFormGroup: FormGroup;
  submited: boolean = false;
  constructor(
    // private api: ApiService,
    private router: Router,
    private newApi: NewAPIService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string
      warn: boolean
      data?: any
      onSubmit?: (reason: string) => any
      request?: any;
      questions?: string[];
      reasonRequire: boolean;
      model: null | 'document' | 'user';
      isNegative: boolean
    },
    private dialogRef: MatDialogRef<SubmitReasonDialogComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.reference = {
      ...this.data,
      reasonRequire: this.data.reasonRequire == false ? false : true
    };
    this.reasonFormGroup = this.fb.group({
      reason: ["", this.reference.reasonRequire && Validators.required]
    });
  }

  public sumitReason() {
    if (this.reasonFormGroup.invalid && !this.data) {
      this.reason.markAsTouched();
      return;
    }
    this.submited = true;
    if (this.data.onSubmit) {
      this.data.onSubmit(this.reason.value);
      return;
    }
    this.newApi.rejectRequest(this.data.request._id, this.reasonFormGroup.value.reason).subscribe(
      res => {
        if (res.status > 0) {
          this.dialogRef.close(true)
        }
      },
      err => console.error(err)
    );
    // }
    // else {
    // console.log('not object type work')
    //   this.newApi.rejectRequest(this.data, this.reasonFormGroup.value.reason).subscribe(
    //     res => this.dialogRef.close(true),
    //     err => console.error(err)
    //   );
    //   const currentRoute = '/documents/notification';
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //       this.router.navigate([currentRoute]);
    //   });
    // }
  }
  get reason(): FormControl {
    return this.reasonFormGroup.get('reason') as FormControl;
  }
}
