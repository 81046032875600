import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  constructor(
    private self: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      code: string,
      mainTitle: string,
      desc: string,
      descs: string[],
      warn: boolean,
      success?: boolean
    }
  ) { }

  ngOnInit(): void {
  }
}
