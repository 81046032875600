import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewAPIService } from 'src/app/core/services/new-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { ProgressModel } from 'src/app/core/models/progress.model';
import { lastValueFrom, timer } from 'rxjs';
import { BUCKET_EXTERNAL_DOC } from '../../routes-component/document-form/document-form.component';

@Component({
  selector: 'app-view-file-dialog',
  templateUrl: './view-file-dialog.component.html',
  styleUrls: ['./view-file-dialog.component.scss']
})
export class ViewFileDialogComponent implements OnInit {
  fileURL: any;
  isLoading: boolean = true;
  progress: number = 0;
  isImage: boolean = false;
  private _url: string;
  constructor(
    private newApi: NewAPIService,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: {
      file: ProgressModel,
      is_download: boolean,
      is_external_doc: boolean
    }
  ) { }

  ngOnInit(): void {
    // this.newApi.fetchFileById(this.data.file._id, this.auth.getToken(), this.data.is_download || false)
    if (this.data.is_external_doc || (this.data.file.bucketName && this.data.file.bucketName == BUCKET_EXTERNAL_DOC)) {
      this.newApi.getExternalDocFile(this.data.file._id)
      .pipe(map((event: HttpProgressEvent) => {
        if (event.type == HttpEventType.DownloadProgress)
          this.progress = Math.floor(100 * event.loaded / event.total);
        return event;
      }))
      .subscribe((response: any) => {
        if (response.body) {
          this.isLoading = false;
          this._url = URL.createObjectURL(response.body)
          this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(this._url);
          this.isImage = response.body.type.search(/image/) == 0;
        }
      })

    } else {
      this.newApi.downloadFileWithoutAuth(this.data.file._id, { is_download: this.data.is_download || false })
      .pipe(map((event: HttpProgressEvent) => {
        if (event.type == HttpEventType.DownloadProgress)
          this.progress = Math.floor(100 * event.loaded / event.total);
        return event;
      }))
      .subscribe((response: any) => {
        if (response.body) {
          this.isLoading = false;
          this._url = URL.createObjectURL(response.body)
          this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(this._url);
          this.isImage = response.body.type.search(/image/) == 0;
        }
      })
    }
  }

  async revoke() {
    await lastValueFrom(timer(0));
    URL.revokeObjectURL(this._url);
  }

  viewFull(any?): void {
    window.open(this.newApi.downloadFileById(this.data.file._id, this.auth.getToken()))
  }

  viewFullScreen() {
    var viewer = <HTMLIFrameElement>document.getElementById("viewer");
    viewer.requestFullscreen();
  }
}
