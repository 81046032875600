import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateDatePipe } from './translate-date.pipe';


@NgModule({
  declarations: [TranslateDatePipe],
  imports: [
    CommonModule
  ],
  exports: [TranslateDatePipe]
})
export class TranslateDateModule {
}
