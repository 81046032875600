import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/app/app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainComponent } from "./components/main/main.component";
import { SharedModule } from "../shared/modules/shared.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { QRCodeModule } from "angularx-qrcode";
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { OfflineInterceptor } from "../core/interceptors/offline.interceptor";
// import { ExcelExportModule } from "@progress/kendo-angular-excel-export";
import { ChangePasswordDialogComponent } from "../shared/dialogs/change-password-dialog/change-password-dialog.component";
import { ViewCabinetBoxDialogComponent } from "../shared/dialogs/view-cabinet-box-dialog/view-cabinet-box-dialog.component";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { DocSearchComponent } from './components/doc-search/doc-search.component';
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateDateModule } from "../core/pipe/translate-date.module";
import { DatePipe } from "@angular/common";
import { MatTreeModule } from "@angular/material/tree";
import { LocaleNumberPipe } from "../core/pipe/locale-number.pipe";
import { PipeModule } from "../core/pipe/pipe.module";
import { MatDatepickerModule } from "@angular/material/datepicker";

import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyTypographyModule } from '@alyle/ui/typography';

/** Import themes */
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

/** Import Alyle UI */
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
} from '@alyle/ui';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ChangePasswordDialogComponent,
    ViewCabinetBoxDialogComponent,
    DocSearchComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateDateModule,
    MatDialogModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatChipsModule,
    MatIconModule,
    QRCodeModule,
    MatTreeModule,
    SharedModule,
    // ExcelExportModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    MatExpansionModule,
    PipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LyImageCropperModule,
    LyButtonModule,
    LyIconModule,
    LyTypographyModule,
  ],
  entryComponents: [
    ChangePasswordDialogComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    DatePipe,
    LocaleNumberPipe,

    [LyTheme2],
    [StyleRenderer],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-dark' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
  ],
  bootstrap: [
    MainComponent
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
  ]
})
export class AppModule { }
