import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private auth: AuthService,
    private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if (this.auth.hasLoggedIn()) {
    //   return !this.router.navigate(['/']);
    // } else {
    //   return true;
    // }

    if (this.auth.hasLoggedIn()) {
      if (this.router.navigate(['/'])) {
        localStorage.clear();
        this.router.navigate(['/sign-in']);
        return true;
      }
      return !this.router.navigate(['/sign-in']);
    } else {
      return true;
    }
  }
}
