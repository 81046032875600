<mat-dialog-content>

    <!-- Token Expired -->
    <p *ngIf="data?.is_expired_token">{{ 'message_dialog.token_expired' | translate }}</p>
    
    <!-- Max File Size -->
    <p *ngIf="data?.is_max_size_file">{{ data.message }}</p>

    <!-- Max Count Size -->
    <p *ngIf="data?.is_max_count_file">{{ data.message }}</p>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button *ngIf="!data?.is_yes_only" [mat-dialog-close]="false">{{'message_dialog.btn_no' | translate}}</button>
    <button mat-raised-button color="accent" class="m-l-5" [mat-dialog-close]="true">{{'message_dialog.btn_yes' | translate}}</button>
</mat-dialog-actions>