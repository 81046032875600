<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding @fade>
    <button mat-icon-button disabled></button>
    <!-- <mat-icon class="type-icon" [attr.aria-label]="'icon'">
      arrow_right
    </mat-icon> -->
    <!-- {{ selection.isSelected(node.item._id) | json }} {{ selection.selected | json }} -->
    <mat-checkbox [checked]="selection.isSelected(node.item._id)" (change)="toggleSelection(node)">
      {{ node.item.name }}
    </mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding  @fade>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <!-- <mat-icon class="type-icon" [attr.aria-label]="'icon'">
      
    </mat-icon> -->
    <mat-checkbox
      [checked]="selection.isSelected(node)"
      (change)="parentToggleSelection(node)"
      [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)"
    >
      <!-- {{ selection.isSelected(node.item._id) | json }} -->
      {{ node.item.name }}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>
