import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SearchService {
  private visibilityChanges = new BehaviorSubject<boolean>(false);
  public queryChanges = new Subject<any>();
  public loadSavedFilter = new Subject<any>();
  public publicSearch: any;
  public reset: () => void;

  public setVisibility(visibility: boolean) {
    this.visibilityChanges.next(visibility);
  }

  public getVisibility(): boolean {
    return this.visibilityChanges.value;
  }

  public visibilityObservable(): Observable<boolean> {
    return this.visibilityChanges.asObservable();
  }

  public emitQuery(object: any) {
    this.queryChanges.next(object);
  }

  public emitSavedFilter(object: any) {
    this.loadSavedFilter.next(object);
  }

  public searchObservable(): Observable<Object> {
    return this.queryChanges.asObservable();
  }
}
